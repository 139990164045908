import SEO from '@/components/common/SEO';
import React from 'react';
import {translationKeys as _} from '@/constants/translations';
import {t} from '@ultradent/components';

export const PageNotFound = () =>
    <>
        <SEO title={t( 'pageNotFound.pageTitle', 'Page not found' )}/>
        <section className="contain-content pad-content mb-16">
            <div className="md:flex  max-w-lg mx-auto">
                <div className="w-full max-w-md">
                    <div className="md:flex justify-between items-center mb-6 md:mb-12">
                        <h1 className="headline-lg">{t( 'pageNotFound.heading', _.pageNotFound.heading )}</h1>
                    </div>

                    <h2 className="text-lg md:text-2xl font-normal leading-tight mb-4">
                        {t( 'pageNotFound.body1', _.pageNotFound.body1 )}
                    </h2>
                    <p className="text-body">
                        {t( 'pageNotFound.body2', _.pageNotFound.body2 )}
                    </p>

                    <hr className="mb-16 border-0"/>
                    <h4 className="subheading-lg mb-2">
                        {t( 'pageNotFound.subheading', _.pageNotFound.subheading )}
                    </h4>
                    <nav className="text-base md:text-lg">
                        <a className="block hover:link-2 mb-2" href="/">
                            {t( 'pageNotFound.navItems.home', _.pageNotFound.navItems.home )}
                        </a>
                        <a className="block hover:link-2 mb-2" href="/resources">
                            {t( 'pageNotFound.navItems.resources', _.pageNotFound.navItems.resources )}
                        </a>
                        <a className="block hover:link-2 mb-2" href="/profile">
                            {t( 'pageNotFound.navItems.accountSettings', _.pageNotFound.navItems.accountSettings )}
                        </a>
                        <a className="block hover:link-2 mb-2" href="/team">
                            {t( 'pageNotFound.navItems.yourTeam', _.pageNotFound.navItems.yourTeam )}
                        </a>
                        <a className="block hover:link-2 mb-2" href="/privacy-management">
                            {t( 'pageNotFound.navItems.privacy', _.pageNotFound.navItems.privacy )}
                        </a>
                    </nav>
                </div>
            </div>
        </section>
    </>
