import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/resourcecenter',
    mockSrc: process.env.MOCK_API && 'resource-center'
} );

// note -> api expects specially formatted query string for facet selection
svc.queryAssets = function queryAssets ( query ) {
    return this.xhr.get( 'search', query );
};

svc.getAssetDetail = function getAssetDetail ( {id} ) {
    return this.xhr.get( 'assetdetails', {params: {id}} );
};

svc.querySuggestions = function querySuggestions ( {query, filter, top = 7} ) {
    return this.xhr.get( 'search/suggestions', {params: {query, filter, top}} );
};

svc.getTaxonomy = function getTaxonomy () {
    return this.xhr.get( 'taxonomy' );
}

export const ResourceCenterProvider = svc.xhr;

export default svc;


