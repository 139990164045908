import {Button, Modal, SelectField, t} from '@ultradent/components';
import invoke from 'lodash.invoke';
import {FormikProvider, useFormik} from 'formik'
import {translationKeys as _} from '@/constants/translations';
import {noop} from '@ultradent/utilities/Functions';
import React, {useCallback, useEffect, useState} from 'react';
import AccountService from '@/providers/account';
import {ErrorCodes, ErrorMessages, getErrorList} from '@/util/Errors';
import FormPostError from '@/components/common/Form/FormPostError';
import {notify} from '@/modules/notifier';
import useAccountFieldOptions from '@/containers/UserManagement/useAccountFieldOptions';

const labelRequired = `(${t( 'common.label.required', _.label.required )})`;

const createSuccessPayload = ( payload, fieldOptions ) => {
    const selectedSalesRep = fieldOptions?.salesReps.find( ( {code} ) => code === payload.salesRep );
    const selectedAccountType = fieldOptions?.accountTypes.find( ( {code} ) => code === payload.accountType );

    return {
        selectedSalesRep,
        selectedAccountType
    };
}
export const EditAccountDetails = ( {account, isOpen, onClose = noop, ...props} ) => {
    const [isProcessing, setIsProcessing] = useState( props.processing );
    const [formPostError, setFormPostError] = useState( null );
    const {fieldOptions} = useAccountFieldOptions();

    const initialState = {
        accountType: account.accountType,
        salesRep: account.salesRep
    };

    const formik = useFormik( {
        initialValues: initialState,
        validateOnChange: true,
        enableReinitialize: true,
        validate: values => {
            const fieldRequired = t( 'notify.fieldRequired', _.notify.fieldRequired );

            const errors = {};
            if ( !values.accountType ) {
                errors.accountType = fieldRequired;
            }
            if ( !values.salesRep ) {
                errors.salesRep = fieldRequired;
            }
            return errors;
        },
        onSubmit: async ( values ) => {
            setFormPostError( null );

            if ( !isProcessing ) {
                setIsProcessing( true );

                try {
                    await AccountService.updateAccount( {
                        accountId: account.id,
                        ...values
                    } );
                    notify.success(
                        t( 'account.notify.updateDetailsSuccess', _.account.notify.updateDetailsSuccess )
                    );
                    invoke( props, 'onUpdateSuccess', createSuccessPayload( values, fieldOptions ) );
                    _onClose();
                }
                catch ( err ) {
                    handleRequestError( err );
                }
                finally {
                    setIsProcessing( false );
                }
            }

            return false;
        }
    } );

    /**
     * note - ensure that we are setting valid values form field options in cases where CRM is out of sync with CMS (fieldOptions)
     * This could happen if a Account Type or Sale Rep was added from CRM but does not exist in CMS for some reason
     */
    useEffect( async () => {
        const foundAccountType = fieldOptions?.accountTypes.find( ( {code} ) => code === account.accountType );
        const foundSalesRep = fieldOptions?.salesReps.find( ( {code} ) => code === account.salesRep );

        await formik.setFieldValue( 'accountType', foundAccountType?.code || '' );
        await formik.setFieldValue( 'salesRep', foundSalesRep?.code || '' );
        await formik.validateForm( formik.values );
    }, [account, fieldOptions] );

    const _onClose = useCallback( () => {
        setFormPostError( null );
        formik.resetForm();
        onClose();
    }, [] );

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            parseRequestErrorResponse( err.response.data );
            return;
        }
        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function parseRequestErrorResponse ( err ) {
        if ( err.code === ErrorCodes.AccountAlreadyExists ) {
            handleValidationError( err );
            return;
        }

        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function handleValidationError ( err ) {
        setFormPostError( {
            message: err.message,
            errorList: getErrorList( err.data )
        } );
    }

    return (
        <Modal
            title={
                t( 'account.label.editAccount', _.account.label.editAccount )
            }
            size="sm"
            onClose={_onClose}
            isOpen={isOpen}>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    {formPostError &&
                        <FormPostError {...formPostError}>
                            <h4 className="text-fail text-title">
                                {t( 'message.genericRequestError', _.message.genericRequestError )}
                            </h4>
                        </FormPostError>
                    }

                    <label>{t( 'account.label.accountName', _.account.label.accountName )}</label>
                    <h3 className="mb-4 text-title capitalize">{account.accountName}</h3>

                    <SelectField
                        id="accountType"
                        labelAppend={labelRequired}
                        value={formik.values.accountType}
                        onChange={formik.handleChange}
                        error={formik.touched.accountType && formik.errors.accountType}
                        className="mb-4"
                        label="Account Type">
                        <option value=""></option>
                        {fieldOptions?.accountTypes?.map( ( {code, name} ) => <option key={code}
                                                                                      value={code}>{name}</option> )}
                    </SelectField>
                    <SelectField
                        id="salesRep"
                        labelAppend={labelRequired}
                        value={formik.values.salesRep}
                        onChange={formik.handleChange}
                        error={formik.touched.salesRep && formik.errors.salesRep}
                        className="mb-4"
                        label="Sales Representative">
                        <option value=""></option>
                        {fieldOptions?.salesReps?.map( ( {code, name} ) => <option key={code}
                                                                                   value={code}>{name}</option> )}
                    </SelectField>
                    <div className="mt-8">
                        <Button type="submit" loading={isProcessing} tabIndex="0" className="mr-4">
                            {t( 'actionLabel.submit', _.actionLabel.submit )}
                        </Button>
                        <Button tertiary onClick={_onClose} as="div" tabIndex="0">
                            {t( 'actionLabel.cancel', _.actionLabel.cancel )}
                        </Button>
                    </div>
                </form>
            </FormikProvider>
        </Modal>
    );
}

EditAccountDetails.defaultProps = {
    isOpen: false,
    onClose: noop,
    onSubmit: noop
};


