import PageModelService from './service';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

class PageModelRepository {

    constructor ( store ) {
        this.pageModelStore = store;
    }

    subscribe ( fn ) {
        return this.pageModelStore.subscribe( fn );
    }

    getStoreKey () {
        return this.pageModelStore.storeKey;
    }

    async getPageModel ( key ) {
        const storeCache = this.pageModelStore.getStore( key );

        // if cached bypass requests
        if ( notEmptyOrNil( storeCache ) ) {
            return storeCache;
        }

        try {
            const response = await PageModelService.getContentType( key );
            const pageModel = response.data[0];

            this.pageModelStore.setStore( key, pageModel );

            return pageModel;
        }
        catch ( err ) {
            // attempt to retrieve from local store
            // if nothing there, return empty
            const pageModel = this.pageModelStore.getStore( key );
            return pageModel || {};
        }
    }

    setPageModel ( key, clipboardData ) {
        this.pageModelStore.setStore( key, clipboardData );
    }

    clearPageModel ( key ) {
        this.pageModelStore.clearStoreItem( key );
    }
}

export default PageModelRepository;
