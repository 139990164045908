import React from 'react';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

const ResourceAppContext = new React.createContext();

const verifyOrFallBackContent = ( pageModel ) => {
    let model = pageModel;
    if ( isEmptyOrNil( model ) ) {
        // use static fallback if pageModel not loaded
        console.warn( '[PageModel Failed to load] Loading fallback content instead...' );
        model = require( './Pages/page-model' ).default;
    }

    return model;
}

export {ResourceAppContext, verifyOrFallBackContent};
