import {FileAttachment, FileDrop, Label, t, Textarea, TextField} from '@ultradent/components';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {translationKeys as _} from '@/constants/translations';

const labels = {
    accountDetail: t( 'equipmentRepair.label.accountDetail', _.equipmentRepair.label.accountDetail ),
    contactInfo: t( 'equipmentRepair.label.contactInfo', _.equipmentRepair.label.contactInfo ),
    equipmentInfo: t( 'equipmentRepair.label.equipmentInfo', _.equipmentRepair.label.equipmentInfo ),
    addAttachments: t( 'equipmentRepair.label.addAttachments', _.equipmentRepair.label.addAttachments ),
    accountName: t( 'equipmentRepair.label.accountName', _.equipmentRepair.label.accountName ),
    accountNumber: t( 'equipmentRepair.label.accountNumber', _.equipmentRepair.label.accountNumber ),
    fullName: t( 'equipmentRepair.label.contact', _.equipmentRepair.label.contact ),
    email: t( 'equipmentRepair.label.email', _.equipmentRepair.label.email ),
    address: t( 'equipmentRepair.label.address', _.equipmentRepair.label.address ),
    serialNumber: t( 'equipmentRepair.label.serialNumber', _.equipmentRepair.label.serialNumber ),
    description: t( 'equipmentRepair.label.description', _.equipmentRepair.label.description ),
    additionalInformation: t( 'equipmentRepair.label.additionalInformation',
        _.equipmentRepair.label.additionalInformation )
};

const EquipmentRepairFields = ( {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    attachments,
                                    onAddAttachment,
                                    onRemoveAttachment
                                } ) => {

    return (
        <>
            <fieldset>
                <h3 className="subheading-base mb-4">
                    {labels.accountDetail}
                </h3>
                <TextField id="accountName" label={`*${labels.accountName}`} className="flex-col mb-4"
                           onChange={handleChange}
                           value={values.accountName}
                           error={touched.accountName && errors.accountName}/>
                <TextField id="accountNumber" label={`*${labels.accountNumber}`} className="flex-col mb-4"
                           onChange={handleChange}
                           value={values.accountNumber}
                           error={touched.accountNumber && errors.accountNumber}/>
            </fieldset>

            <fieldset>
                <h3 className="subheading-base mb-4">
                    {labels.contactInfo}
                </h3>
                <TextField id="fullName" label={`*${labels.fullName}`} className="flex-col mb-4"
                           onChange={handleChange}
                           value={values.fullName}
                           error={touched.fullName && errors.fullName}/>
                <TextField id="email" label={`*${labels.email}`} className="flex-col mb-4"
                           onChange={handleChange}
                           value={values.email}
                           error={touched.email && errors.email}/>
                <Textarea id="address" label={`*${labels.address}`}
                          className="flex-col mb-4"
                          onChange={handleChange}
                          value={values.address}
                          error={touched.address && errors.address}/>

            </fieldset>

            <fieldset>
                <h3 className="subheading-base mb-4">
                    {labels.equipmentInfo}
                </h3>
                <TextField id="serialNumber" label={`*${labels.serialNumber}`} className="flex-col mb-4"
                           onChange={handleChange}
                           value={values.serialNumber}
                           error={touched.serialNumber && errors.serialNumber}/>
                <Textarea id="description" label={`*${labels.description}`}
                          className="flex-col mb-4"
                          onChange={handleChange}
                          value={values.description}
                          error={touched.description && errors.description}/>
                <Textarea id="additionalInformation" label={labels.additionalInformation} className="flex-col mb-4"
                          onChange={handleChange}
                          value={values.additionalInformation}
                          error={touched.additionalInformation && errors.additionalInformation}/>

                <Label htmlFor="attachments">{labels.addAttachments}</Label>
                <div className="rounded-lg border border-solid border-grey-lighter p-2 mb-8">
                    <FileDrop id="attachments" label={labels.addAttachments} onFileAdded={onAddAttachment}/>

                    {notEmptyOrNil( attachments ) &&
                        <div className="mt-4 leading-loose">
                        {attachments.map( ( data, i ) =>
                            <FileAttachment key={data.fileName} {...data}
                                            onRemove={() => onRemoveAttachment( i )}/> )}
                    </div>
                    }
                </div>
            </fieldset>

        </>
    )
}

export {EquipmentRepairFields};
