import {FormatPng} from '@/components/common/Icons';
import {getAssetImageSrc} from '@/apps/ResourceCenter/util/AssetHelpers';
import cx from 'classnames';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const counterStyle = 'badge-hollow absolute z-10 text-near-black bottom-3 right-3 opacity-75 p-1 border-0';

const Album = ( {id, previewImageId, name, url, categories, itemCount, className} ) =>
    <div className={cx( 'leading-tight', className )} data-asset-id={id}>
        <div className="mb-2 relative">
            <a href={url.detail} className="link-hover">
                <div className="album-ornament">
                <img className="object-contain w-full h-full" alt={name} src={getAssetImageSrc( previewImageId )}/>
                </div>
            </a>
            {itemCount > 0 &&
                <div className={counterStyle}>
               <FormatPng width="14" height="14" className="mr-1"/> {itemCount}
            </div>
            }
        </div>
        <h3 className="text-title mb-2">
            <a className="link-hover" href={url.detail}>
                {name}
            </a>
        </h3>
        {categories && categories.length > 0 &&
            <p className="text-body-sm mt-0 mb-1">
                {t( 'asset.label.appearsIn', _.asset.label.appearsIn )} {categories.join( ', ' )}
            </p>
        }
    </div>;

Album.defaultProps = {
    url: {},
    categories: []
};

export {Album}
