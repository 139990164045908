console.warn( '[Mocking] "equipment-repair" api' );

export default function ( instance ) {
    instance
        .onPost( '/submitform' )
        .reply( () => ([
            400,
            {}
        ]) );
}


