import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {BookmarksEmpty} from '@/containers/Bookmarks/BookmarksEmpty';
import {Bookmark} from '@/components/common/Icons';
import React from 'react';
import {InvalidatedBookmark} from '@/containers/Bookmarks/InvalidatedBookmark';
import {Asset} from '@/apps/ResourceCenter/Assets/Asset';
import {SkeletonElement} from '@ultradent/components';

const SKELETON_COUNT = Array( 10 ).fill( null );
const bookmarkGridStyle = 'fade-in w-full grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-1 md:gap-3';

export const BookmarksList = ( {list = [], loading, onRemoveBookmarkItem, itemRenderer} ) => {

    if ( loading ) {
        return <BookmarksList.Skeleton/>
    }

    if ( isEmptyOrNil( list ) ) {
        return <BookmarksEmpty/>
    }

    return (
        <>
            <div className="flex mb-8 items-center">
                <Bookmark width="24" className="mr-2"/> {list.length} items
            </div>
            <div className={bookmarkGridStyle}>
                {list.map( itemProps => {
                    if ( itemProps.valid ) {
                        return React.createElement( itemRenderer, {key: `bookmark-${itemProps.id}`, ...itemProps} );
                    }

                    return <InvalidatedBookmark
                        key={itemProps.id}
                        id={itemProps.id}
                        name={itemProps.name}
                        onRemove={onRemoveBookmarkItem( itemProps )}/>
                } )}
            </div>
        </>
    );
}

BookmarksList.Skeleton = () =>
    <>
        <div className="flex mb-8 items-center">
            <SkeletonElement type="icon" className="mr-2"/>
            <SkeletonElement type="text" className="w-12"/>
        </div>
        <div className={bookmarkGridStyle}>
            {SKELETON_COUNT.map( ( item, i ) => <Asset.Skeleton key={`bookmark-skeleton-${i}`}/> )}
        </div>
    </>;
