import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/equipmentrepair',
    mockSrc: process.env.MOCK_API && 'equipment-repair'
} );

svc.submitRepairRequest = function submitRepairRequest ( {
                                                             accountName,
                                                             accountNumber,
                                                             address,
                                                             fullName,
                                                             email,
                                                             serialNumber,
                                                             description,
                                                             additionalInformation,
                                                             attachments = []
                                                         } ) {
    const body = {
        accountName,
        accountNumber,
        address,
        fullName,
        email,
        serialNumber,
        description,
        additionalInformation,
        attachments
    };

    return this.xhr.post( 'submitform', body );
};

export const EquipmentRepairProvider = svc.xhr;

export default svc;


