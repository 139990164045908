import {Taglist} from '@/apps/ResourceCenter/Pages/AssetDetailPage/Components';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const AssetTagList = ( {tags} ) => {
    tags = tags.map( x => x.trim() );
    const shownTags = tags.slice( 0, 5 );

    return (
        <p title={tags.join( ', ' )} className="mb-2">
            <Taglist tags={shownTags} className="p-2 h-6"/>
            {(shownTags.length < tags.length) &&
                <span className="cursor-help text-xs txt-c2 ml-1 hover:opacity-80 whitespace-nowrap" title={tags}>
                + {t( 'asset.plusMore', _.asset.plusMore )}
            </span>
            }
        </p>
    );
}
