export const eventDetailsReducer = ( state, action ) => {
    switch ( action.type ) {

        case 'setEventId':
            return {
                ...state,
                eventId: action.payload.eventId
            };

        case 'setRequestState':
            const edReq = action.payload.request;
            const eventDetail = edReq.state === 'hasValue' ? edReq.contents : {};
            return {
                ...state,
                isLoading: edReq.state !== 'hasValue' && edReq.state !== 'hasError',
                loadError: edReq.state === 'hasError' ? edReq.contents : null,
                ...eventDetail
            }

        default:
            throw Error( `Unknown action "${action.type}" specified for eventDetailsReducer` );
    }
};
