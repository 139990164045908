const toCustomFormToggle = function ( el ) {
    const wrap = document.createElement( 'span' );
    const sibling = wrap.cloneNode();
    const type = el.getAttribute( 'type' );

    const clearRadioList = function () {
        const els = document.querySelectorAll( `input[name="${this.name}"]` );
        Array.from( els ).forEach( x => x.nextSibling.classList.remove( 'checked' ) );
    }
    const toggleField = function () {
        sibling.classList.toggle( 'checked', this.checked );
    };
    const handleChange = ( e ) => {
        const target = e.currentTarget;
        if ( 'radio' === target.type ) {
            clearRadioList.call( target );
        }
        toggleField.call( target );
    }

    // inject the actual radio button in wrapper and make hidden
    el.classList.add( 'custom' );
    el.insertAdjacentElement( 'afterend', wrap );
    wrap.classList.add( 'wrap-check' );
    wrap.appendChild( el );
    // copy classes from input on to faux field for custom field styles
    const copyClasses = [];
    for ( const item of el.classList.values() ) {
        copyClasses.push( item );
    }
    sibling.classList.add( type, ...copyClasses );
    wrap.appendChild( sibling );

    el.addEventListener( 'click', handleChange );
    el.addEventListener( 'change', handleChange );

    toggleField.call( el );

    return el;
};

export {toCustomFormToggle};
