import {useEffect, useReducer} from 'react';
import {InviteUsers} from '@/components/dialogs/InviteUsers';
import {EditAccountDetails} from '@/components/dialogs/EditAccountDetails';
import {ManageUserPermissions} from '@/components/dialogs/ManageUserPermissions';
import {ManageUserRole} from '@/components/dialogs/ManageUserRole';
import {ManageUserRegions} from '@/components/dialogs/ManageUserRegions';
import {notify} from '@/modules/notifier';
import {userManagementReducer} from '@/containers/UserManagement/reducer';
import AccountManagementService from '@/providers/account-management';
import UserService from '@/providers/user';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

import {Roles} from '@/constants/appConstants';
import {t} from '@ultradent/components';

const DATA_DELIM = '|';
const UI_ITEM_DELIM = ', ';

const getUserData = ( userId ) => {
    const targetEl = document.getElementById( `user-${userId}` );

    if ( !targetEl ) {
        throw TypeError( `User data not available for id ${userId}` );
    }

    const dataset = targetEl.dataset;

    return {
        userId: dataset.userId,
        permissions: dataset.userPermissions?.split( DATA_DELIM ).filter( notEmptyOrNil ),
        role: dataset.userRole,
        regions: dataset.userRegions?.split( DATA_DELIM ).filter( notEmptyOrNil )
    }
};

const getAccountDetails = () => {
    const accountDetailsFields = document.getElementById( 'account-details-fields' );

    if ( !accountDetailsFields ) {
        throw TypeError( `Account details not available` );
    }

    const dataset = accountDetailsFields.dataset;

    return {
        id: dataset.accountId,
        accountName: dataset.accountName,
        accountType: dataset.accountType,
        salesRep: dataset.salesRep
    }
}

const updateAccountDetailsUI = ( {selectedAccountType, selectedSalesRep} ) => {
    const accountDetailsFields = document.getElementById( 'account-details-fields' );
    const accountTypeEl = document.getElementById( 'account-type-field' );
    const salesRepEl = document.getElementById( 'sales-rep-field' );

    if ( !accountDetailsFields ) {
        return;
    }

    const dataset = accountDetailsFields.dataset;
    dataset.accountType = selectedAccountType.code;
    dataset.salesRep = selectedSalesRep.code;
    accountTypeEl && (accountTypeEl.textContent = selectedAccountType.name);
    salesRepEl && (salesRepEl.textContent = selectedSalesRep.name);
}

export const UserManagement = ( {crmAccountId} ) => {
    const [state, dispatch] = useReducer( userManagementReducer, {
        crmAccountId,
        processing: false,
        error: null,
        siteFeatures: [],
        userInEdit: {},
        isEditing: {
            accountDetails: false,
            permissions: false,
            role: false,
            invitation: false,
            regions: false
        }
    } );

    useEffect( () => {
        document.addEventListener( 'click', handleUserAction );
        return () => document.removeEventListener( 'click', handleUserAction );
    }, [] );

    useEffect( () => {
        async function getSiteInfo () {
            const resp = await AccountManagementService.getSiteInfo( {accountId: state.crmAccountId} );
            const {Features} = resp.data;
            dispatch( {type: 'setSiteFeatures', payload: {siteFeatures: Features}} );
        }

        getSiteInfo();
    }, [] );

    const onCloseDialog = () => dispatch( {type: 'stopEditing'} );

    const onInviteSuccess = () => {
        dispatch( {type: 'stopEditing'} );
        window.location.reload();
    };

    function handleUserAction ( e ) {
        const actionId = e.target.dataset.action;

        if ( !actionId ) {
            return;
        }

        switch ( actionId ) {
            case 'change-permissions':
                e.preventDefault();
                dispatch( {type: 'editPermissions', payload: getUserData( e.target.dataset.userId )} );
                break;

            case 'change-role':
                e.preventDefault();
                dispatch( {type: 'editRole', payload: getUserData( e.target.dataset.userId )} );
                break;

            case 'change-regions':
                e.preventDefault();
                dispatch( {type: 'editRegion', payload: getUserData( e.target.dataset.userId )} );
                break;

            case 'invite-team-member':
                e.preventDefault();
                if ( !state.crmAccountId ) {
                    notify.error( t(
                        'notify.requestFailUnknownError',
                        'Sorry, this request could not be completed due to an unknown error'
                    ) );

                    console.error( `"Invite member" action ignored; Expected prop 'crmAccountId' but received "${state.crmAccountId}" instead` );
                    return;
                }

                dispatch( {type: 'editMembers'} );
                break;

            case 'edit-account-details':
                e.preventDefault();
                dispatch( {type: 'editAccountDetails', payload: getAccountDetails()} );
                break;

            default:
                return;
        }
    }

    const onSubmitUserUpdate = ( method, updateNode ) =>
        async ( payload ) => {
            if ( state.processing || typeof method !== 'function' ) {
                return;
            }

            dispatch( {type: 'setProcessing', payload: {processing: true}} );

            try {
                await method( payload );
                const node = document.getElementById( `user-${payload.azureAdId}` );
                updateNode( node, payload );
                dispatch( {type: 'stopEditing'} );

                // highlight the edited node
                const highlightColor = 'bg-blue-lightest';
                node.classList.add( highlightColor );
                setTimeout( () => node.classList.remove( highlightColor ), 7000 );
                notify.success( t(
                    'userManagement.notify.userUpdateSuccess',
                    'User settings updated successfully'
                ) );
            }
            catch ( error ) {
                console.error( `[UserUpdate Error]`, error );
                dispatch( {type: 'setError', payload: {error}} );
            }
            finally {
                dispatch( {type: 'setProcessing', payload: {processing: false}} );
            }
        }

    const inviteOptions = {
        crmAccountId: state.crmAccountId,
        isOpen: state.isEditing.invitation,
        availableFeatures: state.siteFeatures,
        onClose: onCloseDialog,
        onInviteSuccess
    };

    const permissionOptions = {
        processing: state.processing,
        error: state.error,
        isOpen: state.isEditing.permissions,
        availableFeatures: state.siteFeatures,
        user: state.userInEdit,
        onClose: onCloseDialog,
        onSubmit: onSubmitUserUpdate( AccountManagementService.updateUserPermissions, ( node, payload ) => {
            node.dataset.userPermissions = String( payload.permissions.join( DATA_DELIM ) );
            node.querySelector( '.js-permissions' ).innerText = String( payload.permissions.join( UI_ITEM_DELIM ) );
        } )
    };

    const roleOptions = {
        processing: state.processing,
        error: state.error,
        isOpen: state.isEditing.role,
        user: state.userInEdit,
        onClose: onCloseDialog,
        onSubmit: onSubmitUserUpdate( AccountManagementService.updateUserRole, ( node, payload ) => {
            node.dataset.userRole = String( payload.isAdmin ? Roles.PARTNER_ADMIN : Roles.MEMBER );
            node.querySelector( '.js-role' ).innerText = payload.isAdmin
                ? t( 'userManagement.label.admin', 'Admin' )
                : t( 'userManagement.label.member', 'Member' );
        } )
    };

    const regionOptions = {
        processing: state.processing,
        error: state.error,
        isOpen: state.isEditing.regions,
        user: state.userInEdit,
        availableRegions: [],
        onClose: onCloseDialog,
        onSubmit: onSubmitUserUpdate( UserService.setRegions, ( node, payload ) => {
            node.dataset.userRegions = String( payload.regions.join( DATA_DELIM ) );
            node.querySelector( '.js-regions' ).innerText =
                String( payload.regions.join( UI_ITEM_DELIM ) );
        } )
    };

    const accountOptions = {
        processing: state.processing,
        error: state.error,
        isOpen: state.isEditing.accountDetails,
        account: state.userInEdit,
        onClose: onCloseDialog,
        onUpdateSuccess: updateAccountDetailsUI
    };

    return (
        <>
            <InviteUsers {...inviteOptions} />
            <ManageUserPermissions {...permissionOptions} />
            <ManageUserRole {...roleOptions} />
            <ManageUserRegions {...regionOptions} />
            <EditAccountDetails {...accountOptions} />
        </>
    );
};

