import coverProductImages from '@/assets/images/resources/tile-cover_product-images.jpg';
import coverClinicals from '@/assets/images/resources/tile-cover_clinicals.jpg';
import coverModels from '@/assets/images/resources/tile-cover_models.jpg';
import coverBranding from '@/assets/images/resources/tile-cover_branding.jpg';
import coverSalesSheets from '@/assets/images/resources/tile-cover_sales-sheets.jpg';
import coverPresentations from '@/assets/images/resources/tile-cover_presentations.jpg';
import heroBackgroundSrc from '@/assets/images/resource-hero-2022-06.jpg';

const FEATURED_COLLECTIONS = [
    /* {
         id: '01',
         src: 'https://via.placeholder.com/625x300/333/555.png',
         url: '/search?keyword=&AssetCategory=Images&AssetType=Product%20Image',
         overline: 'Popular collection',
         title: 'Opalescence product images',
         categories: ['Images', 'Product images']
     }*/
];

const CATEGORY_TILE_LIST = [
    {
        id: 1,
        src: coverProductImages,
        url: '/search?keyword=&AssetCategory=Images&AssetType=Product%20Image',
        title: 'Product images',
        categories: ['Images', 'Product images']
    },
    {
        id: 2,
        src: coverClinicals,
        url: '/search?keyword=&AssetCategory=Images&AssetType=Clinical%20photo',
        title: 'Clinical photos',
        categories: ['Images', 'Clinical photos']
    },
    {
        id: 3,
        src: coverModels,
        url: '/search?keyword=&AssetCategory=Images&AssetType=Model',
        title: 'Models',
        categories: ['Images', 'Models']
    },
    {
        id: 4,
        src: coverBranding,
        url: '/search?keyword=&AssetCategory=Sales%20%26%20Marketing%20Material&AssetType=Branding%20%26%20Logos',
        title: 'Branding & logos',
        categories: ['Sales & marketing material', 'Branding & logos']
    },
    {
        id: 5,
        src: coverSalesSheets,
        url: '/search?keyword=&AssetCategory=Sales%20%26%20Marketing%20Material&AssetType=Sales%20sheet',
        title: 'Sales sheets',
        categories: ['Sales & marketing material', 'Sales sheets']
    },
    {
        id: 6,
        src: coverPresentations,
        url: '/search?keyword=&AssetCategory=Sales%20%26%20Marketing%20Material&AssetType=Presentation',
        title: 'Presentations',
        categories: ['Sales & marketing material', 'Presentation']
    }
];

export default {
    pageTitle: 'Resource center',
    pageSubtitle: 'Find resources for your sale & marketing or regulatory needs.',
    heroImageSrc: heroBackgroundSrc,
    featuredCollectionsSection: {
        title: 'Featured collections',
        subtitle: '',
        list: FEATURED_COLLECTIONS
    },
    categoriesSection: {
        title: 'Popular categories',
        subtitle: 'Explore diverse collections of our most incredible high-resolution, royalty-free, stock assets',
        list: CATEGORY_TILE_LIST
    }
}
