import {ServiceProvider} from '@/providers/provider';
import {anyEmptyOrNil, isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

const svc = new ServiceProvider( {
    basePath: '/assets',
    mockSrc: process.env.MOCK_API && 'assets'
} );

const anonsvc = new ServiceProvider( {
    basePath: '/assets',
    mockSrc: process.env.MOCK_API && 'assets',
    options: {
        useAccessToken: 'anonymous'
    }
} );

function shareAssets ( {emailAddresses, assets} ) {
    if ( anyEmptyOrNil( [emailAddresses, assets] ) ) {
        throw new TypeError( '[shareAssets Error] Missing required param "assets" or "emailAddresses"' );
    }
    return this.xhr.post( 'share', {emailAddresses, assets} );
}

function shareAssetsDirectLink ( {assets} ) {
    if ( isEmptyOrNil( assets ) ) {
        throw new TypeError( '[shareAssetsDirectLink Error] Missing required param "assets"' );
    }
    return this.xhr.post( 'sharelink', {assets} );
}


function getSharedAssets () {
    return this.xhr.get( 'getsharedassets' );
}

svc.shareAssets = shareAssets;
svc.shareAssetsDirectLink = shareAssetsDirectLink;
svc.getSharedAssets = getSharedAssets;
anonsvc.getSharedAssets = getSharedAssets;

export const AssetProvider = svc.xhr;

svc.anon = anonsvc;

export default svc;


