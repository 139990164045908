import UserProvider from '@/providers/user';
import {Modal, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const ProductAckowledgementPrompt = ( {isOpen, onClose} ) =>
    <Modal isOpen={isOpen} size="sm" title={t( 'productAcknowledgement.heading', _.productAcknowledgement.heading )}>
        <section className="mb-8">
            <p>{t( 'productAcknowledgement.description', _.productAcknowledgement.description )}</p>
        </section>
        <section className="mb-8 mx-4 text-center">
            <p className="mb-4">
                <span className="font-semibold">
                    {t( 'productAcknowledgement.body1', _.productAcknowledgement.body1 )}
                </span>
                <span className="block text-small-print text-grey-dark leading-tight my-2">
                    {t( 'productAcknowledgement.body2', _.productAcknowledgement.body2 )}
                </span>
            </p>
            <button
                className="button secondary"
                onClick={async () => {
                    try {
                        await UserProvider.confirmProductAcknowledgementAccepted();
                    }
                    catch ( error ) {
                        console.error( error );
                    }
                    finally {
                        onClose( false );
                    }
                }}>
                {t( 'productAcknowledgement.acceptActionLabel', _.productAcknowledgement.acceptActionLabel )}
            </button>
        </section>
        <section>
            <a href="https://www.ultradent.com/company/terms-of-use" target="_blank" rel="noreferrer"
               className="text-sm link-2">
                {t( 'productAcknowledgement.termsLabel', _.productAcknowledgement.termsLabel )}
            </a>
        </section>
    </Modal>
