import React from 'react';
import {Link, useLocation} from 'react-router-dom';

export const ModalLink = ( {root, to, search, hash, children, ...props} ) => {
    const currentLocation = useLocation();
    const backgroundLocation = currentLocation.state?.backgroundLocation;
    const index = currentLocation.state?.index || 0

    const linkTarget = {
        pathname: to,
        search: search || '',
        hash: hash || ''
    };

    const linkState = {
        currentLocation,
        backgroundLocation: backgroundLocation || currentLocation,
        index: index + 1
    };

    // note - if root is not in current location.pathname then component ws rendered outside of it's expected route
    //  replace with a normal link to exit current router
    //  **This is a temp solution for Asset components and be solved by implementing a global router if possible
    if ( root && window.location.pathname.indexOf( root ) === -1 ) {
        return (
            <a href={`${root}/#${to}`} {...props}>{children}</a>
        );
    }

    return <Link to={linkTarget} state={linkState} {...props}>{children}</Link>;
};
