import React, {useContext, useEffect, useState} from 'react';
import {translationKeys as _} from '@/constants/translations';
import PropTypes from 'prop-types';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {Button, ContextMenu, MediumDevice, Modal, SmallDevice, t} from '@ultradent/components';
import {ArrowDown, ArrowUp, Download, SelectionGroup, Share} from '@/components/common/Icons';
import {DownloadAssets} from '@/components/dialogs/DownloadAssets';
import {ClipboardItemCard} from './ClipboardItemCard';
import useClipboard from '@/components/common/Clipboard/useClipboard';
import {useAssetSharing} from '@/containers/AssetSharing/useAssetSharing';
import {BookmarksContext} from '@/containers/Bookmarks';

export {ClipboardItemCard, useClipboard};

const ClipboardHeader = ( {isOpen, onToggle, selectionCount, onClear, onDownload, onBookmark, onShare} ) =>
    <div className="flex h-20 p-4 md:px-10 items-center">
        <Button small
                textLink
                className="text-grey-dark hover:link-2 mr-4"
                onClick={onToggle}
                secondary
                aria-label={isOpen
                    ? t( 'clipboard.actionLabel.hideSelections', _.clipboard.actionLabel.hideSelections )
                    : t( 'clipboard.actionLabel.viewSelections', _.clipboard.actionLabel.viewSelections )}>
            {isOpen ? <ArrowDown width="32"/> : <ArrowUp width="32"/>}
        </Button>
        <span className="flex flex-auto items-center">
            <SelectionGroup width="20" className="flex-none mr-2 text-blue"/>
            <h2 className="subheading-base mr-4 leading-tight">
                {t(
                    'clipboard.label.itemSelectedWithCount',
                    _.clipboard.label.itemSelectedWithCount,
                    {count: selectionCount}
                )}
            </h2>
            <span className="hidden md:block">
                <Button textLink className="tertiary" onClick={onClear}>{t(
                    'clipboard.actionLabel.clearAll',
                    _.clipboard.actionLabel.clearAll )}</Button>
            </span>
        </span>
        <div className="flex justify-end">
            <SmallDevice>
                <Button small info className="mr-4">
                    <Download width="20" className="flex-none" onClick={onDownload}/>
                </Button>
            </SmallDevice>
            <MediumDevice>
                <Button tertiary className="mr-4" onClick={onShare} icon={Share} iconClassName="w-5">
                    {t( 'clipboard.actionLabel.shareAll', _.clipboard.actionLabel.shareAll )}
                </Button>
                <Button info className="sm mr-4" onClick={onDownload} icon={Download} iconClassName="w-5">
                    {t( 'clipboard.actionLabel.downloadAll', _.clipboard.actionLabel.downloadAll )}
                </Button>
            </MediumDevice>
            <ContextMenu horizontal className="ml-4 md:ml-8">
                <MenuContent
                    isOpen={isOpen}
                    onToggle={onToggle}
                    onBookmark={onBookmark}
                    onDownload={onDownload}
                    onClear={onClear}
                    onShare={onShare}
                />
            </ContextMenu>
        </div>
    </div>

const MenuContent = ( {isOpen, onToggle, onBookmark, onDownload, onClear, onShare} ) =>
    <div className="flex flex-col w-40 text-base">
        <button className="menu-context--item" onClick={onBookmark}>
            {t( 'clipboard.actionLabel.bookmarkAll', _.clipboard.actionLabel.bookmarkAll )}
        </button>
        <button className="menu-context--item" onClick={onShare}>
            {t( 'clipboard.actionLabel.shareAll', _.clipboard.actionLabel.shareAll )}
        </button>
        <button className="menu-context--item" onClick={onDownload}>
            {t( 'clipboard.actionLabel.downloadAll', _.clipboard.actionLabel.downloadAll )}
        </button>
        <hr className="menu-context--divider"/>
        <button className="menu-context--item" onClick={onClear}>
            {t( 'clipboard.actionLabel.clearAll', _.clipboard.actionLabel.clearAll )}
        </button>
        <hr className="menu-context--divider"/>
        <button className="menu-context--item" onClick={onToggle}>
            {isOpen
                ? t( 'clipboard.actionLabel.hideSelections', _.clipboard.actionLabel.hideSelections )
                : t( 'clipboard.actionLabel.viewSelections', _.clipboard.actionLabel.viewSelections )}
        </button>
    </div>

export const ClipboardContext = React.createContext();

export const Clipboard = ( {storyKey, itemRenderer, children, initialOpen = false, ...props} ) => {
    const [isOpen, setIsOpen] = useState( initialOpen );
    const [isDownloadOpen, setIsDownloadOpen] = useState( false );
    const {clipboard, onRemoveClipboardItem, onClearClipboard} = useClipboard( storyKey );
    const {onShareAssets} = useAssetSharing();
    const {onBookmarkAll} = useContext( BookmarksContext );
    const onDownload = () => setIsDownloadOpen( true );

    useEffect(
        () => isEmptyOrNil( clipboard ) && setIsOpen( false ),
        [clipboard]
    );

    const ModalHeader = ( {isClosable, onClose} ) =>
        <div className="translate--y relative z-20 bg-white shadow-2">
           <ClipboardHeader
               selectionCount={clipboard.length}
               isOpen={isOpen}
               onBookmark={onBookmarkAll( clipboard )}
               onShare={onShareAssets( clipboard )}
               onDownload={onDownload}
               onClear={onClearClipboard}
               onToggle={() => setIsOpen( bool => !bool )}
           />
        </div>;

    if ( isEmptyOrNil( clipboard ) ) {
        return null;
    }

    return (
        <div data-testid="Clipboard">
            <div className="fixed z-10 bottom-0 left-0 right-0 bg-near-white slide-up">
                <ClipboardHeader
                    selectionCount={clipboard.length}
                    isOpen={isOpen}
                    onBookmark={onBookmarkAll( clipboard )}
                    onShare={onShareAssets( clipboard )}
                    onDownload={onDownload}
                    onClear={onClearClipboard}
                    onToggle={() => setIsOpen( bool => !bool )}
                />
            </div>
            <Modal
                id="clipboard"
                onClose={() => setIsOpen( false )}
                drawer={true}
                isOpen={isOpen}
                contentClassName="bg-moon-grey"
                slotHeader={ModalHeader}>
                 <div
                     className="fade-in w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-1 md:gap-3">
                    {clipboard.map( itemProps =>
                        <ClipboardItemCard key={itemProps.id} onRemove={onRemoveClipboardItem( itemProps )}>
                            {React.createElement( itemRenderer, {key: `clipboard-${itemProps.id}`, ...itemProps} )}
                        </ClipboardItemCard>
                    )}
                </div>
            </Modal>
            <DownloadAssets
                assets={clipboard}
                isOpen={isDownloadOpen}
                onClose={() => setIsDownloadOpen( false )}/>
        </div>

    );
}

Clipboard.propTypes = {
    storyKey: PropTypes.string.isRequired
};



