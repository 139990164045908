import ProductAnswersRepository from '@/providers/product-answers';
import {SearchBar, SuggestionItem} from '@/containers/FacetedSearch/PageNavigation/SearchBar';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ChatBubbles} from '@/components/common/Icons';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {formatQueryParams} from '@/containers/FacetedSearch/transforms';

const AUTOCOMPLETE_RESULT_PROP = 'queryPlusText';
const SUGGESTION_RESULT_PROP = '@search.text';

export const SearchSuggestions = ( {initialQuery, onSubmit} ) => {
    const navigate = useNavigate();
    return <SearchBar
        placeholder={t( 'searchBar.productAnswersPlaceholder', _.searchBar.productAnswersPlaceholder )}
        querySuggestionsRepository={ProductAnswersRepository}
        initialQuery={initialQuery}
        onSubmit={onSubmit}
        queryGroup={[
            {
                transientQueryProp: AUTOCOMPLETE_RESULT_PROP,
                renderer: result => (
                    <SuggestionItem>{result[AUTOCOMPLETE_RESULT_PROP]}</SuggestionItem>
                )
            },
            {
                groupName: t( 'search.suggestion.inProductAnswers', _.search.suggestion.inProductAnswers ),
                renderer: result => (
                    <SuggestionItem>
                        <div className="flex items-start">
                            <ChatBubbles width="20" className="flex-none mr-2"/>
                            <span className="line-clamp-4">{result[SUGGESTION_RESULT_PROP]}</span>
                        </div>
                    </SuggestionItem>
                )
            }
        ]}
        onResultSelect={( evt, result ) => {
            if ( result[AUTOCOMPLETE_RESULT_PROP] ) {
                navigate( {
                    pathname: '/search',
                    search: formatQueryParams( {
                        keyword: result[AUTOCOMPLETE_RESULT_PROP]
                    } )
                } );
                return;
            }
            if ( result[SUGGESTION_RESULT_PROP] ) {
                navigate( {
                    pathname: '/search',
                    search: formatQueryParams( {
                        keyword: result[SUGGESTION_RESULT_PROP]
                    } )
                } );
            }
        }}

    />
}

SearchSuggestions.defaultProps = {
    initialQuery: '',
    onSubmit: () => {}
};
