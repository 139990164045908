// import {buildUserProfile} from '@/test/generate';
import searchResp from '../../apps/ResourceCenter/_mocks/search-response.json';

console.warn( '[Mocking] "resource-center" api' );

export default function ( instance ) {
    instance
        .onGet( /\/search\?.*/ )
        .reply( () => ([
            200,
            searchResp
        ]) );

    instance
        .onGet( '/taxonomy' )
        .reply( () => ([
            200,
            [{
                'Name': 'Corporate',
                'Id': 'Corporate',
                'Children': [{'Name': 'Corporate Image', 'Id': 'Corporate Image'},
                    {'Name': 'Ultradent Logo', 'Id': 'Ultradent Logo'},
                    {'Name': 'Video', 'Id': 'Video'},
                    {'Name': 'Presentation', 'Id': 'Presentation'}]
            },
                {
                    'Name': 'Images',
                    'Id': 'Images',
                    'Children': [{'Name': 'Product Image', 'Id': 'Product Image'},
                        {'Name': 'Clinical photo', 'Id': 'Clinical photo'},
                        {'Name': 'Model', 'Id': 'Model'}]
                },
                {'Name': 'Regulatory', 'Id': 'Regulatory', 'Children': []},
                {
                    'Name': 'Sales & Marketing Material',
                    'Id': 'Sales & Marketing Material',
                    'Children': [{'Name': 'Brochure', 'Id': 'Brochure'},
                        {'Name': 'Flyer', 'Id': 'Flyer'},
                        {'Name': 'Advertisement', 'Id': 'Advertisement'},
                        {'Name': 'Branding & Logos', 'Id': 'Branding & Logos'},
                        {'Name': 'Poster', 'Id': 'Poster'},
                        {'Name': 'Sales sheet', 'Id': 'Sales sheet'},
                        {'Name': 'Presentation', 'Id': 'Presentation'},
                        {'Name': 'Video', 'Id': 'Video'},
                        {'Name': 'Catalog', 'Id': 'Catalog'}]
                },
                {
                    'Name': 'Training Material',
                    'Id': 'Training Material',
                    'Children': [{'Name': 'Video', 'Id': 'Video'}]
                }]
        ]) );

    instance
        .onGet( 'AssetDetails' )
        .reply( () => ([
            200,
            {
                'isNew': true,
                'dateApproved': '20220414183940053',
                'id': '0300bd6c-ac28-40d8-a547-79bef2c90d05',
                'taxonomies': [['Corporate', 'Corporate Image']],
                'time': '20220414183940053',
                'tag': ['corporate marketing material', 'nature', 'sunset', 'Utah', 'Delicate Arch'],
                'description': 'Ultra-Blend plus Logo in blue.',
                'created': '20220414183940053',
                'width': 5193,
                'height': 3466,
                'dpi': 300.0,
                'Sizes': [
                    {
                        'Alias': 'Large',
                        'Width': 800,
                        'Height': 600,
                        'Mimetype': 'image/jpeg',
                        'Resolution': 72,
                        'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                    },
                    {
                        'Alias': 'Original',
                        'FileSize': 99000,
                        'Width': 1600,
                        'Height': 1200,
                        'Mimetype': 'image/jpeg',
                        'Resolution': 300,
                        'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                    }
                ],
                'additional': {
                    'Variant Asset': 'Primary',
                    'Asset Category': ['Corporate'],
                    'Product': [],
                    'Asset Title': 'La Sal Mountains',
                    'Product Family': [],
                    'Region(s)': ['APAC & SS', 'Brazil', 'Canada', 'China', 'Japan', 'LATAM', 'NE Europe', 'SEMENA'],
                    'Asset Type': ['Corporate Image'],
                    'Paper Size': [],
                    'Related Asset Details': [
                        {
                            'isNew': false,
                            'dateApproved': '20220506185226247',
                            'id': 'b702e32d-5384-490e-9ace-ae1e9959c056',
                            'Sizes': [
                                {
                                    'Alias': 'Large',
                                    'Width': 400,
                                    'Height': 300,
                                    'Mimetype': 'image/jpeg',
                                    'Resolution': 96,
                                    'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                                },
                                {
                                    'Alias': 'Original',
                                    'FileSize': 23000,
                                    'Width': 1200,
                                    'Height': 900,
                                    'Mimetype': 'image/jpeg',
                                    'Resolution': 180,
                                    'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                                }
                            ],
                            'taxonomies': [
                                [
                                    'Images',
                                    'Model'
                                ]
                            ],
                            'time': '20220506185226247',
                            'tag': [
                                'brown hair',
                                'dark hair',
                                'female',
                                'hand on neck',
                                'striped shirt',
                                'stripes',
                                'white',
                                'black and white',
                                'Opalescence model'
                            ],
                            'description': 'Stock model photo for Opalescence. Avalon wearing sleeveless white/black stripe shirt.',
                            'created': '20220506185226247',
                            'width': 2378,
                            'height': 4544,
                            'dpi': 118,
                            'additional': {
                                'Variant Asset': 'Primary',
                                'Asset Category': [
                                    'Images'
                                ],
                                'Product': [
                                    'Whiten - Opalescence 10%'
                                ],
                                'Asset Title': 'Opalescence Avalon Model Photo',
                                'CE Mark': 'Yes',
                                'Product Family': [
                                    'Whiten'
                                ],
                                'Region(s)': [
                                    'APAC & SS',
                                    'Brazil',
                                    'Canada',
                                    'China',
                                    'Japan',
                                    'LATAM',
                                    'NE Europe',
                                    'SEMENA'
                                ],
                                'Asset Type': [
                                    'Model'
                                ],
                                'Related Assets': [],
                                'Paper Size': []
                            },
                            'latestVersionNumber': 0,
                            'lastUploaded': '20220506185226247',
                            'approvalStatus': 'Approved',
                            'name': 'Opalescence_Avalon-2327_2019.png',
                            'size': 45647855,
                            'scheme': 'PNG',
                            'mimeType': 'image/png',
                            'fileExtension': 'png',
                            'Valid': false
                        },
                        {
                            'isNew': false,
                            'dateApproved': '20220506185225827',
                            'id': '26bafb04-de6f-4a9b-9613-57b91e7387e7',
                            'taxonomies': [
                                [
                                    'Images',
                                    'Model'
                                ]
                            ],
                            'time': '20220506185225827',
                            'tag': [
                                'dark hair',
                                'female',
                                'laughing',
                                'white',
                                'white shirt',
                                'brown hair'
                            ],
                            'description': 'Image of the Green Opalescence Pocket Tray Case for Custom Trays.',
                            'created': '20220506185225827',
                            'width': 2721,
                            'height': 4447,
                            'dpi': 118,
                            'additional': {
                                'Variant Asset': 'Primary',
                                'Asset Category': [
                                    'Images'
                                ],
                                'Product': [
                                    'Whiten - Opalescence 10%',
                                    'Whiten - Opalescence Boost',
                                    'Whiten - Opalescence Endo',
                                    'Whiten - Opalescence Gift Bags',
                                    'Whiten - Opalescence GO',
                                    'Whiten - Opalescence Office',
                                    'Whiten - Opalescence PF',
                                    'Whiten - Opalescence Pocket Tray Cases',
                                    'Whiten - Opalescence Quick PF',
                                    'Whiten - Opalescence Shade Guide Card',
                                    'Whiten - Opalescence Shade Guide Sticker',
                                    'Whiten - Opalescence Treswhite Supreme',
                                    'Whiten - Opalescence Whitening Toothpaste'
                                ],
                                'Asset Title': 'Opalescence Avalon',
                                'CE Mark': 'Yes',
                                'Product Family': [
                                    'Whiten'
                                ],
                                'Region(s)': [
                                    'APAC & SS',
                                    'Brazil',
                                    'Canada',
                                    'China',
                                    'Japan',
                                    'LATAM',
                                    'NE Europe',
                                    'SEMENA'
                                ],
                                'Asset Type': [
                                    'Model'
                                ],
                                'Related Assets': [
                                    '566c643a-fe5a-4d9a-85ba-5eecd9d79702',
                                    '003a0722-934d-4ffe-bd9a-8d0e556fff67',
                                    'b702e32d-5384-490e-9ace-ae1e9959c056'
                                ],
                                'Paper Size': []
                            },
                            'latestVersionNumber': 0,
                            'lastUploaded': '20220506185225827',
                            'approvalStatus': 'Approved',
                            'name': 'Opalescence_Avalon-1716-2019.png',
                            'size': 40897910,
                            'scheme': 'PNG',
                            'mimeType': 'image/png',
                            'fileExtension': 'png',
                            'Valid': false
                        },
                        {
                            'isNew': true,
                            'dateApproved': '20220506185223991',
                            'id': '6cc820a5-0aba-4ffb-be77-a71de3195499',
                            'Sizes': [
                                {
                                    'Alias': 'Large',
                                    'Width': 600,
                                    'Height': 400,
                                    'Mimetype': 'image/jpeg',
                                    'Resolution': 96,
                                    'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                                },
                                {
                                    'Alias': 'Original',
                                    'FileSize': 199000,
                                    'Width': 4000,
                                    'Height': 3200,
                                    'Mimetype': 'image/jpeg',
                                    'Resolution': 600,
                                    'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                                }
                            ],
                            'taxonomies': [
                                [
                                    'Images',
                                    'Model'
                                ]
                            ],
                            'time': '20220506185223991',
                            'tag': [],
                            'description': 'Opalescence Whitening Toothpaste logo',
                            'created': '20220506185223991',
                            'width': 6912,
                            'height': 9456,
                            'dpi': 118,
                            'additional': {
                                'Variant Asset': 'Primary',
                                'Asset Category': [
                                    'Images'
                                ],
                                'Product': [
                                    'Whiten - Opalescence 10%',
                                    'Whiten - Opalescence Boost',
                                    'Whiten - Opalescence Endo',
                                    'Whiten - Opalescence GO',
                                    'Whiten - Opalescence Office',
                                    'Whiten - Opalescence PF',
                                    'Whiten - Opalescence Pocket Tray Cases',
                                    'Whiten - Opalescence Quick PF',
                                    'Whiten - Opalescence Treswhite Supreme',
                                    'Whiten - Opalescence Whitening Toothpaste'
                                ],
                                'Asset Title': 'Opalescence Brianna Pink Blazer 5338',
                                'CE Mark': 'Yes',
                                'Product Family': [
                                    'Whiten'
                                ],
                                'Region(s)': [
                                    'APAC & SS',
                                    'Brazil',
                                    'Canada',
                                    'China',
                                    'Japan',
                                    'LATAM',
                                    'NE Europe',
                                    'SEMENA'
                                ],
                                'Asset Type': [
                                    'Model'
                                ],
                                'Related Assets': [
                                    '566c643a-fe5a-4d9a-85ba-5eecd9d79702',
                                    '003a0722-934d-4ffe-bd9a-8d0e556fff67',
                                    'b702e32d-5384-490e-9ace-ae1e9959c056'
                                ],
                                'Paper Size': []
                            },
                            'latestVersionNumber': 0,
                            'lastUploaded': '20220506185223991',
                            'approvalStatus': 'Approved',
                            'name': 'Opalescence_Brianna_Pink_Blazer-5338-1121.png',
                            'size': 72742096,
                            'scheme': 'PNG',
                            'mimeType': 'image/png',
                            'fileExtension': 'png',
                            'Valid': false
                        }
                    ],
                    'Related Assets': ['b702e32d-5384-490e-9ace-ae1e9959c056']
                },
                'latestVersionNumber': 0,
                'lastUploaded': '20220414183940053',
                'approvalStatus': 'Approved',
                'name': 'shutterstock_La Sal Mountains, as seen through Delicate Arch.jpg',
                'size': 13427761,
                'scheme': 'JPG',
                'mimeType': 'image/jpeg',
                'fileExtension': 'jpg',
                'Valid': false
            }
        ]) );
}
