import {Button, Checkbox, Modal, t} from '@ultradent/components';
import {FormikProvider, useFormik} from 'formik';
import {Cross} from '@/components/common/Icons';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {translationKeys as _} from '@/constants/translations';

export const PermissionOptionList = ( {availableFeatures = [], error, disabled, onChange, onBlur} ) =>
    <div className="mb-8">
        <p className="text-body-sm mb-4">
            {t( 'userManagement.label.whichFeatures', _.userManagement.label.whichFeatures )}
            <br/>
            <span className="text-caption">
                {t( 'userManagement.label.whichFeaturesHint', _.userManagement.label.whichFeaturesHint )}
            </span>
        </p>
        {error &&
            <div className="my-4 text-fail text-base leading-snug">
            <h3 className="mb-2 font-medium text-sm">{error}</h3>
        </div>
        }
        {availableFeatures.length > 0
            ?
            <fieldset className="grid grid-cols-2">
                {availableFeatures.map( feature =>
                    <Checkbox name="permissions" disabled={disabled} key={feature} value={feature} label={feature}
                              onChange={onChange} onBlur={onBlur}/>
                )}
            </fieldset>
            :
            <p className="text-grey-dark flex items-center">
                <Cross width="16" height="16" className="inline-block mr-2 text-fail"/> {t(
                'userManagement.notify.noFeaturesAvailable',
                _.userManagement.notify.noFeaturesAvailable
            )}
            </p>
        }
    </div>

export const ManageUserPermissions = ( {user, availableFeatures, processing, error, isOpen, onSubmit, onClose} ) => {

    const allowedFeature = feature => availableFeatures.includes( feature );
    const formik = useFormik( {
        enableReinitialize: true,
        validate: ( values ) => {
            const errors = {};
            if ( values.permissions.length <= 0 ) {
                errors.permissions =
                    t( 'userManagement.notify.atLeastOnePermissionRequired',
                        _.userManagement.notify.atLeastOnePermissionRequired )
            }
            return errors;
        },
        onSubmit: values => {
            onSubmit( {
                azureAdId: user.userId,
                permissions: values.permissions
            } );
            return false;
        },
        initialValues: {
            permissions: user.permissions?.filter( allowedFeature )
        }
    } );

    return (
        <Modal
            title={t( 'userManagement.label.permissionsFormTitle', _.userManagement.label.permissionsFormTitle )}
            size="md"
            onClose={onClose}
            isOpen={isOpen}>

            {error &&
                <div className="my-4 text-fail text-base leading-snug">
                <h3 className="mb-2 font-medium text-sm">{t(
                    'userManagement.notify.changeSubmissionFailed',
                    _.userManagement.notify.changeSubmissionFailed )}</h3>
            </div>
            }
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>

                    <PermissionOptionList availableFeatures={availableFeatures}
                                          onBlur={formik.handleBlur}
                                          error={formik.errors.permissions}/>
                    <div className="flex w-full justify-end">
                    <Button
                        type="submit"
                        disabled={!isEmptyOrNil( formik.errors ) || availableFeatures.length <= 0}
                        loading={processing}>
                        {t( 'userManagement.actionLabel.updatePermissions',
                            _.userManagement.actionLabel.updatePermissions )}
                    </Button>
                </div>
                </form>
            </FormikProvider>
        </Modal>
    )
};

ManageUserPermissions.defaultProps = {
    isOpen: false,
    processing: false,
    onClose: () => {},
    onSubmit: () => {},
    availableFeatures: [],
    user: {
        userId: null,
        permissions: [] // ['Resource Center','Sales Dashboard'] etc
    }
}
