import React from 'react';
import MediaQueries from '@ultradent/utilities/MediaQueries';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import imageAstringident from '@/assets/images/products/product-astringident.png';
import imageValo from '@/assets/images/products/product-valo-ortho.png';

const astringidentSmallStyle = {
    marginRight: '-80px',
    width: '250px'
};

const astringidentMediumStyle = {
    marginRight: '-20px',
    width: '350px'
};

const astringidentLargeStyle = {
    marginRight: '-20px',
    transform: `translateY(-50%)`,
    width: '500px'
};

export const PopularTopicsSection = ( {title, subtitle, tiles = [], loading = false} ) => {
    const mqSm = useMediaQuery( {query: MediaQueries.max.screenMedium} )
    const mqMd = useMediaQuery( {query: MediaQueries.max.screenLarge} )
    let astringidentStyle = astringidentLargeStyle;
    if ( mqSm ) {
        astringidentStyle = astringidentSmallStyle;
    }
    if ( mqMd ) {
        astringidentStyle = astringidentMediumStyle;
    }

    return (
        <section className="relative bg-grey-lightest mb-16 pt-10 pb-32 md:py-40">
            <img src={imageAstringident} style={astringidentStyle} alt="Astringident"
                 className="block absolute top-0 right-0" width="400"/>
            <div className="contain-content pad-content">
                    <div className="md:flex flex-row-reverse">
                        <div className="flex w-1/2 items-center justify-center">
                            <div className="mb-8 md:m-0 md:max-w-xs w-full">
                                {loading
                                    ?
                                    <>
                                        <SkeletonElement className="m-0 mb-8 h-12 bg-grey-light w-full"/>
                                        <SkeletonElement type="text" className="bg-grey-light"/>
                                        <SkeletonElement type="text" className="bg-grey-light"/>
                                        <SkeletonElement type="text mb-16" className="bg-grey-light"/>
                                        <Link className="button lg gradient" to="/search?keyword=">
                                            {t( 'researchStudiesPage.actionLabel.browseAll',
                                                _.researchStudiesPage.actionLabel.browseAll )}
                                        </Link>
                                    </>
                                    :
                                    <>
                                        <h3 className="headline-lg leading-tight mb-4">{title}</h3>
                                        <p className="text-body mb-8">{subtitle}</p>
                                        <Link className="button lg gradient" to="/search?keyword=">
                                            {t( 'researchStudiesPage.actionLabel.browseAll',
                                                _.researchStudiesPage.actionLabel.browseAll )}
                                        </Link>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 md:w-1/2">
                            {loading ?
                                <>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                </>
                                : tiles?.map( ( {productFamily, topicKeyword} ) =>
                                    <Link
                                        data-testid="PopularTopicTile"
                                        key={productFamily}
                                        className="bg-eggshell-white rounded-lg hover:bg-white hover:text-blue t-color t-duration-short"
                                        to={`/search?keyword=&ProductFamilies=${encodeURIComponent( topicKeyword )}`}>
                                    <div className="flex flex-col h-full py-6 px-8 w-full">
                                        <h4 className="eyebrow-headline-xs">
                                            {t( 'researchStudiesPage.label.topic', _.researchStudiesPage.label.topic )}
                                        </h4>
                                        <h3 className="flex-1 subheading-lg leading-tight mb-4 md:mb-8">{productFamily}</h3>
                                        <span className="link-2 justify-self-end">
                                            {t( 'researchStudiesPage.actionLabel.browseArticles',
                                                _.researchStudiesPage.actionLabel.browseArticles )}
                                        </span>
                                    </div>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            <img src={imageValo} alt="VALO Ortho" className="absolute right-0" width="900"/>
        </section>
    );
}

