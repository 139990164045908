class LocalStore {
    store = window.localStorage;
    listeners = [];
    storeKey = 'upi-pp-generic-store';
    defaultValue = null;

    constructor ( storeKey, storageArea = window.localStorage ) {
        // configure storage type
        if ( storageArea != null ) {
            this.store = storageArea;
        }

        this.storeKey = storeKey;

        if ( !this.store.getItem( this.storeKey ) ) {
            this.initialize();
        }

        window.addEventListener( 'onstoragechange', e => {
            if ( e.storageArea !== this.store || e.key !== this.storeKey ) {
                return;
            }

            this.listeners.forEach( listener => {
                listener( e.newValue );
            } )
        } );
    }

    setDefaultValue ( value ) {
        this.defaultValue = value;
    }

    /**
     * Initialize store without dispatching "change" event
     */
    initialize () {
        this.store.setItem( this.storeKey, '{}' );
    }

    getStore ( key ) {
        try {
            const data = JSON.parse( this.store.getItem( this.storeKey ) );

            if ( !data[key] ) {
                data[key] = [];
                this.store.setItem( this.storeKey, JSON.stringify( data ) );
            }

            return data[key];
        }
        catch ( err ) {
            console.error( '[Failed to retrieve Store]', err.message );
            // clear the store contents and fail gracefully
            this.initialize();
            return [];
        }
    }

    setStore ( key, newData ) {
        try {
            const data = JSON.parse( this.store.getItem( this.storeKey ) );
            data[key] = newData;

            this.store.setItem( this.storeKey, JSON.stringify( data ) );

            const storageEvent = new Event( 'onstoragechange' );

            storageEvent.key = this.storeKey;
            storageEvent.storageArea = this.store;
            storageEvent.newValue = newData;

            window.dispatchEvent( storageEvent );
        }
        catch ( err ) {
            console.error( '[Failed to save Store]', err.message );
        }
    }

    unloadStoreItem ( key ) {
        try {
            const data = JSON.parse( this.store.getItem( this.storeKey ) );
            delete data[key];

            this.store.setItem( this.storeKey, JSON.stringify( data ) );

            const storageEvent = new Event( 'onstoragechange' );

            storageEvent.key = this.storeKey;
            storageEvent.storageArea = this.store;
            storageEvent.newValue = null;

            window.dispatchEvent( storageEvent );
        }
        catch ( err ) {
            console.error( '[Failed to save Store]', err.message );
        }
    }

    clearStoreItem ( key ) {
        this.setStore( key, this.defaultValue );
    }

    clearStore () {
        this.store.setItem( this.storeKey, '{}' );
    }

    subscribe ( fn ) {
        const index = (typeof fn === 'function')
            ? this.listeners.push( fn ) - 1
            : -1;

        return () => this.unsubscribe( index );
    }

    unsubscribe ( index ) {
        if ( index === -1 ) {
            console.warn( '[LocalStore] Unsubscribe failed - listener not found' );
            return;
        }

        this.listeners = [
            ...this.listeners.slice( 0, index ),
            ...this.listeners.slice( index + 1 )
        ];
    }
}

export default LocalStore;
