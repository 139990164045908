import React, {useState} from 'react';
import {FormikProvider, useFormik} from 'formik';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {Search, SkeletonElement} from '@ultradent/components';
import ResponseListFilterBar from './FilterBar';
import RegistrantLine from './RegistrantLine';

const ResponseList = ( {registrantList, loading, error, eventId} ) => {
    const [searchSort, setSearchSort] = useState( 'dateSubmitted:desc' );
    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            statusFilters: []
        }
    } );

    if ( loading ) {
        return <ResponseList.Skeleton count={7}/>
    }

    if ( error ) {
        return (
            <div className="text-center my-8 flex flex-col items-center">
                <h2 className="headline-base text-fail">There was a problem loading this information</h2>
                <p className="text-body text-grey-dark mb-8">Please try refreshing the page or check back later</p>
                <a href={`/events/${eventId}`} className="button primary">Back to Event</a>
            </div>
        )
    }

    if ( isEmptyOrNil( registrantList ) ) {
        return (
            <div className="text-center my-8 flex flex-col items-center">
                <h2 className="headline-base">There are no registration requests right now</h2>
                <p className="text-body text-grey-dark mb-8">Please check back later</p>
                <a href={`/events/${eventId}`} className="button primary">Back to Event</a>
            </div>
        )
    }

    return (
        <FormikProvider value={formik}>
            <Search
                resultLimit={100}
                initialQuery={''}
                minQueryLength={2}
                showPagination={true}
                showDefaultResults={true}
                showNoResults={true}
                alwaysShowResults={true}
                queryGroup={[
                    {
                        list: registrantList,
                        queryProps: [
                            'companyName',
                            'fullName',
                            'email',
                            'irmFullName',
                            'phoneNumber',
                            'status',
                            'country'
                        ],
                        renderer: RegistrantLine,
                        sort: searchSort,
                        filter: ( {status} ) =>
                            notEmptyOrNil( formik.values.statusFilters )
                                ? formik.values.statusFilters.includes( status )
                                : true
                    }
                ]}>
                {searchProps =>
                    <div data-testid="filterlist-pane">
                        <ResponseListFilterBar
                            sortValue={searchSort}
                            onSortChange={setSearchSort}
                            onClearAllFilters={() =>
                                formik.setFieldValue( 'statusFilters', [] )}
                            onRemoveFilter={filter =>
                                formik.setFieldValue( 'statusFilters',
                                    formik.values.statusFilters.filter( f => f !== filter ) )}
                            appliedFilters={formik.values.statusFilters}/>

                        <div className="leading-tight">
                            <dl className="hidden md:flex py-4 px-2 font-bold text-left">
                                <dd className="md:flex-1 px-1 ellipsis">Company</dd>
                                <dd className="md:flex-1 px-1 ellipsis">Name</dd>
                                <dd className="md:flex-1 px-1 ellipsis">Email & Phone</dd>
                                <dd className="md:flex-1 px-1 ellipsis">Date Submitted</dd>
                                <dd className="md:flex-1 px-1 ellipsis">Status</dd>
                                <dd className="md:flex-1">&nbsp;</dd>
                            </dl>
                            <Search.Results
                                groupLabelClass="headline-sm mb-2"
                                groupResultsClass="mb-6"
                                resultItemClass="odd:bg-moon-grey"
                                noResultsClass="my-8 p-6 text-2xl text-black text-center rounded border border-2 border-dashed">
                                {results =>
                                    <div data-testid="filterlist-results">
                                    {results}
                                    </div>
                                }
                            </Search.Results>
                        </div>
                    </div>
                }
            </Search>
        </FormikProvider>
    )
}

ResponseList.Skeleton = ( {count} ) =>
    <div data-testid="ResponseList.Skeleton">
        <div className="hidden md:flex py-4 font-bold text-left">
            <div className="md:w-2/5"><SkeletonElement className="w-16"/></div>
            <div className="md:w-2/5"><SkeletonElement className="w-24"/></div>
            <div className="md:w-2/5"><SkeletonElement className="w-32"/></div>
            <div className="md:w-2/5"><SkeletonElement className="w-16"/></div>
            <div className="md:w-1/5"><SkeletonElement className="w-24"/></div>
            <div className="md:w-1/5">&nbsp;</div>
        </div>
        {Array.from( {length: count || 5} ).map( ( _, index ) =>
            <div key={index} className="md:flex items-center px-2 py-1 leading-normal even:bg-moon-grey">
                <div className="md:w-2/5"><SkeletonElement className="w-1/2 my-3"/></div>
                <div className="md:w-2/5"><SkeletonElement className="w-1/2 my-3"/></div>
                <div className="md:w-2/5"><SkeletonElement className="w-1/2 my-3"/></div>
                <div className="md:w-2/5"><SkeletonElement className="w-1/2 my-3"/></div>
                <div className="md:w-1/5"><SkeletonElement className="w-1/2 my-3"/></div>
                <div className="md:w-1/5"><SkeletonElement className="w-1/2 my-3"/></div>
            </div>
        )}
    </div>

export {ResponseList};
