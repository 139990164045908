import React, {useContext} from 'react';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import SEO from '@/components/common/SEO';
import {ProductAnswersAppContext, verifyOrFallBackContent} from '@/apps/ProductAnswers/appContext';
import {HeaderSection} from './Header';
import {PopularTopicsSection} from './PopularTopics';
import {FAQSection} from './FAQ';

const ProductAnswersQuery = () => {
    const {pageModelApi} = useContext( ProductAnswersAppContext );
    if ( pageModelApi.loading ) {
        return (
            <div className="condense--pad-for-site-header">
                <HeaderSection loading={true}/>
                <PopularTopicsSection loading={true}/>
            </div>
        );
    }

    const {
        heroImageSrc,
        pageTitle,
        pageSubtitle,
        popularTopics,
        faq
    } = verifyOrFallBackContent( pageModelApi.pageModel );

    return (
        <div className="condense--pad-for-site-header">
            <SEO title={pageTitle}/>
            <HeaderSection backgroundImageSrc={heroImageSrc} title={pageTitle} subtitle={pageSubtitle}/>
            {notEmptyOrNil( popularTopics.tiles ) && <PopularTopicsSection {...popularTopics}/>}
            {notEmptyOrNil( faq.list ) && <FAQSection {...faq}/>}
        </div>
    );
}

export {ProductAnswersQuery}
