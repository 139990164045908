import React, {useContext} from 'react';
import SEO from '@/components/common/SEO';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {ResourceAppContext, verifyOrFallBackContent} from '@/apps/ResourceCenter/appContext';
import {CategoryLinkList} from '@/components/common/PageNavigation/CategoryLinkList';
import {HeaderSection} from './Header';
import {FeaturedCollectionsSection} from './FeaturedCollections';
import {PopularCategoriesSection} from './PopularCategories';

export const Resources = () => {

    const {categoryTaxonomy, pageModelApi} = useContext( ResourceAppContext );

    if ( pageModelApi.loading ) {
        return (
            <div className="condense--pad-for-site-header">
                <HeaderSection loading={true}/>
                <hr className="mb-12 md:mb-24 border-0"/>
                <div className="contain-content pad-content mb-16">
                    <PopularCategoriesSection loading={true}/>
                </div>
            </div>
        )
    }
    const {heroImageSrc, pageTitle, pageSubtitle, featuredCollectionsSection, categoriesSection} =
        verifyOrFallBackContent( pageModelApi.pageModel );

    return (
        <div className="condense--pad-for-site-header">
            <SEO title={pageTitle}/>
            <HeaderSection backgroundImage={heroImageSrc}
                           title={pageTitle}
                           subtitle={pageSubtitle}
                           categoryTaxonomy={categoryTaxonomy}/>
            <hr className="mb-12 md:mb-24 border-0"/>
            <div className="contain-content pad-content mb-16">
                {featuredCollectionsSection && notEmptyOrNil( featuredCollectionsSection.list ) &&
                    <FeaturedCollectionsSection {...featuredCollectionsSection}/>
                }
                {notEmptyOrNil( categoriesSection.list ) &&
                    <PopularCategoriesSection {...categoriesSection} />
                }
                <CategoryLinkList taxonomy={categoryTaxonomy} className="lg:mx-24"/>
            </div>
        </div>
    );
}
