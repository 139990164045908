import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SearchSuggestions} from '@/apps/ResourceCenter/SearchSuggestions';
import {CategorySelect} from '@/containers/FacetedSearch/PageNavigation/CategorySelect';
import {TopLevelNavigationList} from '@/components/common/PageNavigation/TopLevelNavigationList';
import {LogoMyultradent} from '@/components/common/Icons';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {transformObjectKeys} from '@/containers/FacetedSearch/transforms';
import {useMediaQuery} from 'react-responsive';
import MediaQueries from '@ultradent/utilities/MediaQueries';

export const HeaderSection = ( {title, subtitle, backgroundImage, categoryTaxonomy, loading} ) => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState( '' );
    const mediumScreen = useMediaQuery( {query: MediaQueries.min.screenMedium} );

    function getQueryString ( keyword ) {
        let query = `?keyword=${keyword}`;
        if ( notEmptyOrNil( selectedCategory ) ) {
            query += `&AssetCategory=${selectedCategory}`;
        }
        return query;
    }

    return (
        <header className="h-96 flex justify-center items-center relative"
                style={{height: '480px'}}>
            <div className="relative z-10 px-2 md:px-12 w-full max-w-xl">
                <div className="text-white text-center">
                    <LogoMyultradent width="190" height="40" className="mb-6 inline-block"/>
                    {
                        loading
                            ?
                            <>
                                <SkeletonElement className="mx-auto mb-2 h-10 w-1/2"/>
                                <SkeletonElement type="paragraph" className="mx-auto mb-4 w-2/3"/>
                            </>
                            :
                            <>
                                <h3 className="headline-lg leading-none m-0">{title}</h3>
                                <div className="m-0 mb-4" dangerouslySetInnerHTML={{__html: subtitle}}/>
                            </>
                    }
                </div>
                <div>
                    <SearchSuggestions
                        prependSearchSlot={mediumScreen && notEmptyOrNil( categoryTaxonomy ) &&
                            <CategorySelect
                                loading={loading}
                                taxonomy={[
                                    // default category: "All Items"
                                    {
                                        id: '',
                                        name: t( 'search.label.allItems', _.search.label.allItems )
                                    },
                                    ...categoryTaxonomy.map( transformObjectKeys )
                                ]}
                                selectedCategory={selectedCategory}
                                onChange={id => setSelectedCategory( id )}/>
                        }
                        // todo -> when category select changes from this view just apply the category to the url of "onSubmit"
                        //  store the category value in component state
                        onSubmit={( e, keyword ) => {
                            navigate( {
                                pathname: '/search',
                                search: getQueryString( keyword )
                            } )
                        }}

                    />
                </div>
                 <TopLevelNavigationList
                     loading={categoryTaxonomy == null}
                     taxonomy={categoryTaxonomy}
                     linkClassName="hover:text-white"
                     className="pt-4 pb-2 subheading-sm text-white"/>

            </div>
            <img data-sizes="auto"
                 data-src={backgroundImage}
                 className="cover-image cover-image--overlay clip-chevron-hero lazyload fade-up" alt=""/>
        </header>
    );
}
