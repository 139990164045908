import React, {useContext} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Asset} from '@/apps/ResourceCenter/Assets/Asset';
import useBookmarks from '@/containers/Bookmarks/useBookmarks';
import {AssetClipboard, ASSET_CLIPBOARD_KEY} from '@/containers/AssetClipboard';
import {ClipboardContext, useClipboard} from '@/components/common/Clipboard';
import {BookmarksList} from '@/containers/Bookmarks/BookmarksList';

export {useBookmarks};

export const ASSET_BOOKMARKS_KEY = 'resource-assets';
export const BookmarksContext = React.createContext();

export const BookmarksQuery = () => {
    const bookmarksApi = useContext( BookmarksContext );

    return (
        <BookmarksList
            loading={bookmarksApi.loading}
            list={bookmarksApi.bookmarks}
            onRemoveBookmarkItem={bookmarksApi.onRemoveBookmarkItem}
            itemRenderer={Asset}/>
    );
}

export const BookmarkApp = () => {
    const bookmarksApi = useBookmarks( ASSET_BOOKMARKS_KEY );
    const clipboardApi = useClipboard( ASSET_CLIPBOARD_KEY );

    return (

        <BookmarksContext.Provider value={bookmarksApi}>
            <ClipboardContext.Provider value={clipboardApi}>
                <Router>
                    <BookmarksQuery/>
                    <AssetClipboard/>
                </Router>
            </ClipboardContext.Provider>
        </BookmarksContext.Provider>
    );
}

