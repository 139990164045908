exports.translationKeys = {
    siteMeta: {
        description: 'Your single, global gateway to the partner tools and information needed to grow your business with Ultradent Products Inc.'
    },
    notify: {
        networkFailed: 'This request failed due to a network issue. Please check your internet connection and try again.',
        downloadFailed: 'Request to download this content failed.',
        requestFailUnknownError: 'This request could not be completed due to an unknown error.',
        requestTimeoutError: 'This request could not be completed due to a timeout on the server.',
        authorizationFailed: 'This page could not be accessed because your access token has expired. Request a new one to continue.',
        fieldRequired: 'This field is required.',
        invalidEmail: 'Please enter a valid email address.'
    },
    message: {
        genericFormError: 'Please correct the following issues above before continuing',
        genericRequestError: 'Sorry, we were unable to complete this request.',
        oneMoment: 'One moment please',
        loading: 'Loading...',
        downloadComplete: 'Download complete!',
        dismissAndRetry: 'Dismiss to try again.',
        problemLoadingPage: 'Sorry, there was a problem loading this page.',
        retryOrRefresh: 'We encountered a problem while trying to load this information. Please retry, if the problem persists try refreshing the page.',
        contactCustomerService: 'Please contact our customer service for assistance.',
        contactCustomerServiceIfPersists: 'If the problem persists please contact our customer service for assistance.',
        pageLoadErrorWithCustomerService: 'Sorry, there was a problem loading this page. If the problem persists please contact our customer service for assistance.'
    },
    // note [Buttons, Menus, Call to Action]
    actionLabel: {
        add: 'Add',
        back: 'Back',
        done: 'Done',
        download: 'Download',
        downloadAll: 'Download all',
        downloading: 'Downloading...',
        cancel: 'Cancel',
        close: 'Close',
        complete: 'Complete!',
        confirm: 'Confirm',
        copy: 'Copy',
        copyLink: 'Copy link',
        continue: 'Continue',
        invite: 'Invite',
        sending: 'Sending',
        submit: 'Submit',
        submitRequest: 'Submit request',
        submitNewRequest: 'Submit new request',
        loadMore: 'Load more',
        retry: 'Retry',
        share: 'Share',
        showLess: 'Show less'
    },

    label: {
        approved: 'Approved',
        download: 'Download',
        member: 'member',
        member_other: 'members',
        emailAddress: 'Email address',
        emailAddress_other: 'Email addresses',
        emailAddressHint: 'e.g. name@company.com',
        acceptMultipleEmail: 'Enter one or more email addresses, followed by a comma.',
        note: 'Note',
        succeeded: 'Succeeded',
        failed: 'Failed',
        required: 'Required',
        authorizing: 'Authorizing...',
        plusMoreWithCount: '+ {{count}} more'
    },

    menu: {
        download: 'Download',
        downloading: 'Downloading...',
        complete: 'Complete!',
        share: 'Share',
        addSelection: 'Add to selections',
        removeSelection: 'Remove selection',
        addBookmark: 'Add to bookmarks',
        removeBookmark: 'Remove from bookmarks',
        additionalActions: 'Additional actions'
    },

    fileFormatTip: {
        doc: 'DOC (Microsoft Word document)',
        docx: 'DOCX (Microsoft Word document)',
        indd: 'INDD (Adobe InDesign; editable document)',
        generic: 'Document',
        jpeg: 'JPG Image',
        jpg: 'JPG Image',
        pdf: 'PDF document (Portable Document Format)',
        png: 'PNG image (Portable Network Graphics)',
        ppt: 'PPT (Microsoft PowerPoint Presentation)',
        pptx: 'PPTX (Microsoft PowerPoint Presentation)',
        psd: 'PSD editable document (Abobe Photoshop Document)',
        mp4: 'MP4 video (Digital multimedia container)',
        mpeg: 'MPEG video (Digital multimedia container)',
        tif: 'TIFF image (Tagged Image File Format)',
        txt: 'TXT (Text Document)',
        video: 'Video',
        zip: 'ZIP (Compressed Archive)'
    },

    // note [Product Acknowledgement prompt on resource center]
    productAcknowledgement: {
        heading: 'Product Sales Acknowledgement',
        description: 'In order to view the content on this site, please consent to our terms and conditions.',
        body1: 'Note that not all products you see on my.ultradent.com are available for sale in your region.',
        body2: 'If you are unsure if a product from Ultradent Products, Inc., is available in your region, please verify with your service representative before attempting to market or sell.',
        acceptActionLabel: 'I understand',
        termsLabel: 'Read ultradent.com terms and conditions'
    },

    resourceCenter: {
        searchTitle: 'Marketing resources',
        assetDetailTitle: 'Asset Details',
        label: {
            allItems: 'All Items',
            allNew: 'All New'
        }
    },
    researchStudiesPage: {
        pageTitle: 'Claims & Supporting Research',
        message: {
            aboutClaims: 'All claims have been evaluated for U.S. and E.U. regulatory requirements. Regulations vary by country. ' +
                'Contact your regional regulatory representative for any questions regarding use of these claims as well as changes or new claims.'
        },
        actionLabel: {
            browseAll: 'Browse all',
            browseQuestions: 'Browse questions',
            browseArticles: 'Browse articles'
        },
        label: {
            topic: 'Topic'
        }
    },
    productAnswersPage: {
        pageTitle: 'Product answers',
        actionLabel: {
            browseAll: 'Browse all'
        },
        label: {
            topic: 'Topic'
        }
    },

    // note [Resource Center | Asset detail page]
    assetDetail: {
        actionLabel: {
            bookmark: 'Bookmark',
            bookmarked: 'Bookmarked',
            share: 'Share',
            info: 'Info'
        },
        label: {
            relatedTags: 'Related tags',
            relatedItems: 'Related items',
            relatedCategories: 'Related categories',
            skus: 'SKUs',
            ceMark: 'CE Mark',
            targetRegion: 'Target region',
            targetRegion_other: 'Target regions',
            collections: 'Appears in these collections',
            availableFormats: 'Available formats',
            expiration: 'Time/expiration',
            fileType: 'File type',
            fileSize: 'File size',
            dimensions: 'Dimensions',
            resolution: 'Resolution',
            size: 'Size',
            lastUpdated: 'Last updated',
            version: 'Version',
            partNumber: 'Part number'
        }
    },

    publicationDetail: {
        actionLabel: {
            visitArticle: 'Visit article',
            requestPublication: 'Request copy of publication'
        },
        label: {
            published: 'Published',
            citedMaterial: 'Cited in marketing materials',
            mentionsUltradent: 'Mentions Ultradent Products Inc.'
        },
        requestPublication: {
            tip: 'This publication is only available upon request. Click "Request publication" to receive a copy by email',
            label: 'Request publication',
            emailSubject: 'Request for Ultradent publication',
            emailBody: 'Hello, I would like a copy of the publication titled'
        }
    },

    // note [FacetedSearch]
    search: {
        suggestion: {
            pressEnter: 'Press enter',
            inResources: 'in Resource center assets',
            inStudies: 'in Studies publications',
            inProductAnswers: 'Questions in Product Answers'
        },
        message: {
            noResults: {
                heading: 'Sorry, your search for "{{keyword}}" returned no results',
                body1: 'Make sure all words are spelled correctly, try different keywords or removing any filters you have applied.',
                subheading: 'You can also browse these categories'
            }
        },
        actionLabel: {
            allCategories: 'Search all categories instead',
            clear: 'Clear all'
        },
        label: {
            resultWithCount_one: '{{count}} result',
            resultWithCount_other: '{{count}} results',
            relatedCollections: 'Related Collections',
            browseCollections: 'Browse all collections',
            category: 'Category',
            allItems: 'All Items',
            filter: 'Filter',
            filter_other: 'Filters',
            appliedFilters: 'Applied filters',
            sortByRelevance: 'Sort by: Relevance',
            sortByNewest: 'Sort by: Newest',
            sortByPopularity: 'Sort by: Popularity',
            sortByTitle: 'Sort by: Title'
        }

    },
    searchQuery: {
        current: 'Searching for <strong>"{{keyword}}"</strong>',
        currentWithin: 'Searching for <strong>"{{keyword}}"</strong> within <strong>{{category}}</strong>',
        currentWithinAll: 'Searching for <strong>"{{keyword}}"</strong> within <strong>all categories</strong>',
        everything: 'everything'
    },
    searchBar: {
        resourcesPlaceholder: 'Search resources',
        studiesPlaceholder: 'Search claims',
        productAnswersPlaceholder: 'Search product answers'
    },

    /**
     * Components
     * */
    asset: {
        defaultFileName: 'myUltradent resources',
        plusMore: 'more...',
        notAvailable: 'Sorry, this resource is not available',
        label: {
            appearsIn: 'Appears in:',
            variant: 'Variant',
            variant_other: 'Variants'
        }
    },

    account: {
        notify: {
            oneOrMoreInvalidEmailAddresses: 'There are one or more invalid email addresses, please fix them before submitting.',
            noAccounts: 'There are no accounts available in your territory',
            noAccountsNote1: 'If you believe this to be an error please contact our CRM team to resolve.',
            pleaseWait: 'Please wait while we retrieve this list',
            createSuccess: 'A new account was created for "{{name}}"',
            updateDetailsSuccess: 'Account details have been updated',
            invitationFailed: 'This invitation failed. Please try again or contact customer service for assistance',
            validationFailed: 'The primary email "{{email}}" for this account is not valid. Please correct in CRM before continuing'
        },
        actionLabel: {
            createNewAccount: 'Create a new account',
            createAccount: 'Create account'
        },
        label: {
            accountName: 'Account name',
            createNewAccount: 'Create a new account',
            editAccount: 'Edit account',
            filterAccountList: 'Partner account filter (Name, email, location)',
            filterAccountListPlaceholder: 'Company name, location, phone'
        },
        message: {
            inviteHint: 'when you click the "invite" button, an email with a registration link will be sent to the partner. You may want to alert them that the email is coming so that they don\'t think it is spam or a phishing attack.',
            whyCantISeeAllPartners: {
                heading: 'Why can\'t I see all of my partners?',
                tipBody1: 'If you would like to invite a partner, but they are not listed on this page, it\'s likely their data has not been input in our system yet.',
                tipBody2: 'Please <a href="{{CRMLoginUrl}}">log into Dynamics</a> and create the partner account there. After the account is created in Dynamics, it may take up to 24 hours before the partner account is visible in myUltradent.'
            }
        }
    },
    accountLine: {
        noEmail: 'No email provided',
        actionLabel: {
            inviteMembers: 'Invite members',
            manageAccount: 'Manage account'
        },
        label: {
            invitePending: 'Invite pending',
            invitePendingTip: 'Waiting for user(s) to accept invitation',
            active: 'Active',
            activeTip: '{{count}} active member',
            activeTip_other: '{{count}} active members'
        }
    },
    userManagement: {
        notify: {
            atLeastOnePermissionRequired: 'At lease one permission must be granted to continue',
            noFeaturesAvailable: 'There are no site features available for this user.',
            userUpdateSuccess: 'User settings updated successfully',
            changeSubmissionFailed: 'There was an issue submitting changes'
        },
        actionLabel: {
            updateRole: 'Update role',
            updatePermissions: 'Update permissions',
            updateRegions: 'Update regions'
        },
        label: {
            role: 'Role',
            sitePermissions: 'Site permissions',
            preferredRegion: 'Preferred Region',
            roleFormTitle: 'Change user role',
            permissionsFormTitle: 'Change user permissions',
            regionsFormTitle: 'Change preferred region',
            inviteFormTitle: 'Invite members',
            inviteEmailCaption: 'Please only invite people you trust and are internal members of your organization.',
            whichRegions: 'Which region(s) of the site should the member have access to?',
            whichFeatures: 'Which features of the site should these members have access to?',
            whichFeaturesHint: '(Select at least one)',
            admin: 'Admin',
            adminHint: 'Full administrative access to organization and site permissions granted for this account',
            member: 'Member',
            memberHint: 'Can see team members and access site features for which they have been assigned permission'
        }
    },
    downloadAssets: {
        notify: {
            metadataNotAvailable: 'Sorry, there is not metadata available for this asset',
            downloadFailed: 'There was an issue downloading these resources'
        },
        label: {
            downloadItem: 'Download {{count}} item',
            downloadItem_other: 'Download {{count}} items',
            original: 'Original',
            webOptimized: 'Web Optimized',
            imageSize: 'Image Size',
            imageOptimizedFormats: '(applies to all jpeg, jpg, and png files)',
            fileName: 'File name (optional)',
            fileNameHint: 'Provide a filename for the .zip file you are downloading e.g. "Opalescence product images"',
            fileNamePlaceholder: 'File name'
        }
    },

    equipmentRepair: {
        label: {
            accountDetail: 'Account detail',
            address: 'Address',
            contact: 'Contact',
            contactInfo: 'Contact information',
            equipmentInfo: 'Equipment information',
            addAttachments: 'Attach photos (optional)',
            accountName: 'Company name',
            accountNumber: 'Account number',
            fullName: 'Contact name',
            email: 'Email address',
            serialNumber: 'Serial number',
            description: 'Describe the issue you are having with your equipment',
            additionalInformation: 'Additional information'
        },
        message: {
            successHeadline: 'Thank you!',
            successSubheading: 'Your request has been submitted',
            accountName: 'Enter an company name',
            accountNumber: 'Enter an account number',
            fullName: 'Enter the contact name for contact',
            email: 'Enter a valid email address for contact',
            address: 'Enter an address',
            serialNumber: 'Enter the serial number for equipment',
            description: 'Enter a short description of the issue'
        }
    },

    assetSharing: {
        message: {
            resourcesHaveExpired: {
                heading: 'Sorry, these resources have expired',
                body1: 'Please contact the original sender to request a new share link.'
            }
        },
        label: {
            shareFormTitle: 'Sharing {{count}} asset',
            shareFormTitle_other: 'Sharing {{count}} assets',
            asset: 'asset',
            asset_other: 'assets',
            shareLink: 'Share link'
        },
        notify: {
            shareSuccess: 'Assets have been shared!',
            copyLinkSuccess: 'This link has been copied to your clipboard!',
            oneOrMoreInvalidEmailAddresses: 'There are one or more invalid email addresses, please fix them before submitting.'
        },
        heading: {
            resourceSharing: 'Resource sharing',
            resourceSharingSubtitle: 'Sales, marketing, and branding assets for Ultradent Products, Inc. '
        },
        list: {
            heading: '{{count}} asset have been shared with you',
            heading_other: '{{count}} assets have been shared with you',
            subheading: 'This content will only be available to download for a short period of time.'
        }
    },
    bookmarks: {
        notify: {
            createBookmarkFailed: 'Sorry, we ran into an issue creating this bookmark. Please try again later',
            removeBookmarkFailed: 'Sorry, we ran into an issue removing this bookmark. Please try again later'
        },
        message: {
            removeItem: 'Remove item from your bookmarks',
            emptyList: {
                heading: 'You haven\'t created any bookmarks yet',
                body1: 'Add your favorite resources to your bookmarks. Simply click the <componentIcon> on any resource item to get started.'
            }
        },
        actionLabel: {
            browseResources: 'Browse resource center'
        },
        label: {
            pleaseRemoveItem: 'Please remove this item from your list'
        }
    },
    clipboard: {
        actionLabel: {
            removeItem: 'Remove item from your selections',
            clearAll: 'Clear selections',
            downloadAll: 'Download all',
            bookmarkAll: 'Bookmark all',
            shareAll: 'Share all',
            viewSelections: 'View all selections',
            hideSelections: 'Hide selections'
        },
        label: {
            selections: 'selections',
            itemSelectedWithCount: '{{count}} item selected',
            itemSelectedWithCount_other: '{{count}} items selected'
        }
    },
    lists: {
        label: {
            empty: 'No Items in this list'
        }
    },
    filterList: {
        label: {
            filterFromWithCount: 'Filter from {{count}} items in this list'
        }
    },
    pickList: {
        actionLabel: {
            cancel: 'Cancel change',
            change: 'Change',
            select: 'Select {{itemType}}',
            addAnother: '＋ Add another'
        }
    },
    fileDrop: {
        actionLabel: {
            dropHere: 'Drop files here ...',
            dragAndDropFiles: 'Drag and drop files here, or browse'
        }
    },
    pageNotFound: {
        pageTitle: 'Page not found',
        heading: 'Sorry',
        body1: 'We can\'t find the page you are looking for.',
        body2: 'The expected resource is either missing or does not exist.',
        subheading: 'Here are some helpful links instead',
        navItems: {
            home: 'Home',
            resources: 'Resource center',
            studies: 'Studies',
            salesDashboard: 'Sales dashboard',
            accountSettings: 'Account settings',
            yourTeam: 'Your team',
            privacy: 'Privacy management'
        }
    }
}
