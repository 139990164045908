import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {basePath: '/crm'} );

svc.getPartnerAccounts = function getPartnerAccounts ( irmFullName = 'Global Admin' ) {
    return svc.xhr.get( `partneraccounts/${encodeURIComponent( irmFullName )}` );
};

export const CRMProvider = svc.xhr;

export default svc;
