import {transformAssetModel} from '@/containers/FacetedSearch/transforms';
import BookmarksService from '@/containers/Bookmarks/service';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import uniqBy from 'ramda/src/uniqBy';
import prop from 'ramda/src/prop';

class BookmarkRepository {

    constructor ( store ) {
        this.bookmarkStore = store;
    }

    subscribe ( fn ) {
        return this.bookmarkStore.subscribe( fn );
    }

    getStoreKey () {
        return this.bookmarkStore.storeKey;
    }

    async getBookmarks ( key ) {
        try {
            const response = await BookmarksService.getAll();
            let bookmarks = [];

            if ( Array.isArray( response.data ) ) {
                bookmarks = response.data.map( transformAssetModel );
            }

            this.bookmarkStore.setStore( key, bookmarks );

            return bookmarks;
        }
        catch ( err ) {
            // attempt to retrieve from local store
            // if nothing there, return empty
            const bookmarks = this.bookmarkStore.getStore( key );
            return bookmarks || [];
        }
    }

    async bookmarkAll ( {key, bookmarks, items} ) {
        if ( isEmptyOrNil( items ) ) {
            const message = `[Could not bookmark] "items" is empty or null`;
            console.warn( message );
            return Promise.reject( message );
        }

        const nextState = [...bookmarks].concat(
            items.map( item => ({...item, valid: true}) )
        );

        // local store - ensure only unique items are going in
        this.bookmarkStore.setStore( key, uniqBy( prop( 'id' ), nextState ) );

        // http
        try {
            return await BookmarksService.add(
                items.map( ( {id, name} ) => ({id, name}) )
            );
        }
        catch ( err ) {
            // reset store to previous state
            console.error( '[Create Bookmark failed]', err );
            this.bookmarkStore.setStore( key, bookmarks );
            return Promise.reject( err.message );
        }

    }

    async addBookmark ( {key, bookmarks, item} ) {
        const foundItem = bookmarks.find( i => i.id === item.id );
        if ( foundItem ) {
            const message = `Item with id "${item.id}" is already in bookmarks`;
            console.warn( message );
            return Promise.reject( message );
        }
        const nextState = [...bookmarks, {...item, valid: true}];

        // local store - ensure only unique items are going in
        this.bookmarkStore.setStore( key, uniqBy( prop( 'id' ), nextState ) );

        // http
        try {
            return await BookmarksService.add( [{
                id: item.id,
                name: item.name
            }] );
        }
        catch ( err ) {
            // reset store to previous state
            console.error( '[Create Bookmark failed]', err );
            this.bookmarkStore.setStore( key, bookmarks );
            return Promise.reject( err.message );
        }

    }

    async removeBookmark ( {key, bookmarks, item} ) {
        const foundIndex = bookmarks.findIndex( e => e.id === item.id );
        if ( foundIndex !== -1 ) {
            // only update store if item was found
            const nextState = [
                ...bookmarks.slice( 0, foundIndex ),
                ...bookmarks.slice( foundIndex + 1 )
            ];
            // local store
            this.bookmarkStore.setStore( key, nextState );
        }

        // http
        try {
            return await BookmarksService.remove( [item.id] );
        }
        catch ( err ) {
            // reset store to previous state
            console.error( '[Delete Bookmark failed]', err );
            this.bookmarkStore.setStore( key, bookmarks );
            throw err;
        }
    }

    setBookmarks ( key, bookmarkData ) {
        this.bookmarkStore.setStore( key, bookmarkData );
    }

    clearBookmarks ( key ) {
        this.bookmarkStore.clearStoreItem( key );
    }
}

export default BookmarkRepository;
