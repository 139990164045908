import {FormFeedback, Show, Tooltip} from '@ultradent/components';
import {Pencil, Cross} from '@/components/common/Icons';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import React from 'react';

const RegistrantCard = ( {id, fullName, email, phoneNumber, companyName, disabled, postError, onRemove, onEdit} ) =>
    <li data-testid={`registrant-${email}`}
        className="p-6 border border-grey-lighter relative">
        <button type="button"
                data-testid={`remove-registrant-${id}`}
                disabled={disabled}
                className="absolute top-4 right-4"
                onClick={() => onRemove( id )}>
            <Tooltip content="Remove user from registration list">
                <Cross width="20" height="20"/>
            </Tooltip>
        </button>

        <p>{fullName}</p>
        <p>{email}</p>
        <p>{phoneNumber}</p>
        <p>{companyName}</p>

        <Show when={notEmptyOrNil( postError )}>
            <FormFeedback
                className="bg-fail text-white my-4"
                {...postError}/>
        </Show>

        <div className="mt-2">
            <button type="button"
                    data-testid={`edit-registrant-${id}`}
                    disabled={disabled}
                    onClick={() => onEdit( id )}
                    className="inline-flex items-center text-blue">
                <Pencil width="20" className="inline-block mr-2"/> edit
            </button>
        </div>
    </li>;

export default RegistrantCard;
