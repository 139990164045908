import {Checkmark} from '@/components/common/Icons';

const RegistrationSubmitted = ( {eventId} ) =>
    <div className="bg-near-white p-16 text-center">
        <Checkmark className="inline-block w-16 h-16 mb-8 text-brand"/>
        <h1 className="headline-sm mb-1">Thank You</h1>
        <p className="mb-8">We received your request and will contact you with any further details</p>
        <a className="button primary" href={`/events/${eventId}`}>Return Home</a>
    </div>

export {RegistrationSubmitted}
