import {Notyf} from 'notyf';
import 'notyf/notyf.min.css';
import namespace from  '@ultradent/utilities/Namespace';

// resource docs - https://www.npmjs.com/package/notyf
/**
 * api
 * dismiss, dismissAll
 * called on return object of notyf instance call
 *
 * Events
 * click, dismiss
 * e.g. notyf.on('click', () => {})
 */

const DEFAULT_OPTIONS = {
    duration: 10000,
    position: {x: 'center', y: 'top'},
    dismissible: true
};

const notify = new Notyf( DEFAULT_OPTIONS );

const registerType = ( typeConfig = {} ) => ( options ) => {
    let config = typeConfig;

    if ( typeof options === 'string' ) {
        config.message = options;
    }
    if ( options.toString() === '[object Object]' ) {
        config = {
            ...config,
            ...options
        };
    }

    notify.open( config );
}

notify.info = registerType( {
    type: 'info',
    className: 'gradient-upi-sheen'
} );

notify.success = registerType( {
    type: 'info',
    className: 'gradient-green-sheen'
} );

const createInstance = options => {
    return new Notyf( ...DEFAULT_OPTIONS, ...options );
};

/**
 * Expose to global namespace
 * can be executed from server by calling
 * example: upi.Notifier.notify.success('Your profile has been updated')
 */
Object.assign( namespace( 'Notifier' ), {notify} );

export {
    notify,
    createInstance
}
