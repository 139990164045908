import {Link} from 'react-router-dom';
import cx from 'classnames';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const TopLevelNavigationList = ( {taxonomy, className, linkClassName, loading} ) => {
    if ( loading ) {
        return <TopLevelNavigationList.Skeleton className={className}/>
    }

    if ( isEmptyOrNil( taxonomy ) ) {
        return null;
    }

    return (
        <nav data-testid="TopLevelNavigationList"
             className={cx( 'flex flex-wrap', className )}>
            <Link className={cx( 'mb-1 mr-4 whitespace-nowrap hover:underline', linkClassName )}
                  to={`/search?keyword=`}>
                    {t( 'resourceCenter.label.allItems', _.resourceCenter.label.allItems )}
            </Link>
            <Link className={cx( 'mb-1 mr-4 whitespace-nowrap hover:underline', linkClassName )}
                  to={`/search?keyword=&IsNew=Last%2090%20Days&orderBy=DateApproved%20desc`}>
                    {t( 'resourceCenter.label.allNew', _.resourceCenter.label.allNew )}
            </Link>
            <div className="mr-4">{'|'}</div>
            {taxonomy.map( node =>
                <Link key={node.Name}
                      className={cx( 'mb-1 mr-4 whitespace-nowrap hover:underline', linkClassName )}
                      to={`/search?keyword=&AssetCategory=${encodeURIComponent( node.Name )}`}>
                    {node.Name}
                </Link>
            )}
        </nav>
    );
}

TopLevelNavigationList.Skeleton = ( {className} ) =>
    <div data-testid="TopLevelNavigationList.Skeleton" className={cx( 'flex items-center', className )}>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
        <SkeletonElement type="text" className="w-24 mt-1 mr-8"/>
    </div>
