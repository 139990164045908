import React from 'react';
import {AppRouter} from './router';
import {StudiesAppContext} from '@/apps/Studies/appContext';
import {HashRouter as Router} from 'react-router-dom';
import usePageModel from '@/containers/PageModel/usePageModel'

function App () {

    const pageModelApi = usePageModel( 'studylandingpage' );

    return (
        <StudiesAppContext.Provider value={{pageModelApi}}>
            <Router basename={'/'}>
                <AppRouter/>
            </Router>
        </StudiesAppContext.Provider>
    );
}

export default App;
