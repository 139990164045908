import {useLocation, useNavigate} from 'react-router-dom';
import {useAssetSharing} from '@/containers/AssetSharing/useAssetSharing';
import React, {useContext} from 'react';
import {BookmarksContext} from '@/containers/Bookmarks';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {getFormattedISODate} from '@ultradent/utilities/Date';
import cx from 'classnames';
import SEO from '@/components/common/SEO';
import {TopLevelNavigationList} from '@/components/common/PageNavigation/TopLevelNavigationList';
import {SearchSuggestions} from '@/apps/ResourceCenter/SearchSuggestions';
import {LoadError} from '@/components/dialogs/PageLoadError';
import {Button, Show, SkeletonElement, t, ThumbnailList} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {
    ActionBar,
    AssetFileSizeMeta,
    DetailPane,
    PreviewPane,
    Taglist
} from '@/apps/ResourceCenter/Pages/AssetDetailPage/Components';
import {AssetDownloadButtonRenderer} from '@/components/common/AssetDownload/AssetDownloadRenderer';
import {LocationBar} from '@/containers/FacetedSearch/PageNavigation/LocationBar';
import {AssetDownloadMenu} from '@/components/common/AssetDownload';
import useAssetDownload from '@/components/common/AssetDownload/useAssetDownload';
import {AssetThumbnail} from '@/apps/ResourceCenter/Assets/AssetThumbnail';
import {Asset} from '@/apps/ResourceCenter/Assets/Asset';
import {BookmarkAdd, BookmarkFilled, CalendarTime, Share} from '@/components/common/Icons';
import {Album} from '@/apps/ResourceCenter/Assets/Album';
import {ResourceAppContext, verifyOrFallBackContent} from '@/apps/ResourceCenter/appContext';
import {transformAssetSizes} from '@/containers/FacetedSearch/transforms';
import {AssetData} from '@/constants/appConstants';

export const AssetDetail = ( {
                                 id,
                                 stream,
                                 scheme,
                                 name,
                                 description,
                                 tag,
                                 taxonomies,
                                 dateApproved,
                                 lastUploaded,
                                 Sizes,
                                 size,
                                 width,
                                 height,
                                 dpi,
                                 mimeType,
                                 fileExtension,
                                 relatedAlbums,
                                 additional,
                                 categoryTaxonomy,
                                 loading,
                                 error,
                                 onRetry
                             } ) => {
    const {pageModelApi} = useContext( ResourceAppContext );
    const pageModel = verifyOrFallBackContent( pageModelApi.pageModel );
    const assetDownloadApi = useAssetDownload( {
        assets: [{
            id,
            additional,
            scheme,
            name: additional[AssetData.Title],
            sizes: Sizes?.map( transformAssetSizes ) || [],
            mimetype: mimeType,
            fileExtension
        }]
    } );
    const location = useLocation();
    const navigate = useNavigate();
    const {onShareAssets} = useAssetSharing();
    const {isBookmarked, onAddBookmarkItem, onRemoveBookmarkItem} = useContext( BookmarksContext );
    const isBackgroundLocation = location.state?.backgroundLocation;
    const relatedItems = notEmptyOrNil( additional[AssetData.RelatedDetail] )
        ? additional['Related Asset Details'].map( asset => ({
            ...asset,
            name: asset.additional[AssetData.Title],
            formats: [asset.fileExtension]
        }) )
        : [];
    const dateApprovedFormatted = getFormattedISODate( dateApproved, 'yyyy, MMMM d' );
    const assetData = {id, name: additional[AssetData.Title]};
    const skus = additional[AssetData.AssociatedSkus]?.split( ',' ).join( ', ' );
    const targetRegions = additional[AssetData.Regions];

    const onQuery = ( query ) => {
        navigate( {
            pathname: '/search',
            search: `?keyword=${query}`
        } );
    };

    return (
        <div className={cx( 'contain-content mb-16 ', {
            'md:-mt-8 md:pad-content': isBackgroundLocation,
            'pad-content': !isBackgroundLocation
        } )}>
            <SEO title={notEmptyOrNil( additional )
                ? additional[AssetData.Title]
                : t( 'resourceCenter.assetDetailTitle', _.resourceCenter.assetDetailTitle )}/>
            {!isBackgroundLocation &&
                <>
                <div className="w-full mb-2">
                    <div className="md:flex items-center">
                        {pageModelApi.loading
                            ?
                            <SkeletonElement className="mr-8 mb-6 mt-2" type="title"/>
                            :
                            <h3 className="subheading-lg mr-8 mb-4 whitespace-nowrap">{pageModel.pageTitle}</h3>
                        }
                        <TopLevelNavigationList loading={loading} taxonomy={categoryTaxonomy}
                                                className="mb-2 subheading-sm"/>
                    </div>
                    <SearchSuggestions onSubmit={( e, query ) => onQuery( query.trim() )}/>
                </div>
                </>
            }

            <div>
                {error && <LoadError onRetry={onRetry}/>}
                {loading
                    ? <AssetDetail.Skeleton/>
                    : <>
                        <LocationBar pageName={additional[AssetData.Title]} linkBack={true} loading={loading}/>
                        <PreviewPane>
                            <AssetThumbnail id={id} name={name} size="1200" scheme={scheme} stream={stream}
                                            imgClassName="object-contain border-2 border-solid border-grey-lightest"/>
                        </PreviewPane>
                        <DetailPane className="flex-1 fade-in mb-20">
                            <Show when={notEmptyOrNil( dateApprovedFormatted )}>
                                <div className="text-title mb-2 self-end flex-auto flex items-center">
                                    <CalendarTime width="24" height="24" className="mr-2"/>
                                    <span>
                                        {t( 'label.approved', _.label.approved )} {dateApprovedFormatted}
                                    </span>
                                </div>
                            </Show>
                            <ActionBar className="flex-col-reverse md:flex-row">
                                    <div className="flex flex-none w-full md:w-auto">
                                        <Button
                                            textLink
                                            data-testid="AssetDetail.ShareLink"
                                            onClick={onShareAssets( assetData )}
                                            icon={Share}
                                            iconClassName="text-blue w-5"
                                            className="flex items-center mr-4 tertiary hover:link-2">
                                            {t( 'assetDetail.actionLabel.share', _.assetDetail.actionLabel.share )}
                                        </Button>

                                        {isBookmarked( assetData )
                                            ?
                                            <Button
                                                textLink
                                                onClick={onRemoveBookmarkItem( assetData )}
                                                icon={BookmarkFilled}
                                                iconClassName="text-blue w-5"
                                                className="flex items-center mr-4 tertiary hover:link-2">
                                                {t( 'assetDetail.actionLabel.bookmarked',
                                                    _.assetDetail.actionLabel.bookmarked )}
                                            </Button>
                                            :
                                            <Button
                                                textLink
                                                onClick={onAddBookmarkItem( assetData )}
                                                icon={BookmarkAdd}
                                                iconClassName="text-blue w-5"
                                                className="flex items-center mr-4 tertiary hover:link-2">
                                                {t( 'assetDetail.actionLabel.bookmark',
                                                    _.assetDetail.actionLabel.bookmark )}
                                            </Button>
                                        }
                                    </div>

                                <div className="flex flex-none w-full md:w-auto my-4 md:my-0">
                                        <AssetDownloadMenu
                                            assetDownloadApi={assetDownloadApi}
                                            fileExtension={fileExtension}
                                            className="relative button sm tertiary w-48 flex items-center">
                                            {AssetDownloadButtonRenderer}
                                        </AssetDownloadMenu>
                                    </div>
                            </ActionBar>

                            <AssetFileSizeMeta fileExtension={fileExtension} sizes={Sizes}/>
                            <Show when={notEmptyOrNil( description )}>
                                <p className="text-body mb-2">{description}</p>
                            </Show>
                        </DetailPane>
                        <div className="flex">
                                 <Show when={notEmptyOrNil( skus )}>
                                     <div className="mb-4 mr-12">
                                        <h3 className="subheading-base mb-2">
                                            {t( 'assetDetail.label.skus', _.assetDetail.label.skus )}
                                        </h3>
                                        <div className="mb-2">{skus}</div>
                                    </div>
                                 </Show>
                                 <Show when={notEmptyOrNil( additional[AssetData.CEMark] )}>
                                     <div className="mb-4 mr-12">
                                        <h3 className="subheading-base mb-2">
                                            {t( 'assetDetail.label.ceMark', _.assetDetail.label.ceMark )}
                                        </h3>
                                        <div className="mb-2">{additional[AssetData.CEMark]}</div>
                                    </div>
                                 </Show>
                            </div>
                        <Show when={notEmptyOrNil( relatedItems )}>
                                <div className="mb-4" id="related-items">
                                    <h3 className="subheading-base mb-2">
                                        {t( 'assetDetail.label.relatedItems', _.assetDetail.label.relatedItems )}
                                    </h3>
                                    <ThumbnailList list={relatedItems.map( item => ({
                                        ...item,
                                        assetDownloderType: 'menu',
                                        sizes: item.Sizes?.map( transformAssetSizes ) || []
                                    }) )} assetComponent={Asset} thumbnailSize="xl"/>
                                </div>
                        </Show>
                        <Show when={notEmptyOrNil( relatedAlbums )}>
                                <div className="mb-4">
                                    <h3 className="subheading-base mb-2">
                                        {t( 'assetDetail.label.collections', _.assetDetail.label.collections )}
                                    </h3>
                                    <div className="mb-28 flex flex-wrap justify-content-between gap-12">
                                            {relatedAlbums?.map( itemProps => <Album key={itemProps.id} {...itemProps}
                                                                                     className="w-1/6"/> )}
                                    </div>
                                </div>
                        </Show>
                        <Show when={notEmptyOrNil( tag )}>
                                <div className="mb-4">
                                    <h3 className="subheading-base mb-2">
                                        {t( 'assetDetail.label.relatedTags', _.assetDetail.label.relatedTags )}
                                    </h3>
                                    <Taglist tags={tag}/>
                                </div>
                        </Show>
                        <Show when={notEmptyOrNil( taxonomies )}>
                                <div className="mb-4">
                                    <h3 className="subheading-base mb-2">
                                        {t( 'assetDetail.label.relatedCategories',
                                            _.assetDetail.label.relatedCategories )}
                                    </h3>
                                    <div className="mb-2">
                                        {taxonomies?.map( ( l, index ) => <div key={index}>{l.join( ' → ' )}</div> )}
                                    </div>
                                </div>
                        </Show>
                        <Show when={notEmptyOrNil( targetRegions )}>
                                <div className="mb-4">
                                    <h3 className="subheading-base mb-2">
                                        {t(
                                            'assetDetail.label.targetRegion',
                                            _.assetDetail.label.targetRegion,
                                            {count: targetRegions?.length}
                                        )}
                                    </h3>
                                    <div className="mb-2">{targetRegions?.join( ', ' )}</div>
                                </div>
                        </Show>
                    </>
                }
            </div>

    </div>
    )
}

AssetDetail.defaultProps =
    {
        additional: {}
    };

AssetDetail.Skeleton = () =>
    <div data-testid="AssetDetail.Skeleton">
        <PreviewPane>
            <div className="mb-8 w-full flex justify-center items-center">
                <SkeletonElement type="thumbnail-full" className="max-w-lg"/>
            </div>
        </PreviewPane>
        <DetailPane>
            <ActionBar>
                 <div className="flex flex-col md:flex-row items-center mr-3">
                    <SkeletonElement type="icon"/>
                    <SkeletonElement className="w-24"/>
                 </div>
                 <div className="flex flex-col md:flex-row items-center mr-3">
                    <SkeletonElement type="icon"/>
                    <SkeletonElement className="w-24"/>
                 </div>
            </ActionBar>
            <hr className="border-0 mb-3 md:mb-6"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <hr className="border-0 mb-6 md:mb-12"/>
            <SkeletonElement type="title"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <hr className="border-0 mb-6 md:mb-12"/>
            <SkeletonElement type="title"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <hr className="border-0 mb-6 md:mb-12"/>
            <SkeletonElement type="title"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
        </DetailPane>
    </div>;
