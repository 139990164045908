import env from '@env';
import AuthService from '@/providers/auth-provider';
import axios from 'axios';
import {NotAuthorized, TokenRequestError} from '@/util/Errors';

const onResponseRejected = ( axiosInstance, onReject ) => async ( error ) => {
    if ( error.config?.url ) {
        let reqPath = error.config?.url;

        if ( !reqPath.startsWith( 'http' ) ) {
            reqPath = `${error.config?.baseURL?.replace( env.API_ORIGIN, '' )}/` + reqPath;
        }

        console.error(
            `Error: Request to "${reqPath || 'Unknown'}" failed with status code ${error.response?.status}`
        );
    }

    if ( error instanceof axios.Cancel ) {
        console.warn( error.message );
        return Promise.reject( error );
    }

    if ( ![401, 419].includes( error.response?.status ) ) {
        return Promise.reject( error );
    }

    // check for "retry" requests
    if ( error.response?.config?._retry ) {
        return axiosInstance.request( error.config );
    }

    return onReject && onReject( error );
}

export function setApiKey ( axiosInstance ) {
    axiosInstance.interceptors.request.use(
        config => {
            config.headers['Ocp-Apim-Subscription-Key'] = env.API_KEY;
            return config;
        },
        error => Promise.reject( error )
    )
}

/*
* This flow looks for an auth token
* if token not present request refresh token, if refresh fails redirect to auth page.
* if token preset continue request as normal; if request fails with 401, request refresh token and retry request
*   if request fails again with 401 redirect to login */
export function setAuthToken ( axiosInstance ) {
    axiosInstance.interceptors.request.use(
        async ( config ) => {
            const authHeader = await AuthService.getAuthHeader();
            config.headers['Authorization'] = authHeader;
            return config;
        },
        ( error ) => Promise.reject( error )
    )

    axiosInstance.interceptors.response.use(
        ( res ) => res,
        onResponseRejected( axiosInstance, async ( error ) => {
            try {
                console.warn( '[HttpProvider Intercept] - Attempting authorization' );
                await AuthService.acquireTokenFromRefresh();

                // retry original request
                return axiosInstance.request( error.config );
            }
            catch ( err ) {
                console.error( err );

                if ( err instanceof TokenRequestError ) {
                    console.warn( '[HttpProvider Intercept] - TokenRequestError reauthorization required' );
                    AuthService.redirectTo( 'login' );
                }

                if ( err instanceof NotAuthorized ) {
                    console.warn( `[HttpProvider Intercept] - ${err.message}` );
                    AuthService.redirectTo( 'notauthorized' );
                }

                return Promise.reject( error );
            }
        } )
    );
}

/*
* This flow looks for anonymous token to be set
* if token not present a redirect to not authed occurs
* if token present continue request as normal; if this request fails with 401 a redirect to not authed occurs
* */
export function setAnonymousToken ( axiosInstance ) {
    axiosInstance.interceptors.request.use(
        async ( config ) => {
            config.headers['Authorization'] = AuthService.getAnonymousAuthHeader();
            return config;
        },
        ( error ) => Promise.reject( error )
    )

    axiosInstance.interceptors.response.use(
        ( res ) => res,
        onResponseRejected( axiosInstance, ( axiosError ) => {
            console.warn( `[HttpProvider Intercept] - ${axiosError.message}` );
            AuthService.redirectTo( 'notauthorized' );
        } )
    );
}

/*
* This flow looks for anonymous token to be set
* if token not present a redirect to not authed occurs
* if token present continue request as normal; if this request fails with 401 a redirect to not authed occurs
* */
export function logResponse ( axiosInstance ) {
    axiosInstance.interceptors.response.use(
        ( res ) => {
            const {config: {method, url}, status} = res;
            console.groupCollapsed( `[HttpProvider AxiosResponse] "[${method} ${status}] ${url}"` );
            if ( res.config?.data ) {
                console.log( 'Payload', JSON.parse( res.config.data ) );
            }
            console.log( 'Data', res.data );
            console.groupCollapsed( 'Config' );
            console.log( res.config );
            console.groupEnd();
            console.groupEnd();
            return res;
        }
    );
}
