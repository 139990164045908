import {useEffect, useReducer} from 'react';
import {useRecoilState, useRecoilValueLoadable} from 'recoil';
import {eventDetailsQuery, eventIdState} from './selectors';
import {eventDetailsReducer} from './reducers/eventDetails';

export function useEventDetails ( {eventId} ) {
    const [, setEventId] = useRecoilState( eventIdState );
    const eventDetailsRequest = useRecoilValueLoadable( eventDetailsQuery );

    const [state, dispatch] = useReducer( eventDetailsReducer, {
        eventId: null,
        AttendeeLimit: null,
        loadError: null,
        isLoading: eventDetailsRequest.state !== 'hasValue'
    } );

    useEffect(
        () => setEventId( eventId ),
        [eventId] );

    useEffect(
        () => dispatch( {type: 'setRequestState', payload: {request: eventDetailsRequest}} ),
        [eventDetailsRequest.state] );

    return {
        ...state
    }
}
