import {ShareAsset} from '@/components/dialogs/ShareAsset';
import {useAssetSharing} from '@/containers/AssetSharing/useAssetSharing';
import {notify} from '@/modules/notifier';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const Submission = () => {
    const {isShareAssetsDialogOpen, sharingAssets, closeAssetSharing} = useAssetSharing();
    const assets = sharingAssets.hasItems ? sharingAssets.list : [];

    return (
        <ShareAsset
            isOpen={isShareAssetsDialogOpen}
            assets={assets}
            onClose={closeAssetSharing}
            onShareSuccess={() => {
                closeAssetSharing();
                notify.success( t( 'assetSharing.notify.shareSuccess', _.assetSharing.notify.shareSuccess ) );
            }}
            onCopyLinkSuccess={() => {
                closeAssetSharing();
                notify.success( t( 'assetSharing.notify.copyLinkSuccess', _.assetSharing.notify.copyLinkSuccess ) );
            }}
        />
    );
}
