import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import PropTypes from 'prop-types';

const CurrentQuery = ( {
                           isLoading,
                           keyword = '',
                           categoryFilter,
                           onClearFilter
                       } ) => {
    if ( isLoading ) {
        return <CurrentQuery.Skeleton/>;
    }

    const categoryDisplay = Array.isArray( categoryFilter ) && categoryFilter.join( ' → ' );
    const keywordDisplay = keyword || t( 'searchQuery.everything', _.searchQuery.everything );
    return (
        <div className="mb-2 text-lg">
            {!!categoryDisplay
                ?
                <>
                    <span
                        data-testid="current-query"
                        dangerouslySetInnerHTML={{
                            __html: t(
                                'searchQuery.currentWithin',
                                _.searchQuery.currentWithin,
                                {
                                    keyword: keywordDisplay,
                                    category: categoryDisplay,
                                    interpolation: {escapeValue: false}
                                }
                            )
                        }}></span>
                    <button data-testid="clear-categories"
                            className="link-2 block md:inline-block md:ml-4"
                            onClick={onClearFilter}>
                        {t( 'search.actionLabel.allCategories', _.search.actionLabel.allCategories )}
                    </button>
                </>
                :
                <span
                    data-testid="current-query"
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'searchQuery.currentWithinAll',
                            _.searchQuery.currentWithinAll,
                            {
                                keyword: keywordDisplay,
                                interpolation: {escapeValue: false}
                            }
                        )
                    }}></span>

            }
        </div>
    );
}

CurrentQuery.Skeleton = () => (
    <div className="mb-2">
        <SkeletonElement type="title"/>
    </div>
);

CurrentQuery.propTypes = {
    isLoading: PropTypes.bool,
    keyword: PropTypes.string,
    categoryFilter: PropTypes.array,
    onClearFilter: PropTypes.func
};

export
{
    CurrentQuery
}
