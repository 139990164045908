import React from 'react';
import {Clipboard} from '@/components/common/Clipboard';
import {AssetClipboardItem} from '@/containers/AssetClipboard/AssetClipboardItem';

export const ASSET_CLIPBOARD_KEY = 'resource-assets';

export const AssetClipboard = ( {initialOpen} ) => {
    return (
        <Clipboard storyKey={ASSET_CLIPBOARD_KEY} initialOpen={initialOpen} itemRenderer={AssetClipboardItem}/>
    )
}
