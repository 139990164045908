import {useContext} from 'react';
import cx from 'classnames';
import flatten from 'ramda/src/flatten';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {getFormattedISODate} from '@ultradent/utilities/Date';
import {ModalLink} from '@/components/common/Routing/ModalLink';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';
import {Show, SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {AssetDownloadDialog, AssetDownloadMenu} from '@/components/common/AssetDownload';
import useAssetDownload from '@/components/common/AssetDownload/useAssetDownload';
import {ClipboardContext} from '@/components/common/Clipboard';
import {BookmarksContext} from '@/containers/Bookmarks';
import {AssetThumbnail} from '@/apps/ResourceCenter/Assets/AssetThumbnail';
import {AssetActions} from './AssetActions';
import {AssetTagList} from '@/apps/ResourceCenter/Assets/AssetTagList';
import {AssetContext} from '@/apps/ResourceCenter/assetContext';
import {AssetDownloadIconRenderer} from '@/components/common/AssetDownload/AssetDownloadRenderer';

const Asset = props => {
    const {
        id,
        isNew,
        dateApproved,
        additional,
        scheme,
        name,
        productFamilies,
        products,
        assetCategory,
        assetType,
        tag,
        sizes,
        mimetype,
        fileExtension,
        formats,
        relatedAssets,
        className,
        assetDownloderType = 'dialog'
    } = props;

    const clipboardApi = useContext( ClipboardContext );
    const bookmarksApi = useContext( BookmarksContext );
    const assetDownloadApi = useAssetDownload( {
        assets: [{id, additional, scheme, name, sizes, mimetype, fileExtension}]
    } );
    const dateApprovedFormatted = getFormattedISODate( dateApproved, 'MMMM d, yyyy' );
    const isSelected = clipboardApi.isInClipboard( props );
    const isBookmarked = bookmarksApi.isBookmarked( props );
    const categories = flatten( [productFamilies, products, assetCategory, assetType].filter( Boolean ) );
    const assetTileClassNames = cx(
        'group relative p-2 border-2 border-transparent leading-tight rounded-md',
        className,
        {'border-blue': isSelected}
    );

    const AssetDownloader = assetDownloderType === 'menu' ? AssetDownloadMenu : AssetDownloadDialog;

    return (
        <AssetContext.Provider value={{assetData: props, isSelected, clipboardApi, bookmarksApi}}>
            <div data-testid="AssetTile" className={assetTileClassNames} data-asset-id={id}>
                <Show when={isNew}>
                    <span
                        className="absolute top-0 left-0 gradient-orange-sheen sash text-xs text-white">New</span>
                </Show>
                <AssetActions isSelected={isSelected}
                              isBookmarked={isBookmarked}
                              onDownloadClick={assetDownloadApi.onPromptDownloadOptions}/>
                <div className="mb-2 relative">
                    <ModalLink root="/resources" to={`/assets/${id}`} className="block">
                        <AssetThumbnail name={name} id={id} isPreviewOnly={true} scheme={props.scheme} imgRatio="1x1"/>
                    </ModalLink>
                </div>
                <h3 className="text-title mb-2">
                    <ModalLink root="/resources" to={`/assets/${id}`} className="link-hover break-words">
                       {additional?.AssetTitle ?? name}
                    </ModalLink>
                </h3>
                <Show when={notEmptyOrNil( dateApprovedFormatted )}>
                    <p className="text-xs mb-1">{t( 'label.approved', _.label.approved )}: {dateApprovedFormatted}</p>
                </Show>
                {notEmptyOrNil( categories ) &&
                    <p title={categories.join( ', ' )}
                       className="cursor-help text-grey-darker text-sm md:text-body-sm mt-0 mb-2 line-clamp-3">
                        {t( 'asset.label.appearsIn', _.asset.label.appearsIn )} {categories.join( ', ' )}
                    </p>
                }
                {notEmptyOrNil( tag ) && <AssetTagList tags={tag}/>}
                <div className="sm:flex justify-start items-center">
                    <div className="my-4 flex items-center">
                        {notEmptyOrNil( relatedAssets ) &&
                            <ModalLink root="/resources"
                                       to={`/assets/${id}`}
                                       className="badge-hollow link-2 mr-4 hover:bg-blue hover:text-white t-bg-color t-duration-short">
                                + {relatedAssets.length} {
                                t(
                                    'asset.label.variant',
                                    _.asset.label.variant,
                                    {count: relatedAssets.length}
                                )}
                            </ModalLink>
                        }
                        {formats.map( fm =>
                            <FileFormatIcon key={fm} format={fm} className="ml-1 inline-block text-grey-dark"/>
                        )}
                    </div>
                    <div className="my-4 flex flex-auto flex-nowrap justify-end">
                        <AssetDownloader
                            assetDownloadApi={assetDownloadApi}
                            label={t( 'actionLabel.download', _.actionLabel.download )}
                            className="inline-block ml-4 link-2 relative">
                            {AssetDownloadIconRenderer}
                        </AssetDownloader>
                    </div>
                </div>
            </div>
        </AssetContext.Provider>
    );
}

Asset.Skeleton = ( {className = ''} ) =>
    <div className={className}>
        <SkeletonElement type="thumbnail-full" className="mb-4"/>
        <SkeletonElement type="title"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="icon"/>
        <SkeletonElement type="icon"/>
    </div>;

Asset.defaultProps = {
    tag: [],
    relatedAssets: [],
    formats: [],
    productFamilies: [],
    products: [],
    assetCategory: null,
    assetType: null
};

export {
    Asset
}
