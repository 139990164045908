import {atomFamily, selectorFamily, useRecoilValueLoadable} from 'recoil';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import pageModelStore from './store';
import PageModelRepository from './repository';

export const repository = new PageModelRepository( pageModelStore );

const syncStorageEffect = storeKey => ( {setSelf, trigger} ) => {
    // initialize
    if ( trigger === 'get' ) { // Avoid expensive initialization
        setSelf( repository.getPageModel( storeKey ) );
    }
    // subscribe
    const unsubscribe = repository.subscribe( data => setSelf( data ) );

    // cleanup
    return () => unsubscribe()
}

const pageModelState = atomFamily( {
    key: 'pageModel',
    default: {},
    effects_UNSTABLE: ( storeKey ) => [
        syncStorageEffect( storeKey )
    ]
} );

function getPageModelContents ( {state, contents} ) {
    if ( state === 'hasValue' && notEmptyOrNil( contents ) ) {
        return contents;
    }

    return [];
}

export const pageModelSelector = selectorFamily( {
    key: 'pageModelSelector',
    get: ( storeKey ) => ( {get} ) => {
        return get( pageModelState( storeKey ) );
    }
} );

export default function usePageModel ( storeKey ) {

    const STORE_KEY = storeKey;

    const pageModelLoadable = useRecoilValueLoadable( pageModelState( STORE_KEY ) );
    const pageModelStatusLoadable = useRecoilValueLoadable( pageModelSelector( STORE_KEY ) );

    const pageModelStatus = pageModelStatusLoadable.state === 'hasValue'
        ? pageModelStatusLoadable.contents
        : {};

    return {
        loading: pageModelLoadable.state === 'loading',
        error: pageModelLoadable.state === 'hasError' ? pageModelLoadable.contents : null,
        pageModel: getPageModelContents( pageModelLoadable ),
        pageModelStatus
    };
}
