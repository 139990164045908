console.warn( '[Mocking] "general" api' );

export const Responses = {
    SUCCESS: [200, {data: 'success'}],
    UNKNOWN_ERROR: [400, {}],
    COUNTRY_LIST: [200, [
        {
            'Id': 6850201,
            'Name': 'Andorra',
            'Value': 'AD'
        },
        {
            'Id': 6850302,
            'Name': 'United Arab Emirates',
            'Value': 'AE'
        },
        {
            'Id': 6850303,
            'Name': 'Afghanistan',
            'Value': 'AF'
        },
        {
            'Id': 6850304,
            'Name': 'Antigua and Barbuda',
            'Value': 'AG'
        },
        {
            'Id': 6850305,
            'Name': 'Anguilla',
            'Value': 'AI'
        },
        {
            'Id': 6850306,
            'Name': 'Albania',
            'Value': 'AL'
        },
        {
            'Id': 6850307,
            'Name': 'Armenia',
            'Value': 'AM'
        },
        {
            'Id': 6850308,
            'Name': 'Angola',
            'Value': 'AO'
        },
        {
            'Id': 6850309,
            'Name': 'Antarctica',
            'Value': 'AQ'
        },
        {
            'Id': 6850310,
            'Name': 'Argentina',
            'Value': 'AR'
        },
        {
            'Id': 6850311,
            'Name': 'American Samoa',
            'Value': 'AS'
        },
        {
            'Id': 6850312,
            'Name': 'Austria',
            'Value': 'AT'
        },
        {
            'Id': 6850313,
            'Name': 'Australia',
            'Value': 'AU'
        },
        {
            'Id': 6850314,
            'Name': 'Aruba',
            'Value': 'AW'
        },
        {
            'Id': 6850315,
            'Name': 'Åland Islands',
            'Value': 'AX'
        },
        {
            'Id': 6850316,
            'Name': 'Azerbaijan',
            'Value': 'AZ'
        },
        {
            'Id': 6850317,
            'Name': 'Bosnia and Herzegovina',
            'Value': 'BA'
        },
        {
            'Id': 6850318,
            'Name': 'Barbados',
            'Value': 'BB'
        },
        {
            'Id': 6850319,
            'Name': 'Bangladesh',
            'Value': 'BD'
        },
        {
            'Id': 6850320,
            'Name': 'Belgium',
            'Value': 'BE'
        },
        {
            'Id': 6850321,
            'Name': 'Burkina Faso',
            'Value': 'BF'
        },
        {
            'Id': 6850322,
            'Name': 'Bulgaria',
            'Value': 'BG'
        },
        {
            'Id': 6850323,
            'Name': 'Bahrain',
            'Value': 'BH'
        },
        {
            'Id': 6850324,
            'Name': 'Burundi',
            'Value': 'BI'
        },
        {
            'Id': 6850325,
            'Name': 'Benin',
            'Value': 'BJ'
        }
    ]]
};

export default function ( instance ) {
    instance
        .onGet( '/getcountrycodes' )
        .reply( () => {
            return Responses.COUNTRY_LIST
            // return Responses.UNKNOWN_ERROR;
        } );
}




