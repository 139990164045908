import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {Checkbox} from '@ultradent/components';

const FacetLine = ( {id, groupId, groupName, title, resultCount, onLineChange} ) => {
    const handleChange = useCallback( ( checked, value ) =>
            onLineChange && onLineChange( {id, groupId, groupName, title, resultCount}, checked ),
        []
    );

    return (
        <div className="flex">
            <Checkbox name={groupId} value={id} label={title} onChange={handleChange} labelClassName="text-sm"/>
            {resultCount && <span className="ml-1 text-xs text-grey-darker">({resultCount})</span>}
        </div>
    )
}

FacetLine.propTypes = {
    id: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export {FacetLine}
