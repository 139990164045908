import PropTypes from 'prop-types';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {SkeletonElement} from '@ultradent/components';
import {guid} from '@ultradent/utilities/Guid';
import {Link} from 'react-router-dom';
import flatten from 'ramda/src/flatten';

function buildCategorizedTagList ( categories = {} ) {
    // note -> these categories are hardcoded for the time being until variable facets are needed
    const categorize = ( list, group ) => Array.isArray( list ) ? list.map( value => ({group, value}) ) : null;
    const productFamilyGroup = categorize( categories.productFamilies, 'ProductFamilies' );
    const productGroup = categorize( categories.products, 'Products' );
    const tags = categorize( categories.tags, 'Tags' );

    return flatten( [productFamilyGroup, productGroup, tags].filter( Boolean ) );
}

export const Taglist = ( {tags = []} ) =>
    tags.map( t => <Link key={`${guid()}-${t.value}`} className="tag hover:link-2 mr-2 mb-2"
                         to={`/search?keyword=&${t.group}=${encodeURIComponent( t.value )}`}>{t.value}</Link> );

const FaqLine = ( props ) => {
    const {
        id,
        question,
        answer,
        products,
        productFamilies,
        tags
    } = props;

    const tagList = buildCategorizedTagList( {products, productFamilies, tags} );

    return (
        <div data-testid="FaqLine" className="mb-12 max-w-lg" data-asset-id={id}>
            <h3 className="subheading-base leading-tight mb-2">{question}</h3>
            <p className="flex text-base txt-c2 mb-4">
                <span className="font-bold mr-2">A:</span>
                <span>{answer}</span>
            </p>

            {notEmptyOrNil( tagList ) &&
                <Taglist tags={tagList}/>
            }
        </div>
    );
}

FaqLine.Skeleton = ( {className = ''} ) =>
    <div className={`mb-12 max-w-lg ${className}`} data-testid="FaqLine.Skeleton">
        <SkeletonElement.Paragraph className="mb-4"/>
        <SkeletonElement type="text" className="mb-4"/>
        <div>
            <SkeletonElement type="tag"/>
            <SkeletonElement type="tag"/>
        </div>
    </div>;

FaqLine.defaultProps = {
    tag: []
};

FaqLine.propTypes = {
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired

};

export {FaqLine}
