const ROOT_MENU_ID = 'root-menu';
const MENU_TARGET_ID = 'data-target-menu-id';
const MENU_OPEN_CLASS = 'js-site-menu--open';
const MENU_QUERY = '[data-menu-id]';
const MENU_TOGGLES_QUERY = '[data-action="site-menu-toggle"]';
const MENU_SUBNAV_TRIGGER_QUERY = '[data-target-menu-id]';
const MENU_BACK_QUERY = '[data-action-back]';
const STATE_ON_LEFT = 'move-oncanvas-left';
const STATE_OFF_LEFT = 'move-offcanvas-left';
const STATE_OFF_RIGHT = 'move-offcanvas-right';
const STATE_OPEN = 'open';
const STATE_INACTIVE = 'inactive';
const STATE_ACTIVE = 'active';
const STATE_MOVE_RIGHT = 'move-from-right';
const STATE_MOVE_LEFT = 'move-from-left';
const STATE_UNVIEWED = 'data-menu-unviewed';

class SiteMenu {
    activeMenuId = ROOT_MENU_ID;
    inactiveMenuStack = [];
    _isShowingMenu = false;
    nodes = {};

    // #nav-site-menu--wrap
    constructor ( containerEl ) {
        console.warn( '[Init Site Menu]' );

        this.nodes.docEl = document.documentElement;
        this.menuWrap = containerEl;
        this.menuCollection = Array.from( this.menuWrap.querySelectorAll( MENU_QUERY ) );
        this.nodes.actionBtns = Array.from( document.querySelectorAll( MENU_TOGGLES_QUERY ) );

        this.initEvents();
    }

    initEvents () {
        this.nodes.actionBtns.forEach( item => {
            item.addEventListener( 'click', this.handleMenuIconClick )
        } );

        this.menuWrap.addEventListener( 'click', ( e ) => {
            if ( e.target === e.currentTarget && !this.menuWrap.classList.contains( 'hidden' ) ) {
                this.closeMenu();
            }
            // handle link clicks within menu; closes menu for links handled from a ClientRouter
            if ( e.target.matches( 'a[href]' ) ) {
                this.closeMenu();
            }
        } )

        const menuSubnavTriggers = Array.from( this.menuWrap.querySelectorAll( MENU_SUBNAV_TRIGGER_QUERY ) );
        menuSubnavTriggers.forEach( item => {
            item.addEventListener( 'click', this.handleMenuClick );
        } )

        const backLinks = Array.from( this.menuWrap.querySelectorAll( MENU_BACK_QUERY ) );
        backLinks.forEach( item => {
            item.addEventListener( 'click', this.handleBackButtonClick );
        } )

    }

    handleMenuIconClick = e => {
        !this._isShowingMenu ? this.openMenu() : this.closeMenu();
    };

    toggleMenuOverlay = () => {
        this.nodes.docEl.classList.toggle( MENU_OPEN_CLASS, this._isShowingMenu );
    };

    resetScroll ( menuItem ) {
        const scrollContext = menuItem.querySelector( '.nav-scroller' );
        if ( scrollContext ) {
            scrollContext.scrollTop = 0;
        }
    }

    openMenu = () => {
        const menu = this.menuCollection[0];
        this._isShowingMenu = true;
        this.toggleMenuOverlay();
        this.resetScroll( menu );

        this.menuWrap.dataset.overlay = STATE_ACTIVE;
        menu.dataset.state = STATE_ON_LEFT;

        menu.addEventListener( 'animationend', function onAnimEnd () {
            menu.removeEventListener( 'animationend', onAnimEnd );
            menu.dataset.state = STATE_OPEN;
        } );
    };

    closeMenu = () => {
        this._isShowingMenu = false;
        this.activeMenuId = ROOT_MENU_ID;
        this.inactiveMenuStack = [];
        this.toggleMenuOverlay();
        // reset all menu panes to initial state
        this.menuWrap.dataset.overlay = STATE_INACTIVE;
        this.menuCollection.forEach( item => item.dataset.state = STATE_OFF_LEFT );

    };

    handleMenuClick = e => {
        e.stopPropagation();
        const target = e.currentTarget;

        const menuId = e.currentTarget.getAttribute( MENU_TARGET_ID );
        // ignore touch on current active menu item
        if ( menuId === this.activeMenuId ) {
            return;
        }

        this.inactiveMenuStack.push( this.activeMenuId );
        this.activeMenuId = menuId;

        // deactivate current slide
        const parentMenu = target.closest( MENU_QUERY );
        if ( !parentMenu ) {
            return;
        }
        parentMenu.dataset.state = STATE_INACTIVE;

        // activate next menu slide (by id)
        const menu = this.menuWrap.querySelector( `[data-menu-id=${menuId}]` );
        this.resetScroll( menu );
        menu.removeAttribute( STATE_UNVIEWED );
        menu.dataset.state = STATE_MOVE_RIGHT;
    };

    handleBackButtonClick = e => {
        e.stopPropagation();
        const target = e.currentTarget;

        // get current active slide and set to off
        const parentMenu = target.closest( MENU_QUERY );
        if ( !parentMenu ) {
            return;
        }
        parentMenu.dataset.state = STATE_OFF_RIGHT;

        // get previous inactive menu to reactivate
        const prevMenuId = this.inactiveMenuStack.pop();
        this.activeMenuId = prevMenuId;

        const prevMenu = this.menuCollection.find( item => item.dataset.menuId === prevMenuId );
        prevMenu.dataset.state = STATE_MOVE_LEFT;

        prevMenu.addEventListener( 'animationend', function onAnimEnd () {
            prevMenu.removeEventListener( 'animationend', onAnimEnd );
            prevMenu.dataset.state = STATE_OPEN;
        } )
    };

}

export default SiteMenu;
