import {Button, Modal, SelectField, t, TextField} from '@ultradent/components';
import invoke from 'lodash.invoke';
import {FormikProvider, useFormik} from 'formik'
import {translationKeys as _} from '@/constants/translations';
import {noop} from '@ultradent/utilities/Functions';
import React, {useCallback, useState} from 'react';
import {validateEmail} from '@ultradent/utilities/Strings';
import AccountService from '@/providers/account';
import {ErrorCodes, ErrorMessages, getErrorList} from '@/util/Errors';
import FormPostError from '@/components/common/Form/FormPostError';
import {notify} from '@/modules/notifier';
import useAccountFieldOptions from '@/containers/UserManagement/useAccountFieldOptions';

const initialState = {
    accountName: '',
    email: '',
    accountType: '',
    region: '',
    salesRepId: ''
};
const labelRequired = `(${t( 'common.label.required', _.label.required )})`;

export const CreateNewAccount = ( {isOpen, onClose = noop, ...props} ) => {
    const [isProcessing, setIsProcessing] = useState( props.processing );
    const [formPostError, setFormPostError] = useState( null );
    const {fieldOptions} = useAccountFieldOptions();

    const formik = useFormik( {
        initialValues: initialState,
        enableReinitialize: true,
        validate: values => {
            const fieldRequired = t( 'notify.fieldRequired', _.notify.fieldRequired );

            const errors = {};
            if ( !values.accountName ) {
                errors.accountName = fieldRequired;
            }
            if ( !validateEmail( values.email ) ) {
                errors.email = t( 'notify.invalidEmail', _.notify.invalidEmail )
            }
            if ( !values.email ) {
                errors.email = fieldRequired;
            }
            if ( !values.accountType ) {
                errors.accountType = fieldRequired;
            }
            if ( !values.region ) {
                errors.region = fieldRequired;
            }
            if ( !values.salesRepId ) {
                errors.salesRepId = fieldRequired;
            }
            return errors;
        },
        onSubmit: async ( values ) => {
            setFormPostError( null );

            if ( !isProcessing ) {
                setIsProcessing( true );

                try {
                    const resp = await AccountService.createAccount( values );
                    notify.success(
                        t( 'account.notify.createSuccess',
                            _.account.notify.createSuccess,
                            {name: resp.data?.name} )
                    );
                    invoke( props, 'onCreateSuccess' );
                    _onClose();
                }
                catch ( err ) {
                    handleRequestError( err );
                }
                finally {
                    setIsProcessing( false );
                }
            }

            return false;
        }
    } );

    const _onClose = useCallback( () => {
        setFormPostError( null );
        formik.resetForm();
        onClose();
    }, [] );

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            parseRequestErrorResponse( err.response.data );
            return;
        }

        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function parseRequestErrorResponse ( err ) {
        if ( err.code === ErrorCodes.AccountAlreadyExists ) {
            handleValidationError( err );
            return;
        }

        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function handleValidationError ( err ) {
        setFormPostError( {
            message: err.message,
            errorList: getErrorList( err.data )
        } );
    }

    return (
        <Modal
            title={
                t( 'account.label.createNewAccount', _.account.label.createNewAccount )
            }
            size="sm"
            onClose={_onClose}
            isOpen={isOpen}>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    {formPostError &&
                        <FormPostError {...formPostError}>
                            <h4 className="text-fail text-title">
                                {t( 'message.genericRequestError', _.message.genericRequestError )}
                            </h4>
                        </FormPostError>
                    }
                    <TextField
                        id="accountName"
                        labelAppend={labelRequired}
                        value={formik.values.accountName}
                        onChange={formik.handleChange}
                        error={formik.touched.accountName && formik.errors.accountName}
                        className="mb-4"
                        label="Account Name"
                    />
                    <TextField
                        id="email"
                        labelAppend={labelRequired}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && formik.errors.email}
                        className="mb-4"
                        label="Email"
                    />
                    <SelectField
                        id="accountType"
                        labelAppend={labelRequired}
                        value={formik.values.accountType}
                        onChange={formik.handleChange}
                        error={formik.touched.accountType && formik.errors.accountType}
                        className="mb-4"
                        label="Account Type">
                        <option value=""></option>
                        {fieldOptions?.accountTypes?.map( ( {code, name} ) => <option key={code}
                                                                                      value={code}>{name}</option> )}
                    </SelectField>
                    <SelectField
                        id="region"
                        labelAppend={labelRequired}
                        value={formik.values.region}
                        onChange={formik.handleChange}
                        error={formik.touched.region && formik.errors.region}
                        className="mb-4"
                        label="Region">
                        <option value=""></option>
                        {fieldOptions?.regions?.map( ( {code, name} ) => <option key={code}
                                                                                 value={code}>{name}</option> )}
                    </SelectField>
                    <SelectField
                        id="salesRepId"
                        labelAppend={labelRequired}
                        value={formik.values.salesRepId}
                        onChange={formik.handleChange}
                        error={formik.touched.salesRepId && formik.errors.salesRepId}
                        className="mb-4"
                        label="Sales Representative">
                        <option value=""></option>
                        {fieldOptions?.salesReps?.map( ( {code, name} ) => <option key={code}
                                                                                   value={code}>{name}</option> )}
                    </SelectField>
                    <div className="mt-8">
                        <Button type="submit" loading={isProcessing} tabIndex="0" className="mr-4">
                            {t( 'account.actionLabel.createAccount', _.account.actionLabel.createAccount )}
                        </Button>
                        <Button tertiary onClick={_onClose} as="div" tabIndex="0">
                            {t( 'actionLabel.cancel', _.actionLabel.cancel )}
                        </Button>
                    </div>
                </form>
            </FormikProvider>
        </Modal>
    );
}

CreateNewAccount.defaultProps = {
    isOpen: false,
    onClose: noop,
    onSubmit: noop
};

