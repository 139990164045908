import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {basePath: '/productanswers'} );

svc.querySuggestions = function querySuggestions ( {query, filter, top = 7} ) {
    return this.xhr.get( 'search/suggestions', {params: {query, filter, top}} );
};

svc.getTaxonomy = function getTaxonomy () {
    return this.xhr.get( 'taxonomy' );
}

export const ProductAnswersProvider = svc.xhr;

export default svc;


