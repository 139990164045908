import cx from 'classnames';
import PropTypes from 'prop-types';
import {CategoryLinkList} from '@/components/common/PageNavigation/CategoryLinkList';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {identity} from 'ramda';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const resultGridStyle = 'fade-in w-full grid gap-2 md:gap-4';
const gridSmallStyle = 'grid-cols-2 lg:grid-cols-3';
const gridLargeStyle = 'grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3';

const getGridStyle = gridSize => (gridSize === 'small')
    ? gridSmallStyle
    : gridLargeStyle;

export const NoResults = ( {keyword, taxonomy} ) =>
    <div className="w-full">
        <div className="max-w-md">
            <h3 className="headline-sm mb-4">
                {t(
                    'search.message.noResults.heading',
                    _.search.message.noResults.heading,
                    {keyword} )}
            </h3>
            <p className="text-body">{t(
                'search.message.noResults.body1',
                _.search.message.noResults.body1
            )}</p>
        </div>
        {notEmptyOrNil( taxonomy ) &&
            <div className="contain-content">
            <hr className="mb-24 border-0"/>
            <h3 className="headline-xs mb-8">{t(
                'search.message.noResults.subheading',
                _.search.message.noResults.subheading
            )}</h3>
            <CategoryLinkList taxonomy={taxonomy}/>
        </div>
        }

    </div>;

export const RelatedCollections = ( {component, keyProp, list} ) => {
    const Renderer = component;
    if ( notEmptyOrNil( list ) ) {
        return (
            <div>
                <h3 className="subheading-base mb-4">
                    {t(
                        'search.label.relatedCollections',
                        _.search.label.relatedCollections
                    )}
                </h3>
                <div className="mb-28 flex justify-content-between gap-12">
                    {list.map( item => <Renderer key={item[keyProp]} {...item} className="w-1/6"/> )}
                    <div className="flex items-center">
                        <a href="/browse-collections" className="button ghosted">
                            {t(
                                'search.label.browseCollections',
                                _.search.label.browseCollections
                            )}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    return null;
}
RelatedCollections.defaultProps = {
    keyProp: 'id'
};

export const ResultGrid = ( {results, component, keyProp, transform, gridSize} ) => {
    const Renderer = component;
    return (
        <div data-testid="ResultGrid" className={cx( resultGridStyle, getGridStyle( gridSize ) )}>
            {results.map( item => <Renderer key={item[keyProp]} {...transform( item )} /> )}
        </div>
    )
}
ResultGrid.propTypes = {
    component: PropTypes.func.isRequired
}
ResultGrid.defaultProps = {
    keyProp: 'id',
    transform: identity
};

ResultGrid.Skeleton = ( {component, gridSize} ) => {
    const Renderer = component;
    return (
        <div data-testid="ResultGrid.Skeleton" className={cx( resultGridStyle, getGridStyle( gridSize ) )}>
            {Array( 50 ).fill( 'i' ).map( ( d, i ) => <Renderer.Skeleton key={i}/> )}
        </div>
    )
}
ResultGrid.Skeleton.propTypes = {
    component: PropTypes.func.isRequired
}

export const ResultList = ( {results, component, keyProp, transform, gridSize} ) => {
    const Renderer = component;
    return (
        <div data-testid="ResultList" className="fade-in w-full">
            {results.map( item => <Renderer key={item[keyProp]} {...transform( item )} /> )}
        </div>
    )
}
ResultList.propTypes = {
    component: PropTypes.func.isRequired
}
ResultList.defaultProps = {
    keyProp: 'id',
    transform: identity
};

ResultList.Skeleton = ( {component} ) => {
    const Renderer = component;
    return (
        <div data-testid="ResultList.Skeleton" className="fade-in w-full">
            {Array( 50 ).fill( 'i' ).map( ( d, i ) => <Renderer.Skeleton key={i}/> )}
        </div>
    )
}
ResultList.Skeleton.propTypes = {
    component: PropTypes.func.isRequired
}
