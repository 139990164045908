import {Button, Modal, Radio, t} from '@ultradent/components';
import {FormikProvider, useFormik} from 'formik';
import {Roles} from '@/constants/appConstants';
import {translationKeys as _} from '@/constants/translations';

const noop = () => console.log( '[RoleChange] noop' );

export const RolesOptionList = ( {onChange = noop} ) =>
    <fieldset className="max-w-sm leading-snug">
        <Radio
            name="role"
            id="admin"
            value={Roles.PARTNER_ADMIN}
            labelClassName="flex"
            label={
                <div className="mb-6">
                    <h4 className="subheading-sm">{t( 'userManagement.label.admin', _.userManagement.label.admin )}</h4>
                    <p className="text-grey-darker">{t(
                        'userManagement.label.adminHint',
                        _.userManagement.label.adminHint
                    )}</p>
                </div>
            }
            onChange={onChange}>
        </Radio>
        <Radio
            name="role"
            id="member"
            value={Roles.MEMBER}
            labelClassName="flex"
            label={
                <div className="mb-6">
                    <h4 className="subheading-sm">{t( 'userManagement.label.member',
                        _.userManagement.label.member )}</h4>
                    <p className="text-grey-darker">{t(
                        'userManagement.label.memberHint',
                        _.userManagement.label.memberHint
                    )}</p>
                </div>
            }
            onChange={onChange}>
        </Radio>
    </fieldset>;

export const ManageUserRole = ( {user, processing, error, isOpen, onSubmit, onClose} ) => {
    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            role: [user.role]
        }
    } );

    return (
        <Modal
            title={t( 'userManagement.label.roleFormTitle', _.userManagement.label.roleFormTitle )}
            size="md"
            onClose={onClose}
            isOpen={isOpen}>
            {error &&
                <div className="my-4 text-fail text-base leading-snug">
                <h3 className="mb-2 font-medium text-sm">{t(
                    'userManagement.notify.changeSubmissionFailed',
                    _.userManagement.notify.changeSubmissionFailed )}</h3>
            </div>
            }
            <FormikProvider value={formik}>
                <RolesOptionList/>
                <div className="flex w-full justify-end">
                    <Button
                        loading={processing}
                        onClick={() => onSubmit( {
                            azureAdId: user.userId,
                            isAdmin: formik.values.role === Roles.PARTNER_ADMIN
                        } )}>
                        {t( 'userManagement.actionLabel.updateRole', _.userManagement.actionLabel.updateRole )}
                    </Button>
                </div>
            </FormikProvider>
        </Modal>
    )
};

ManageUserRole.defaultProps = {
    isOpen: false,
    processing: false,
    error: null,
    onClose: () => {},
    onSubmit: () => {},
    user: {
        userId: null,
        role: null // 'member' or 'admin'
    }
}
