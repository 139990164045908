import React, {useEffect} from 'react';
import {Button, Modal, t, TextField} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {FormikProvider} from 'formik';
import useAssetDownload from '@/components/common/AssetDownload/useAssetDownload';
import {notify} from '@/modules/notifier';
import {AssetDownloadButtonRenderer} from '@/components/common/AssetDownload/AssetDownloadRenderer';
import {AssetDownloadOptions} from '@/components/common/AssetDownload/AssetDownloadOptions';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';

export const DownloadAssets = ( {assets, allowAnonymous, error, isOpen, onSubmit, onClose} ) => {

    const {
        formProvider,
        isDownloading,
        isComplete,
        progress,
        onDownload,
        sizeOptions
    } = useAssetDownload( {assets, allowAnonymous} );

    useEffect( () => {
        if ( isComplete ) {
            onClose();
            formProvider.setFieldValue( 'downloadFilename', '' );
            notify.success( t( 'message.downloadComplete', _.message.downloadComplete ) )
        }
    }, [isComplete] );

    return (
        <Modal
            title={
                t( 'downloadAssets.label.downloadItem',
                    _.downloadAssets.label.downloadItem,
                    {count: assets.length} )
            }
            size="md"
            onClose={onClose}
            isOpen={isOpen}>

            {error &&
                <div className="my-4 text-fail text-base leading-snug">
                <h3 className="mb-2 font-medium text-sm">
                    {t( 'downloadAssets.notify.downloadFailed', _.downloadAssets.notify.downloadFailed )}
                </h3>
            </div>
            }
            <ul className="list-reset mb-8 grid grid-cols-2 gap-2">
                {assets.map( asset =>
                    <li key={asset.id} className="text-sm leading-tight text-grey-dark flex items-center mb-1">
                        <FileFormatIcon format={asset.scheme} width="20" className="mr-2 flex-none"/> <span
                        className="break-words">{asset.name}</span>
                    </li>
                )}
            </ul>
            <FormikProvider value={formProvider}>
                <div data-testid="DownloadAsset.Modal">
                    <AssetDownloadOptions sizeOptions={sizeOptions}/>
                    <div className="md:flex w-full justify-between">
                        <div className="w-full md:max-w-xs mb-4 md:mb-0">
                        {assets.length > 1 &&
                            <>
                                <label htmlFor="downloadFilename">
                                    {t( 'downloadAssets.label.fileName', _.downloadAssets.label.fileName )}
                                    <span className="text-caption text-grey-dark block mt-1 leading-tight">
                                    {t( 'downloadAssets.label.fileNameHint', _.downloadAssets.label.fileNameHint )}
                                </span>
                                </label>
                                <fieldset className="flex flex-auto w-full md:max-w-sm my-0 md:mr-4">
                                    <TextField
                                        id="downloadFilename"
                                        name="downloadFilename"
                                        placeholder={t( 'downloadAssets.label.fileNamePlaceholder',
                                            _.downloadAssets.label.fileNamePlaceholder )}
                                        disabled={isDownloading}
                                        onChange={formProvider.handleChange}
                                        value={formProvider.values.downloadFilename}
                                        error={formProvider.errors.downloadFilename} append=".zip"/>
                                </fieldset>
                            </>
                        }
                        </div>


                        <div className="flex">
                            <Button
                                tertiary
                                className="w-full md:w-48 self-end h-10"
                                loading={isDownloading}
                                onClick={onDownload}>
                                {AssetDownloadButtonRenderer( {isDownloading, progress, isComplete} )}
                            </Button>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
};

DownloadAssets.defaultProps = {
    assets: [],
    isOpen: false,
    processing: false,
    error: null,
    onClose: () => {},
    onSubmit: () => {}
}
