console.warn( '[Mocking] "page-model" api' );

export default function ( instance ) {
    instance
        .onGet( '/content' )
        .reply( () => ([
            200,
            {}
        ]) )
}
