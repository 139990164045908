const pageModel = {
    pageTitle: 'Product answers',
    pageSubtitle: 'Browse or search from hundreds of frequently asked questions and help guides for our line of products at Ultradent Products, Inc.',
    popularTopics: {
        title: 'Popular topics to browse',
        subtitle: 'Not sure where to start? Here are a few of our most frequently searched topics to get you started.',
        tiles: [
            {
                productFamily: 'Endodontics',
                topicFacet: 'ProductFamilies',
                topicKeyword: 'Endodontics'
            },
            {
                productFamily: 'Whitening',
                topicFacet: 'Tags',
                topicKeyword: 'Whitening'
            },
            {
                productFamily: 'Allergens',
                topicFacet: 'Tags',
                topicKeyword: 'Allergen'
            },
            {
                productFamily: 'Cleaners and Disinfection',
                topicFacet: 'Tags',
                topicKeyword: 'Cleaner|Alcohol|AntiBacterial|Antimicrobial|Chemical|Clean|Cleaning|Sterilization'
            }
        ]
    },
    faq: {
        title: 'Frequently asked questions',
        list: [
            {
                question: 'What cleaning agents should I use to clean the Valo Curing Light?',
                answer: 'To clean the VALO curing light, you should use isopropyl-alcohol based cleaners, ethyl alcohol-based cleaners, or another non-bleach, non-abrasive cleaner. We recommend using: Isopropyl Alcohol, Ethyl alcohol-based cleaners, lysol Brand 3 Disinfectant Spray, Lysol Concentrate (alcohol-based only)'
            },
            {
                question: 'How much shrinkage do you get with VALO in Xtra Power Mode?',
                answer: 'The shrinkage is the same. The rate or how fast it shrinks is the major difference. Xtra Power Mode is about 3 times more powerful than standard mode, which will make the rate of conversion somewhere around 3 times as fast. This means the speed with which the stress builds up in the tooth is substantially more aggressive. In theory, this can produce a restoration with more built-in stress. Clearly, the theory doesn\'t predict reality, since many doctors use plasma lights without problems. This is the reason we feel safe in producing our light with this power.'
            },
            {
                question: 'Do we have a light meter for VALO?',
                answer: 'No. You can use your existing light meter, but be aware that light meters were not designed to give an accurate reading for this light. Instead, use your meter when the VALO is new and record the reading as a reference to compare future readings.'
            },
            {
                question: 'Does Enamelast contain colophony (rosin, tree resin) or tree nuts?',
                answer: 'All fluoride varnishes are made with rosins, which are derived from trees and have shown no direct correlation to nut allergens or any other specific allergens. That doesn\'t eliminate the extremely rare chance that a patient may be allergic/show sensitivity to rosins. Again, this would be an extremely rare case and to our knowledge, this has not been experienced by patients receiving fluoride varnish.'
            },
            {
                question: 'At what age is it safe to bleach children\'s teeth? ',
                answer: 'For take-home bleaching, the child should be at least 12 years old.  For in-office bleaching, any age, at the dentist\'s discretion.'
            },
            {
                question: 'Why is Opalescence listed as offering “unbeatable daytime bleaching, 30 minutes twice daily,” and then also listed with a graph that says the gel is active 8-10 hours?',
                answer: 'Opalescence is active 8-10 hours, so whitening overnight will give the patient the most active whitening time. Since some patients prefer to whiten during the daytime, however, it is important to note that Opalescence can also be used in daytime whitening regimens. Research demonstrates equivalent performance between Opalescence 20% carbamide peroxide and a 7.5% hydrogen peroxide product when both are used in a daytime treatment of 30 minutes, twice daily.'
            },
            {
                question: 'Do Opalescence Go trays contain latex?',
                answer: 'No.'
            },
            {
                question: 'Do patients need to avoid particular foods or drinks following bleaching?',
                answer: 'No studies have shown that particular foods or drinks need to be avoided for a specific amount of time following tooth whitening procedures/treatments.'
            },
            {
                question: 'What is the shelf life of Opalescence Go with and without refrigeration?',
                answer: 'We strongly recommend refrigerating Opalescence Go. When refrigerated, its shelf life is 18 months from date of manufacture. Check the expiration date on the box. For short periods (e.g. during shipment), the material can be at room temperature.'
            },
            {
                question: 'Has the shelf life been affected by the changes on new Opal Boost',
                answer: 'No. The new formula still has a 24-month shelf life from its manufacture date. Specific use-by dates are printed on each kit label'
            }

        ]
    }
}

export default pageModel;
