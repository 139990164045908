import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/account',
    mockSrc: process.env.MOCK_API && 'account'
} );

svc.createAccount = function createAccount ( {accountName, email, accountType, region, salesRepId} ) {
    const body = {
        accountName, email, accountType, region,
        SalesRepId: salesRepId
    };
    return svc.xhr.post( 'create', body );
}

svc.updateAccount = function updateAccount ( {accountId, accountType, salesRep} ) {
    const body = {accountId, accountType, salesRep};
    return svc.xhr.post( 'update', body );
}

svc.getFieldOptions = function getFieldOptions () {
    return svc.xhr.get( `options` );
}

export const AccountProvider = svc.xhr;

export default svc;

