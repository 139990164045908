import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {ResourceAppContext, verifyOrFallBackContent} from '@/apps/ResourceCenter/appContext';
import ResourceCenterRepository from '@/providers/resource-center';
import {FacetedSearchQuery} from '@/containers/FacetedSearch';
import {Asset} from '@/apps/ResourceCenter/Assets/Asset';
import {TopLevelNavigationList} from '@/components/common/PageNavigation/TopLevelNavigationList';
import {CurrentQuery} from '@/containers/FacetedSearch/PageNavigation/CurrentQuery';
import {SearchSuggestions} from '@/apps/ResourceCenter/SearchSuggestions';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {NoResults, RelatedCollections, ResultGrid} from '@/containers/FacetedSearch/Components';
import {transformAssetModel, transformObjectKeys} from '@/containers/FacetedSearch/transforms';
import cx from 'classnames';
import SEO from '@/components/common/SEO';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {CategorySelect} from '@/containers/FacetedSearch/PageNavigation/CategorySelect';
import {useMediaQuery} from 'react-responsive';
import MediaQueries from '@ultradent/utilities/MediaQueries';

const ITEMS_PER_PAGE = 50;

/**
 * Take search string from current location and construct a meaningful url for the api
 * This method can be used to transform the final query and handle special scenarios
 * Keywords and additional configuration can also be modified
 * @param searchStr
 * @returns {{categoryFilter: string[], query, keyword: (string|string), startAt: (string|number)}}
 */
const constructQuery = ( searchStr ) => {
    const searchParams = new URLSearchParams( searchStr );
    // any facets set on initial request will get passed through on searchStr
    let query = searchStr;

    if ( !searchParams.get( 'limit' ) ) {
        query += `&limit=${ITEMS_PER_PAGE}`;
    }

    // hardcode VariantAsset if not provided
    // note -> this gets passed along to the api but not appended to the page url
    if ( !searchParams.get( 'VariantAsset' ) ) {
        query += `&VariantAsset=Primary`;
    }

    return {
        query,
        keyword: searchParams.get( 'keyword' ) || '',
        orderBy: searchParams.get( 'orderBy' ) || '',
        startAt: searchParams.get( 'start' ) || 0,
        categoryFilter: [
            searchParams.get( 'AssetCategory' ),
            searchParams.get( 'AssetType' )
        ].filter( Boolean )
    };
}

const SearchQuery = () => {

    const mediumScreen = useMediaQuery( {query: MediaQueries.min.screenMedium} );
    const {categoryTaxonomy, pageModelApi} = useContext( ResourceAppContext );
    const pageModel = verifyOrFallBackContent( pageModelApi.pageModel );

    return (
        <>
            <FacetedSearchQuery
                queryRepository={ResourceCenterRepository}
                constructQuery={constructQuery}
                itemsPerPage={ITEMS_PER_PAGE}
                sortOptions={[
                    {
                        label: t( 'search.label.sortByRelevance', _.search.label.sortByRelevance ),
                        value: ''
                    },
                    {
                        label: t( 'search.label.sortByNewest', _.search.label.sortByNewest ),
                        value: 'DateApproved desc'
                    }
                ]}
                slotHeader={
                    ( {
                          showSkeleton,
                          keyword,
                          onKeywordChange,
                          onCategoryChange,
                          selectedCategories,
                          onClearCategories
                      } ) =>
                        <div className="relative z-20">
                            <SEO title={keyword ? `${pageModel.pageTitle} : ${keyword}` : pageModel.pageTitle}/>
                            <div className="w-full mb-2">
                                <div className="md:flex items-center">
                                    {pageModelApi.loading
                                     ?
                                     <SkeletonElement className="mr-8 mb-6 mt-2" type="title"/>
                                     :
                                     <Link to="/" className="hover:text-blue">
                                         <h3 className="subheading-lg mr-8 mb-4 whitespace-nowrap">{pageModel.pageTitle}</h3>
                                     </Link>
                                    }
                                    <TopLevelNavigationList
                                        loading={isEmptyOrNil( categoryTaxonomy )}
                                        taxonomy={categoryTaxonomy}
                                        className="mb-2 subheading-sm"/>
                                 </div>
                                <SearchSuggestions
                                    initialQuery={keyword}
                                    selectedCategories={selectedCategories}
                                    prependSearchSlot={mediumScreen && notEmptyOrNil( categoryTaxonomy ) &&
                                        <CategorySelect
                                            loading={showSkeleton}
                                            taxonomy={[
                                                // default category: "All Items"
                                                {
                                                    id: '',
                                                    name: t( 'search.label.allItems', _.search.label.allItems )
                                                },
                                                ...categoryTaxonomy.map( transformObjectKeys )
                                            ]}
                                            selectedCategory={selectedCategories?.[0]}
                                            onChange={id => onCategoryChange( [id] )}/>
                                    }
                                    onSubmit={( e, query ) => onKeywordChange( query.trim() )}
                                />
                            </div>
                            <CurrentQuery
                                isLoading={showSkeleton}
                                keyword={keyword}
                                onClearFilter={onClearCategories}
                                categoryFilter={selectedCategories}/>
                        </div>
                }
                slotResults={( {
                                   results,
                                   relatedCollections,
                                   lastKeyword,
                                   hasResults,
                                   showSkeleton,
                                   showLoadMore,
                                   loadingMore,
                                   onLoadMore
                               } ) => {
                    if ( showSkeleton ) {
                        return <ResultGrid.Skeleton gridSize="small" component={Asset}/>;
                    }
                    return hasResults
                           ? <>
                               <RelatedCollections list={relatedCollections}/>
                               <ResultGrid
                                   results={results}
                                   keyProp="Id"
                                   gridSize="small"
                                   component={Asset}
                                   transform={transformAssetModel}
                               />
                               {showLoadMore &&
                                   <button
                                       onClick={onLoadMore}
                                       disabled={loadingMore}
                                       className={cx(
                                           'button lg tertiary mx-auto my-8 w-full max-w-sm',
                                           {'processing': loadingMore}
                                       )}>
                                       {t( 'actionLabel.loadMore', _.actionLabel.loadMore )}
                                   </button>
                               }
                           </>
                           : <NoResults keyword={lastKeyword} taxonomy={categoryTaxonomy}/>
                }}
            />
        </>
    )
}

export {SearchQuery}
