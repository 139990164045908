import PropTypes from 'prop-types';
import {FormikProvider, useFormik} from 'formik';
import {FilterList, SkeletonElement} from '@ultradent/components';
import {FacetLine} from '@/containers/FacetedSearch/Facets/FacetLine';

const FacetFilterList = ( {
                              list = [],
                              id,
                              groupTitle = '[facet title]',
                              maxLength = 11,
                              minQueryLength = 1,
                              enableSearchAtCount = 11,
                              onLineChange,
                              className
                          } ) => {

    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            [id]: list.filter( i => i.selected ).map( x => String( x.id ) )
        }
    } );

    return (
        <FormikProvider value={formik}>
            <section className={className}>
                <h2 className="subheading-sm mb-2">{groupTitle}</h2>
                <FilterList
                    maxLength={maxLength}
                    minQueryLength={minQueryLength}
                    initialQuery={''}
                    enableSearchAtCount={enableSearchAtCount}
                    alwaysShowResults={false}
                    list={list}
                    itemProps={{onLineChange}}
                    queryProps={['title']}
                    renderer={FacetLine}
                />
            </section>
        </FormikProvider>
    )
}

const Skeleton = ( {className} ) =>
    <section className={className}>
        <SkeletonElement type="title" className="mb-8"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="text"/>
        <SkeletonElement type="text"/>
    </section>;

FacetFilterList.Skeleton = Skeleton;

FacetFilterList.propTypes = {
    id: PropTypes.string.isRequired,
    groupTitle: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
        PropTypes.shape( FacetLine.propTypes )
    )
};

export {FacetFilterList};
