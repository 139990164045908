import React from 'react';
import cx from 'classnames';

export const Card = ( {actionPosition, children, className, ...props} ) => {
    const classNames = cx(
        'group relative flex shadow-1 bg-white border-grey-lightest rounded',
        className,
        {
            'flex-row': actionPosition === 'right',
            'flex-col': actionPosition === 'bottom'
        }
    );

    const childrenWithProps = React.Children.map( children, child => {
        if ( React.isValidElement( child ) ) {
            return React.cloneElement( child, {actionPosition} );
        }
        return child;
    } );

    return (
        <div className={classNames} {...props}>
            {childrenWithProps}
        </div>
    );
}
Card.defaultProps = {
    actionPosition: 'bottom'
}

export const CardActions = ( {actionPosition, children, className, showOnHover} ) => {
    const classNames = cx(
        'p-3 flex flex-1 items-end',
        className,
        {
            'flex-col': actionPosition === 'right',
            'flex-row': actionPosition === 'bottom',
            'lg:invisible group-hover:visible active:visible focus:visible': showOnHover
        }
    );
    return (
        <div className={classNames}>
            {children}
        </div>
    );
}

export const CardContent = ( {children, className} ) =>
    <div className={cx( 'p-4 flex-auto', className )}>
        {children}
    </div>

export const CardFooter = ( {children, className} ) =>
    <div className={cx( 'py-3 px-6', className )}>
        {children}
    </div>
