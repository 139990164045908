import React, {useContext} from 'react';
import {format} from 'date-fns';
import {getStatusStyle} from '../../transformers';
import {Button} from '@ultradent/components';
import {noop} from '@ultradent/utilities/Functions';
import {EventResponseStatus} from '@/constants/appConstants';
import {EventManagementContext} from '@/containers/EventManagement';

const RegistrantLine = ( {
                             id,
                             companyName,
                             fullName,
                             email,
                             phoneNumber,
                             dateSubmitted,
                             status
                         } ) => {
    const {eventManager} = useContext( EventManagementContext );

    return <div data-testid={`registrant-line-${id}`} key={id}
                className="md:flex items-center px-2 py-1 leading-normal even:bg-moon-grey">
        <div className="md:flex-1 px-1 ellipsis">{companyName}</div>
        <div className="md:flex-1 px-1 ellipsis">{fullName}</div>
        <div className="md:flex-1 px-1 ellipsis">{email}<br/>{phoneNumber}</div>
        <div className="md:flex-1 px-1 ellipsis">{dateSubmitted
            ? format( new Date( dateSubmitted ), 'yyyy-MM-dd' )
            : 'Unknown'}
        </div>
        <div className="md:flex-1 px-1">
            <select
                className={getStatusStyle( status )}
                data-testid="registrant-status-select"
                id="status"
                value={status || EventResponseStatus[0]}
                onChange={e => eventManager.onUpdateRegistrant( {
                    id,
                    status: e.target.value
                } )}>
                {EventResponseStatus.map( status =>
                    <option key={status} value={status}>{status}</option>
                )}
            </select>
        </div>
        <div className="md:flex-1 md:flex justify-end">
            <Button ghosted small tertiary className="my-2 md:my-0 md:ml-4 whitespace-nowrap"
                    onClick={() => eventManager.onViewRegistrantDetails( id )}>
                View more
            </Button>
        </div>
    </div>

};

RegistrantLine.defaultProps = {
    onViewRegistrantDetails: noop,
    onUpdateRegistrant: noop
}

export default RegistrantLine;
