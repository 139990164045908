import React from 'react';
import {useRecoilValueLoadable, useSetRecoilState} from 'recoil';
import {LogoMyultradent} from '@/components/common/Icons';
import {Header} from './Header';
import {ListDetail} from './ListDetail';
import heroBackgroundSrc from '@/assets/images/sharing-hero-2021-08.jpg';
import heroBackgroundSrc2x from '@/assets/images/sharing-hero-2021-08@2x.jpg';
import {AssetSharingItem} from './AssetSharingItem';
import {Submission as AssetSharingSubmission} from '@/containers/AssetSharing/Submission';
import {sharedAssetsRefreshCount, sharedAssetsSelector} from '@/containers/AssetSharing/useAssetSharing';
import {LoadError} from '@/components/dialogs/PageLoadError';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export {AssetSharingItem, AssetSharingSubmission};

export const AssetSharing = ( {itemRenderer} ) => {
    const retryCount = useSetRecoilState( sharedAssetsRefreshCount );
    const refetchAssets = () => retryCount( ( n ) => n + 1 );
    const sharedAssets = useRecoilValueLoadable( sharedAssetsSelector );

    switch ( sharedAssets.state ) {
        case 'loading':
            return <>
                <Header.Skeleton/>
                <ListDetail.Skeleton/>
            </>;

        case 'hasError':
            return <LoadError className="py-24" error={sharedAssets.contents} onRetry={refetchAssets}/>;

        case 'hasValue':
        default:
            return (
                <>
                    <Header sharedAssets={sharedAssets.contents}/>
                    <ListDetail sharedAssets={sharedAssets.contents} itemRenderer={itemRenderer}/>
                </>
            );
    }

}

AssetSharing.defaultProps = {
    sharingUser: 'A partner'
}

export const AssetSharingApp = ( {itemRenderer} ) =>
    <div className="condense--pad-for-site-header">
        <header className="h-96 relative bg-white" style={{height: '480px'}}>
            <div className="contain-content h-full flex justify-center items-center">
                <div className="relative z-10 px-4 pt-8 md:pt-16 mb-8 md:px-12 w-full max-w-lg">
                    <div className="text-white text-center mb-8">
                        <LogoMyultradent className="mb-6 inline-block" width="200"/>
                        <h3 className="headline-base leading-none m-0 mb-4">
                            {t( 'assetSharing.heading.resourceSharing', _.assetSharing.heading.resourceSharing )}
                        </h3>
                        <p>{t(
                            'assetSharing.heading.resourceSharingSubtitle',
                            _.assetSharing.heading.resourceSharingSubtitle
                        )}</p>
                    </div>
                </div>
            </div>
            <picture>
                <source data-srcset={heroBackgroundSrc} media="(max-width: 599px)"/>
                <source data-srcset={heroBackgroundSrc2x} media="(min-width: 600px)"/>
                <img data-sizes="auto"
                     data-src={heroBackgroundSrc}
                     className="cover-image clip-chevron-hero lazyload fade-in" alt=""/>
            </picture>
        </header>
        <AssetSharing itemRenderer={itemRenderer || AssetSharingItem}/>
    </div>
