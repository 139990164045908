import {useState} from 'react';
import {Route} from 'react-router-dom';
import {FacetedSearchRouter} from '@/containers/FacetedSearch';
import {AssetDetailQuery} from './Pages/AssetDetailPage';
import {SearchQuery} from './Pages/SearchPage';
import {Resources} from './Pages/ResourcesPage';
import {ProductAckowledgementPrompt} from '@/apps/ResourceCenter/ProductAckowledgementPrompt';

export const routes = [
    {
        path: '/assets/:assetId',
        exact: true,
        main: AssetDetailQuery
    },
    {
        path: '/search',
        exact: false,
        main: SearchQuery
    },
    {
        path: '/',
        exact: true,
        main: Resources
    }
];

function doPromptForProductAcknowledgement () {
    // verify user is logged in and has not accepted acknowledgement
    return window.upi?.User?.info?.userName &&
        !window.upi?.User?.flags?.productAcknowledgementAccepted;
}

export const AppRouter = () => {
    const [promptForProductAcknowledgement, setPromptForProductAcknowledgement] =
        useState( doPromptForProductAcknowledgement() );

    return (
        <FacetedSearchRouter
            routes={routes}
            ResultDetailRoute={
                <Route path="/assets/:assetId" element={<AssetDetailQuery/>}/>
            }
        >
            <ProductAckowledgementPrompt isOpen={promptForProductAcknowledgement}
                                         onClose={setPromptForProductAcknowledgement}/>
        </FacetedSearchRouter>
    );
}
