import React, {useEffect, useRef, useState} from 'react';
import {guid} from '@ultradent/utilities/Guid';
import invoke from 'lodash.invoke';
import {uniqList} from '@ultradent/utilities/Arrays';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {validateEmail} from '@ultradent/utilities/Strings';
import {ErrorCodes, ErrorMessages} from '@/util/Errors';
import {Button, Modal, Show, t, Token} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations'
import TokenInput from 'react-tag-autocomplete';
import {Checkmark, Cross, FormatGeneric} from '@/components/common/Icons';
import {FormikProvider, useFormik} from 'formik';
import AssetService from '@/providers/assets';

// resource -react-tag-autocomplete Docs; https://www.npmjs.com/package/react-tag-autocomplete#autoresize-optional
const tokenInputClassNames = {
    root: 'react-tags md:flex flex-auto items-center',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search min-w-2xs flex-auto',
    searchWrapper: 'react-tags__search-wrapper',
    searchInput: 'react-tags__search-input text-field ghosted w-full',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled'
};

const FormPostError = ( {message, errorList, customMessage} ) =>
    <div className="my-4 text-fail text-base leading-snug">
        {message &&
            <h3 className="mb-2 font-medium text-sm">{message}</h3>
        }
        {Array.isArray( errorList ) &&
            <ul className="bullets-x m-0 p-0">
                {errorList.map( error =>
                    <li key={guid()} className="text-sm font-medium">{error}</li>
                )}
            </ul>
        }
        {customMessage}
    </div>;

// Remove trailing period - some OSs will add a period at end of string on double press of [SPACE] key
const sanitizeTagString = ( value ) => value.trim().replace( /(.*)\.$/, '$1' );
const hasInvalidTag = ( tags ) => {
    return tags.filter( tag => !tag.isValid ).length > 0
};
const hasValidInput = query => validateEmail( sanitizeTagString( query ) ) || !query;
const getValidUserNames = ( tags ) => tags.filter( tag => tag.isValid ).map( tag => tag.name );

const getCopyUrl = async ( assets ) => {
    try {
        const {data} = await AssetService.shareAssetsDirectLink( {
            assets: assets.map( ( {name, id} ) => ({name, assetId: id}) )
        } );

        return data;
    }
    catch ( err ) {
        console.error( 'Failed to fetch Share URL', err );
    }
}

export const ShareAsset = ( props ) => {
    const reactTags = useRef( null );
    const [tags, setTags] = useState( props.tags || [] );
    const [query, setQuery] = useState( '' );
    const [isProcessing, setIsProcessing] = useState( props.processing );
    const [isCopyingLink, setIsCopyingLink] = useState( false );
    const [shareUrl, setShareUrl] = useState( null );
    const [isSubmitDisabled, setIsSubmitDisabled] = useState( false );
    const [formPostError, setFormPostError] = useState( null );

    const formik = useFormik( {
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: {},
        onSubmit: async ( values ) => {
            setFormPostError( null );
            const errors = await formik.validateForm( values );

            if ( !isProcessing && isEmptyOrNil( errors ) ) {
                setIsProcessing( true );

                try {
                    const resp = await AssetService.shareAssets( {
                        emailAddresses: getValidUserNames( tags ),
                        assets: props.assets.map( ( {name, id} ) => ({name, assetId: id}) )
                    } );

                    setTags( [] );

                    invoke( props, 'onShareSuccess', resp );
                }
                catch ( err ) {
                    handleRequestError( err );
                }
                finally {
                    setIsProcessing( false );
                }
            }

            return false;
        }
    } );

    useEffect( async () => {
        if ( notEmptyOrNil( props.assets ) ) {
            const url = await getCopyUrl( props.assets );
            setShareUrl( url );
        }
    }, [props.assets] )

    useEffect( () => {
        let submitDisabled = false;
        if (
            notEmptyOrNil( formik.errors )
            || (!tags.length && !query)
            || !hasValidInput( query )
            || hasInvalidTag( tags ) ) {
            submitDisabled = true;
        }

        setIsSubmitDisabled( submitDisabled );
    }, [tags, query, formik.errors] );

    const copyShareLinkToClipboard = async () => {
        if ( isCopyingLink ) {
            return false;
        }

        setIsCopyingLink( true );

        try {
            await navigator.clipboard.writeText( shareUrl );
            invoke( props, 'onCopyLinkSuccess', shareUrl );
        }
        catch ( err ) {
            console.error( 'Failed to copy:', err );
        }
        finally {
            setIsCopyingLink( false );
        }

        return false;
    };

    const onAddTag = ( tag ) => {
        const sName = sanitizeTagString( tag.name );
        const newTag = {
            id: sName,
            name: sName,
            isValid: validateEmail( sName )
        };

        setTags( list => uniqList( [...list, newTag] ) );
        setQuery( '' );
    };

    const onRemoveTag = ( index ) => {
        setTags( tagList => {
            const list = tagList.slice( 0 );
            list.splice( index, 1 );
            return list;
        } );
    };

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            parseRequestErrorResponse( err.response.data );
            return;
        }
        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function parseRequestErrorResponse ( err ) {
        switch ( err.code ) {
            case ErrorCodes.Validation:
                handleValidationError( err );
                break;

            case ErrorCodes.Invitation:
                handleSubmissionError( err );
                break;

            default:
                setFormPostError( {
                    message: ErrorMessages.UnknownError
                } );
        }
    }

    function handleValidationError ( err ) {
        const _tags = tags.concat();
        const invalidValues = err.data.map( e => e.value );

        _tags.forEach( tag => {
            if ( invalidValues.includes( tag.name ) ) {
                tag.isValid = false;
            }
        } );

        setFormPostError( {
            message: err.message,
            errorList: err.data.map( ( {value, issue} ) => `${value} ${issue}` )
        } );
        setTags( _tags );
    }

    function handleSubmissionError ( err ) {
        const successfulInvites = [];
        const failedInvites = [];

        const invalidValues = err.data.map( e => e.value );
        tags.forEach( tag => {
            const ctx = invalidValues.includes( tag.name ) ? failedInvites : successfulInvites;
            ctx.push( tag );
        } );

        setFormPostError( {
            // message: err.message,
            customMessage: (
                <div className="text-near-black">
                    {successfulInvites.length > 0 &&
                        <div className="p-4 mb-2 bg-success-lightest">
                        <span className="flex items-center text-success text-sm font-bold mb-1">
                            <Checkmark width="16" height="16" className="mr-1"/> {t( 'label.succeeded',
                            _.label.succeeded )}
                        </span>
                            {successfulInvites.map( tag =>
                                <div key={tag.name} className="font-medium">{tag.name}</div>
                            )}
                    </div>
                    }
                    <div className="p-4 bg-fail-lightest">
                        <span className="flex items-center text-fail text-sm font-bold mb-1">
                            <Cross width="16" height="16" className="mr-1"/> {t( 'label.failed', _.label.failed )}
                        </span>
                        <p className="text-sm font-medium leading-snug">{err.message}</p>
                        {failedInvites.map( tag =>
                            <div key={tag.name} className="font-medium">{tag.name}</div>
                        )}
                    </div>
                </div>
            )
        } );
        setTags( failedInvites );
    }

    return (
        <Modal
            id="asset-sharing-dialog"
            title={t(
                'assetSharing.label.shareFormTitle',
                _.assetSharing.label.shareFormTitle,
                {count: props.assets.length}
            )}
            size="md"
            className={'z-10'}
            onClose={() => {
                setFormPostError( null );
                props.onClose();
            }}
            isOpen={props.isOpen}>

            <FormikProvider value={formik}>
                <form data-testid="ShareAsset.Modal" onSubmit={formik.handleSubmit} className="mb-6">
                    {formPostError &&
                        <FormPostError {...formPostError} />
                    }
                    <ul className="list-reset mb-6 grid grid-cols-2 gap-2">
                        {props.assets.map( asset =>
                            <li key={asset.id} className="text-sm leading-tight text-grey-dark flex items-center mb-1">
                                <FormatGeneric width="20" className="mr-2 flex-none"/> {asset.name}
                            </li>
                        )}
                    </ul>

                    <h3 className="subheading-base">{t( 'label.emailAddress', _.label.emailAddress )}*</h3>
                    <label htmlFor="invitelist">{t( 'label.acceptMultipleEmail', _.label.acceptMultipleEmail )}</label>
                    <div
                        className="input-group items-center flex md:flex-nowrap w-full p-1 border border-solid border-grey-lighter rounded">
                        <TokenInput
                            ref={reactTags}
                            id="asset-sharing"
                            classNames={tokenInputClassNames}
                            name="invitelist"
                            placeholderText={t( 'label.emailAddressHint', _.label.emailAddressHint )}
                            allowNew={true}
                            autoresize={false}
                            addOnBlur={true}
                            delimiters={['Enter', ',']}
                            tags={tags}
                            tagComponent={Token}
                            onInput={setQuery}
                            onAddition={onAddTag}
                            onDelete={onRemoveTag}
                        />
                        <Button className="button w-full md:w-auto md:ml-1"
                                type="submit"
                                loading={isProcessing}
                                disabled={isSubmitDisabled || isProcessing}>
                            {isProcessing
                                ? t( 'actionLabel.sending', _.actionLabel.sending )
                                : t( 'actionLabel.share', _.actionLabel.share )}
                        </Button>
                </div>
                    {hasInvalidTag( tags ) &&
                        <p className="text-xs font-semibold text-fail leading-tight">
                            {t(
                                'assetSharing.notify.oneOrMoreInvalidEmailAddresses',
                                _.assetSharing.notify.oneOrMoreInvalidEmailAddresses
                            )}
                        </p>
                    }
                </form>
            </FormikProvider>

            <Show when={!!navigator.clipboard && shareUrl}>
                <div data-testid="ShareAsset.CopyLink">
                    <h3 className="subheading-base">
                        {t( 'assetSharing.label.shareLink', _.assetSharing.label.shareLink )}
                    </h3>
                    <div
                        className="input-group items-center flex md:flex-nowrap w-full p-1 bg-moon-grey rounded">
                        <input type="text"
                               defaultValue={shareUrl}
                               className="ghosted bg-none mr-2"
                               onClick={e => e.currentTarget.select()}/>
                        <Button
                            data-testid="ShareAsset.CopyLink.Action"
                            as="div"
                            tertiary
                            small
                            className="cursor-pointer"
                            loading={isCopyingLink}
                            onClick={copyShareLinkToClipboard}>
                            {t( 'actionLabel.copyLink', _.actionLabel.copyLink )}
                        </Button>
                    </div>
                </div>
            </Show>
        </Modal>
    );
}

ShareAsset.defaultProps = {
    isOpen: false,
    onClose: () => {},
    tags: [],
    assets: [],
    availableFeatures: []
}
