import {atomFamily, selectorFamily, useRecoilValueLoadable} from 'recoil';
import {notify} from '@/modules/notifier';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import BookmarksRepository from './repository';
import bookmarksStore from './store'
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const repository = new BookmarksRepository( bookmarksStore );

const syncStorageEffect = storeKey => ( {setSelf, trigger} ) => {
    // initialize
    if ( trigger === 'get' ) { // Avoid expensive initialization
        setSelf( repository.getBookmarks( storeKey ) );
    }
    // subscribe
    const unsubscribe = repository.subscribe( data => setSelf( data ) );

    // cleanup
    return () => unsubscribe()
}

const bookmarksState = atomFamily( {
    key: 'bookmarks',
    default: {},
    // note -> I think this may be triggering error:
    //  Using UNSAFE_componentWillMount in strict mode - Please update the following components: SideEffect(NullComponent)
    //  This may be resolved with future versions of Recoil
    effects_UNSTABLE: ( storeKey ) => [
        syncStorageEffect( storeKey )
    ]
} );

export const bookmarkSelector = selectorFamily( {
    key: 'bookmarkSelector',
    get: ( storeKey ) => ( {get} ) => {
        const bookmarks = get( bookmarksState( storeKey ) );

        return {
            hasItems: notEmptyOrNil( bookmarks ),
            assetIds: notEmptyOrNil( bookmarks ) ? bookmarks.map( i => i.id ) : []
        };
    }
} );

function getBookmarkContents ( {state, contents} ) {
    if ( state === 'hasValue' && Array.isArray( contents ) ) {
        return contents;
    }

    return [];
}

export default function useBookmarks ( storeKey ) {
    const STORE_KEY = storeKey;
    const bookmarksLoadable = useRecoilValueLoadable( bookmarksState( STORE_KEY ) );
    const bookmarksStatusLoadable = useRecoilValueLoadable( bookmarkSelector( STORE_KEY ) );

    const onClearBookmarks = () => {
        repository.clearBookmarks( STORE_KEY );
    };

    const onBookmarkAll = items => async () => {
        const bookmarks = getBookmarkContents( bookmarksLoadable );
        try {
            await repository.bookmarkAll( {
                key: STORE_KEY,
                bookmarks,
                items
            } );
        }
        catch ( err ) {
            notify.error( t(
                'bookmarks.notify.createBookmarkFailed',
                _.bookmarks.notify.createBookmarkFailed
            ) );
        }
    };

    const onAddBookmarkItem = ( item ) => async () => {
        const bookmarks = getBookmarkContents( bookmarksLoadable );
        try {
            await repository.addBookmark( {
                key: STORE_KEY,
                bookmarks,
                item
            } );
        }
        catch ( err ) {
            notify.error( t(
                'bookmarks.notify.createBookmarkFailed',
                _.bookmarks.notify.createBookmarkFailed
            ) );
        }
    };

    const onRemoveBookmarkItem = ( item ) => async () => {
        const bookmarks = getBookmarkContents( bookmarksLoadable );
        try {
            await repository.removeBookmark( {
                key: STORE_KEY,
                bookmarks,
                item
            } );
        }
        catch ( err ) {
            notify.error( t(
                'bookmarks.notify.removeBookmarkFailed',
                _.bookmarks.notify.removeBookmarkFailed
            ) );
        }
    };

    const bookmarksStatus = bookmarksStatusLoadable.state === 'hasValue' ?
        bookmarksStatusLoadable.contents :
        {hasItems: false, assetIds: []};

    const isBookmarked = ( {id} ) => bookmarksStatus.assetIds.indexOf( id ) !== -1;

    return {
        loading: bookmarksLoadable.state === 'loading',
        error: bookmarksLoadable.state === 'hasError' ? bookmarksLoadable.contents : null,
        bookmarks: getBookmarkContents( bookmarksLoadable ),
        bookmarksStatus,
        isBookmarked,
        onClearBookmarks,
        onBookmarkAll,
        onAddBookmarkItem,
        onRemoveBookmarkItem
    };
}
