import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {useMemo} from 'react';

const PhotoViewer = ( {imgSrcList} ) => {

    return (
        <PhotoProvider>
            {imgSrcList.map( ( {src, altText} ) =>
                <PhotoView src={`${src}/m/`}>
                    <picture
                        className="shadow-lg img-ratio img-ratio-1x1 cursor-pointer hover:opacity-80 t-opacity t-duration-short">
                        <source data-srcset={`${src}/m/750x750/smart" media="(min-resolution: 2dppx)`} sizes="750px"/>
                        <source data-srcset={`${src}/m/375x375/smart`} sizes="375px"/>
                        <img data-sizes="auto"
                             data-src={`${src}/m/375x375/smart`}
                             className="fade-up lazyload"
                             alt={altText}
                             sizes="375px"/>
                    </picture>
                </PhotoView>
            )}
        </PhotoProvider>
    )
}

export default PhotoViewer;
