import React from 'react';
import {AppRouter} from './router';
import {ProductAnswersAppContext} from '@/apps/ProductAnswers/appContext';
import {HashRouter as Router} from 'react-router-dom';
import usePageModel from '@/containers/PageModel/usePageModel';

function App () {
    const pageModelApi = usePageModel( 'productanswerlandingpage' );

    return (
        <ProductAnswersAppContext.Provider value={{pageModelApi}}>
            <Router basename={'/'}>
                <AppRouter/>
            </Router>
        </ProductAnswersAppContext.Provider>
    );
}

export default App;
