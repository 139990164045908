import {ServiceProvider} from '@/providers/provider';
import {anyEmptyOrNil, isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import props from 'ramda/src/props';
import env from '@env';

const AVG_ASSET_SIZE = 5e+6; // 5MB
const BASE_URL = window.location.hostname.startsWith( 'localhost' )
    ? 'https://mvdataappstorageuscaprod.blob.core.windows.net' // patch - local download service to bypass CORS issues
    : env.CDN_ORIGIN;

// fixme - this is a temporary solution to bypass CORS issues with the CDN
//  once verified auth/anon alt services can be removed and applicable code using this api can be refactored to remove this switch
const svc = new ServiceProvider( {
    baseURL: BASE_URL,
    options: {
        useAccessToken: false
    }
} );

const anonsvc = new ServiceProvider( {
    baseURL: BASE_URL,
    options: {
        useAccessToken: false
    }
} );

function getValidDownloadUrl ( sizes, requestSize ) {
    const match = sizes?.find( ( {alias} ) => alias.toLowerCase() === requestSize );
    if ( !match ) {
        console.error( `getValidDownloadUrl: No match found for requested size: ${requestSize}, responding with "Original" instead` );
        return sizes?.find( ( {alias} ) => alias.toLowerCase() === 'original' )?.url;
    }

    return match?.url;
}

function aggregateProgress ( progress ) {
    return progress.reduce( ( acc, next ) => {
        acc.loaded += next.loaded;
        acc.total += next.total;

        return acc;
    }, {loaded: 0, total: 0} );
}

/**
 *
 * @param assets Array [{id, scheme, sizes}]
 * @param sizeAlias String - one of ['original','large'], requested asset size alias from client
 * @param onDownloadProgress Function ({loaded, total }) => {}
 * @returns {Promise<AssetData[]>}
 * @example
 * {
 *      "assets": [
 *          {
 *              "id": "123",
 *              "name": "foo",
 *              scheme: "JPG",
 *              sizes: [
 *                  {
 *                      "alias": "Original",
 *                      "fileSize": 1439213,
 *                      "width": 1920,
 *                      "height": 1080,
 *                      "resolution": 0
 *                  }
 *              ]
 *          }
 *      ],
 *      onDownloadProgress: ({loaded, total }) => {}
 * }
 */
function downloadAssetList ( {assets, sizeAlias, onDownloadProgress} = {} ) {
    if ( isEmptyOrNil( assets ) ) {
        throw new TypeError( '[downloadAsset Error] Missing required param "assets"' );
    }

    // precalculate each asset assuming avg size (replaced with actual size once stream begins)
    const totalProgress = (Array( assets.length )).fill( {loaded: 0, total: AVG_ASSET_SIZE} );

    const downloadList = assets.map( ( {id, scheme, sizes}, index ) => {
        const downloadUrl = getValidDownloadUrl( sizes, sizeAlias );
        const requestConfig = {
            responseType: 'blob',
            timeout: 0,
            onDownloadProgress: progress => {
                if ( progress.lengthComputable ) {
                    totalProgress[index] = progress;
                }
                onDownloadProgress( aggregateProgress( totalProgress ) );
            }
        };

        return this.xhr.get( downloadUrl, requestConfig );
    } );

    return Promise.all( downloadList );
}

/**
 *
 * @param id String
 * @param scheme String
 * @param onDownloadProgress Function ({loaded, total }) => {}
 * @returns Promise
 * @example
 * {
 *     "scheme" : "image",
 *     "id" : "123ABDEE32423,
 *     onDownloadProgress: ({loaded, total }) => {}
 * }
 */
function downloadAsset ( {asset, sizeAlias, onDownloadProgress} = {} ) {
    const [id, scheme, sizes] = props( ['id', 'scheme', 'sizes'], asset );

    if ( anyEmptyOrNil( [id, scheme] ) ) {
        throw new TypeError( '[downloadAsset Error] Missing required param "id" or "scheme"' );
    }

    const downloadUrl = getValidDownloadUrl( sizes, sizeAlias );
    const requestConfig = {
        responseType: 'blob',
        timeout: 0,
        onDownloadProgress
    }

    return this.xhr.get( downloadUrl, requestConfig );
}

svc.downloadAsset = downloadAsset;
svc.downloadAssetList = downloadAssetList;
anonsvc.downloadAsset = downloadAsset;
anonsvc.downloadAssetList = downloadAssetList;

export const AssetDownloadProvider = svc.xhr;

svc.anon = anonsvc;

export default svc;
