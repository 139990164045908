import {AccordionItem} from '@ultradent/components';

export const FAQSection = ( {title, list = []} ) =>
    <section className="max-w-lg mx-auto py-12 my-16 md:py-24 md:my-32">
        <div className="contain-content pad-content">
            <h3 className="headline-lg mb-12 text-center">{title}</h3>
            {list?.map( ( {question, answer}, index ) =>
                <AccordionItem
                    key={`faqItem.${index}`}
                    label={question}
                    className="leading-snug border-b border-solid border-grey-lighter"
                    headingClassName="subheading-lg font-semibold py-4 leading-tight">
                    <p className="pt-2 pb-6 pl-12 text-body txt-c2">{answer}</p>
                </AccordionItem>
            )}
        </div>
    </section>

