import {useContext} from 'react';
import {BookmarkAdd, BookmarkFilled, CheckmarkFilled, SelectionGroupAdd} from '@/components/common/Icons';
import cx from 'classnames';
import {AssetContext} from '@/apps/ResourceCenter/assetContext';
import {ContextMenu, t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {useAssetSharing} from '@/containers/AssetSharing/useAssetSharing';

const ADD_SELECTION = t( 'menu.addSelection', _.menu.addSelection );
const REMOVE_SELECTION = t( 'menu.removeSelection', _.menu.removeSelection );
const ADD_BOOKMARK = t( 'menu.addBookmark', _.menu.addBookmark );
const REMOVE_BOOKMARK = t( 'menu.removeBookmark', _.menu.removeBookmark );
const SHARE = t( 'menu.share', _.menu.share );
const DOWNLOAD = t( 'menu.download', _.menu.download );

const MenuContent = ( {isSelected, isBookmarked, onDownloadClick, toggleSelection, toggleBookmark, onShare} ) => {

    const clipboardActionLabel = isSelected ? REMOVE_SELECTION  : ADD_SELECTION;
    const bookmarkActionLabel = isBookmarked ? REMOVE_BOOKMARK : ADD_BOOKMARK;
    const shareActionLabel = SHARE;
    const downloadActionLablel = DOWNLOAD;

    return (
        <div className="flex flex-col w-48 text-base" data-testid="AssetActions.ContextMenu">
            <button className="menu-context--item" onClick={toggleSelection} aria-label={clipboardActionLabel}>
                {clipboardActionLabel}
            </button>
            <button className="menu-context--item" onClick={toggleBookmark} aria-label={bookmarkActionLabel}>
                {bookmarkActionLabel}
            </button>
            <button className="menu-context--item" onClick={onShare} aria-label={shareActionLabel}>
                {shareActionLabel}
            </button>
            <button className="menu-context--item" onClick={onDownloadClick} aria-label={downloadActionLablel}>
                {downloadActionLablel}
            </button>
        </div>
    );
}

const actionDisplayClasses = 'm-px p-1 rounded bg-eggshell-white';
const actionHoverClasses = 'lg:invisible group-hover:visible active:visible focus:visible';
const displayClasses = 'flex flex-col justify-start';
export const AssetActions = ( {isSelected, isBookmarked, onDownloadClick, className} ) => {

    const {assetData, clipboardApi, bookmarksApi} = useContext( AssetContext );
    const {onShareAssets} = useAssetSharing();

    const containerClasses = cx(
        'absolute top-1 right-1 z-10',
        displayClasses,
        className
    );

    return (
        <div className={containerClasses}>
            {isSelected
                ?
                <Tooltip content={REMOVE_SELECTION}>
                    <button aria-label={REMOVE_SELECTION}
                            onClick={clipboardApi.onRemoveClipboardItem( assetData )}
                            className={cx( actionDisplayClasses, 'link-2 visible outline-none' )}>
                        <CheckmarkFilled width="20"/>
                    </button>
                </Tooltip>
                :
                <Tooltip content={ADD_SELECTION}>
                    <button aria-label={ADD_SELECTION}
                            onClick={clipboardApi.onAddClipboardItem( assetData )}
                            className={cx(
                                actionDisplayClasses,
                                {[actionHoverClasses]: !clipboardApi.clipboardStatus.hasItems},
                                'hover:link-2 outline-none'
                            )}>
                        <SelectionGroupAdd width="20"/>
                    </button>
                </Tooltip>
            }
            {isBookmarked
                ? <Tooltip content={REMOVE_BOOKMARK}>
                    <button aria-label={REMOVE_BOOKMARK}
                            className={cx( actionDisplayClasses, actionHoverClasses, 'link-2' )}
                            onClick={bookmarksApi.onRemoveBookmarkItem( assetData )}>
                        <BookmarkFilled width="20"/>
                    </button>
                </Tooltip>
                : <Tooltip content={ADD_BOOKMARK}>
                    <button aria-label={ADD_BOOKMARK}
                            className={cx( actionDisplayClasses, actionHoverClasses )}
                            onClick={bookmarksApi.onAddBookmarkItem( assetData )}>
                        <BookmarkAdd width="20"/>
                    </button>
                </Tooltip>
            }

            <ContextMenu className={cx( actionDisplayClasses, actionHoverClasses )}>
                <MenuContent
                    isSelected={isSelected}
                    isBookmarked={isBookmarked}
                    onDownloadClick={onDownloadClick}
                    onShare={onShareAssets( assetData )}
                    toggleSelection={isSelected
                        ? clipboardApi.onRemoveClipboardItem( assetData )
                        : clipboardApi.onAddClipboardItem( assetData )}
                    toggleBookmark={isBookmarked
                        ? bookmarksApi.onRemoveBookmarkItem( assetData )
                        : bookmarksApi.onAddBookmarkItem( assetData )}
                />
            </ContextMenu>
        </div>
    );
}
