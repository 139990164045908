import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

class EventRegistrantRepository {
    constructor ( store ) {
        this.eventRegistrants = store;
    }

    subscribe ( fn ) {
        return this.eventRegistrants.subscribe( fn );
    }

    getStoreKey () {
        return this.eventRegistrants.storeKey;
    }

    getRegistrants ( eventId ) {
        const registrants = this.eventRegistrants.getStore( eventId );
        return registrants || [];
    }

    addRegistrant ( {eventId, registrant} ) {
        const state = this.getRegistrants( eventId );
        const nextState = [...state, registrant];
        this.eventRegistrants.setStore( eventId, nextState );

        return nextState;
    }

    updateRegistrant ( {eventId, registrants, registrant} ) {
        const foundIndex = registrants.findIndex( e => e.id === registrant.id );
        const nextState = [...registrants];

        if ( foundIndex !== -1 ) {
            // only update store if item was found
            nextState.splice( foundIndex, 1, registrant );
            // local store
            this.eventRegistrants.setStore( eventId, nextState );
        }

        return nextState;
    }

    removeRegistrant ( {eventId, registrants, registrantId} ) {
        let nextState = [...registrants];
        const foundIndex = registrants.findIndex( e => e.id === registrantId );

        if ( foundIndex !== -1 ) {
            // only update store if item was found
            nextState = [
                ...registrants.slice( 0, foundIndex ),
                ...registrants.slice( foundIndex + 1 )
            ];
            // local store
            this.eventRegistrants.setStore( eventId, nextState );
        }

        return nextState;
    }

    unloadRegistrantList ( eventId ) {
        this.eventRegistrants.unloadStoreItem( eventId );
    }

    clearRegistrants ( eventId ) {
        this.eventRegistrants.clearStoreItem( eventId );
    }
}

export default EventRegistrantRepository;
