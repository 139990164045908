// resource - https://javascript.info/custom-errors
import {notify} from '@/modules/notifier';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const CONTACT_CUSTOMER_SERVICE = t( 'message.contactCustomerServiceIfPersists',
    _.message.contactCustomerServiceIfPersists );
const FAILED_PAGE_LOAD = t( 'message.pageLoadErrorWithCustomerService', _.message.pageLoadErrorWithCustomerService );
const FAILED_TIMEOUT_ERROR = t( 'notify.requestTimeoutError', _.notify.requestTimeoutError );
const FAILED_AUTHORIZATION = t( 'notify.authorizationFailed', _.notify.authorizationFailed );
const FAILED_API_UNKNOWN = t( 'notify.requestFailUnknownError', _.notify.requestFailUnknownError );
const FAILED_NETWORK_ERROR = t( 'notify.networkFailed', _.notify.networkFailed );
const FAILED_DOWNLOAD_ERROR = t( 'notify.downloadFailed', _.notify.downloadFailed );

export const ErrorCodes = {
    Validation: 'ValidationError',
    ResourceStale: 'ResourceStale',
    Server: 'ServerError',
    AccountAlreadyExists: 'AccountAlreadyExists',
    Authentication: 'AuthenticationError',
    Invitation: 'InvitationError',
    InvitationUserExistsOtherAccount: 'InvitationUserExistsOtherAccountError',
    Unknown: 'UnknownError'
}

export const ErrorMessages = {
    UnknownError: `${FAILED_API_UNKNOWN} ${CONTACT_CUSTOMER_SERVICE}`,
    PageLoadError: `${FAILED_PAGE_LOAD} ${CONTACT_CUSTOMER_SERVICE}`,
    NotAuthorized: FAILED_AUTHORIZATION,
    NetworkError: FAILED_NETWORK_ERROR,
    DownloadError: `${FAILED_DOWNLOAD_ERROR} ${CONTACT_CUSTOMER_SERVICE}`
}

class CustomError extends Error {
    constructor ( message ) {
        super( message );
        this.name = this.constructor.name;
    }
}

export class UnknownError extends CustomError {
    constructor () {
        super( ErrorMessages.UnknownError );
    }
}

export class ValidationError extends CustomError {}

export class TokenRequestError extends CustomError {
    constructor ( message = 'Unknown error' ) {
        super( `Failed to retrieve access tokens: ${message}` );
    }
}

export class NotAuthorized extends CustomError {}

export class MalformedRequest extends ValidationError {
    constructor ( property, message = 'undefined or unexpected type' ) {
        super( `Invalid or missing param: ${property} ${message}` );
        this.property = property;
    }
}

export function parseGeneralRequestError ( err, options = {} ) {
    let message = ErrorMessages.UnknownError;
    let retryMessage = t( 'message.dismissAndRetry', _.message.dismissAndRetry );

    // check for known errors such as network and timeout
    // otherwise throw UNKNOWN
    if ( /timeout/i.test( err.message ) ) {
        message = FAILED_TIMEOUT_ERROR;
    }
    if ( /network/i.test( err.message ) ) {
        message = FAILED_NETWORK_ERROR;
    }

    const notif = notify.error( message + (options.onRetry ? ` ${retryMessage}` : '') );

    if ( options.onRetry ) {
        // retry on dialog click
        notif.on( 'click', () => {
            // dismiss error on retry
            notify.dismiss( notif );
            options.onRetry();
        } );
        // OR retry when dialog is "dismissed"
        notif.on( 'dismiss', options.onRetry );
    }
}

export function getErrorList ( errorData ) {
    if ( Array.isArray( errorData ) ) {
        return errorData?.map( ( {value, issue} ) => `${value} - ${issue}` )
    }

    return errorData ? [`${errorData?.value} - ${errorData?.issue}`] : [];
}

export function getInvalidPostValues ( errorData ) {
    if ( Array.isArray( errorData ) ) {
        return errorData?.map( ( {value} ) => value )
    }

    return errorData ? [errorData?.value] : [];
}

