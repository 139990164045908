import cx from 'classnames';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {Link} from 'react-router-dom';
import {toLegibleStringList} from  '@ultradent/utilities/Strings';
import {SkeletonElement} from '@ultradent/components';

const MediaTile = ( {
                        id,
                        src,
                        url,
                        title,
                        categories,
                        overline,
                        children,
                        className
                    } ) =>
    <div className={cx( 'leading-tight text-grey-darker', className )} data-asset-id={id}>
        <div className="mb-2 max-w-md">
            <Link to={url} className="link-hover">
                <img className="bg-near-white lazyload fade-up" alt={title} data-src={src}/>
            </Link>
        </div>
        <h3 className="subheading-lg mb-2">
            <Link to={url} className="text-near-black link-hover">
                {title}
            </Link>
        </h3>
        {notEmptyOrNil( categories?.items ) &&
        <p className="text-subtitle mt-0 mb-3">{toLegibleStringList( categories.items )}</p>
        }
        {children}
    </div>;

MediaTile.defaultProps = {
    categories: []
};
MediaTile.Skeleton = () => <SkeletonElement.ArticleTile/>

export {MediaTile}
