import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/',
    mockSrc: process.env.MOCK_API && 'generalData',
    options: {
        useAccessToken: false
    }
} );

svc.getCountryCodes = function getCountryCodes ( eventId ) {
    return svc.xhr.get( `getcountrycodes`, {params: {id: eventId}} );
}

export const GeneralDataProvider = svc.xhr;

export default svc;
