import {BookmarkAdd} from '@/components/common/Icons';
import {Button, t} from '@ultradent/components';
import React from 'react';
import {translationKeys as _} from '@/constants/translations';

export const BookmarksEmpty = () =>
    <div
        className="max-w-lg mx-auto p-12 text-grey-darker text-center border-2 border-dashed border-grey-lightest rounded-lg">
        <h3 className="subheading-lg mb-4">
                {t(
                    'bookmarks.message.emptyList.heading',
                    _.bookmarks.message.emptyList.heading
                )}
        </h3>
        <p className="mb-8 max-w-sm mx-auto">
            {t(
                'bookmarks.message.emptyList.body1',
                _.bookmarks.message.emptyList.body1,
                {
                    postProcess: [`reactPostprocessor`],
                    componentIcon: (<BookmarkAdd width="24" className="inline-block text-blue"/>)
                }
            )}
        </p>
        <Button href="/resources" large primary gradient>
            {t( 'bookmarks.actionLabel.browseResources', _.bookmarks.actionLabel.browseResources )}
        </Button>
    </div>;
