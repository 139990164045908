import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import React from 'react';
import {AssetSharingItem} from '@/containers/AssetSharing/AssetSharingItem';
import {CalendarTime} from '@/components/common/Icons';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const SKELETON_COUNT = Array( 8 ).fill( null );

const AssetSharingEmpty = () =>
    <div className="max-w-xl mx-auto p-12 text-center">
        <CalendarTime width="96" className="my-4 inline-block text-grey-dark"/>
        <h3 className="headline-base mb-4">{t(
            'assetSharing.message.resourcesHaveExpired.heading',
            _.assetSharing.message.resourcesHaveExpired.heading
        )}</h3>
        <p className="text-body">
            {t(
                'assetSharing.message.resourcesHaveExpired.body1',
                _.assetSharing.message.resourcesHaveExpired.body1
            )}

        </p>
    </div>;

export const ListDetail = ( {sharedAssets, itemRenderer} ) =>
    <section className="relative bg-moon-grey pt-10 pb-32">
        <div className="contain-content pad-content mb-16">
            {isEmptyOrNil( sharedAssets?.all )
                ?
                <AssetSharingEmpty/>
                :
                <div className="fade-in w-full grid grid-cols-3 lg:grid-cols-4 gap-1 md:gap-4">
                    {sharedAssets?.all.map( itemProps => {
                        if ( itemProps.valid ) {
                            return React.createElement( itemRenderer, {key: itemProps.id, ...itemProps} );
                        }

                        return <AssetSharingItem.Ivalidated
                            key={itemProps.id}
                            id={itemProps.id}
                            name={itemProps.name}/>
                    } )}
                </div>
            }
        </div>
    </section>

ListDetail.Skeleton = () =>
    <section className="relative bg-moon-grey pt-10 pb-32">
        <div className="contain-content pad-content mb-16">
            <div className="fade-in w-full grid grid-cols-3 lg:grid-cols-4 gap-1 md:gap-4">
            {SKELETON_COUNT.map( () => <AssetSharingItem.Skeleton/> )}
            </div>
        </div>
    </section>
