import {FacetedSearchRouter} from '@/containers/FacetedSearch';
import {SearchQuery} from './Pages/SearchPage';
import {StudiesQuery} from './Pages/StudiesPage';

export const routes = [
    {
        path: '/search',
        exact: false,
        main: SearchQuery
    },
    {
        path: '/',
        exact: false,
        main: StudiesQuery
    }
];

export const AppRouter = () => {
    return <FacetedSearchRouter routes={routes}/>;
}
