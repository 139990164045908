import {SkeletonElement, t, TruncatedList} from '@ultradent/components';
import cx from 'classnames';
import {translationKeys as _} from '@/constants/translations';

const CategoryLine = ( {id, parentId, name, isSelected, className, onClick = () => {}, hasParent = false} ) => {
    if ( isSelected ) {
        return <div className={cx( className, {'ml-4': hasParent} )}>{name}</div>;
    }

    return (
        <div className={cx( className, {'ml-4': hasParent} )}>
            <span className="link-2 hover:underline"
                  onClick={() => onClick( [parentId, id].filter( Boolean ) )}>{name}</span>
        </div>
    );
}

const CategoryList = ( {
                           list = [],
                           maxLength = 8,
                           className,
                           onLineClick
                       } ) =>
    <section className={cx( className )}>
        <h2 className="subheading-sm mb-2">{t( 'search.label.category', _.search.label.category )}</h2>
        <nav className="m-0 p-0 text-sm leading-relaxed">
            <TruncatedList renderer={CategoryLine}
                           itemProps={{onClick: onLineClick}}
                           list={list}
                           maxLength={maxLength}/>
        </nav>
    </section>;

const Skeleton = ( {className} ) =>
    <section className={cx( className )}>
        <SkeletonElement type="title" className="mb-8"/>
        <SkeletonElement.NavListVertical/>
    </section>;

CategoryList.Skeleton = Skeleton;

export {CategoryList, CategoryLine};
