import {Link} from 'react-router-dom';
import cx from 'classnames';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import PropTypes from 'prop-types';

const baseStyles = 'grid grid-cols-2 md:grid-cols-6 gap-4 lg:gap-8 text-body-sm';

const LinkGroup = ( {children, className} ) =>
    <ul className={cx( 'm-0 p-0', className )}>{children}</ul>;

const LinkItem = ( {path = '', className, children} ) =>
    <li>
        <Link className={cx( 'mb-2 leading-tight inline-block hover:link-2', className )}
              to={`/search?keyword=&${path}`}>{children}</Link>
    </li>;

export const CategoryLinkList = ( {taxonomy, className} ) => {
    if ( notEmptyOrNil( taxonomy ) ) {
        return (
            <nav data-testid="CategoryLinkList" className={cx( baseStyles, className )}>
                {taxonomy.map( x =>
                    <LinkGroup key={x.Id}>
                        <LinkItem className="text-title" path={`AssetCategory=${encodeURIComponent( x.Name )}`}>
                            {x.Name}
                        </LinkItem>

                        {notEmptyOrNil( x.Children ) &&
                        x.Children.map( y =>
                            <LinkItem key={y.Id}
                                      path={`AssetCategory=${encodeURIComponent( x.Name )}&AssetType=${encodeURIComponent(
                                          y.Name )}`}>
                                {y.Name}
                            </LinkItem>
                        )
                        }
                    </LinkGroup>
                )}
            </nav>
        );
    }

    return null;
};

CategoryLinkList.defaultProps = {
    taxonomy: []
};

CategoryLinkList.propTypes = {
    taxonomy: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.array
    } ) )
};
