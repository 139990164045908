console.warn( '[Mocking] "account" api' );

export default function ( instance ) {
    instance
        .onPost( '/createAccount' )
        .reply( () => ([
            200,
            {}
        ]) );

    instance
        .onPost( '/updateAccount' )
        .reply( () => ([
            200,
            {}
        ]) );

    instance
        .onGet( '/options' )
        .reply( () => ([
            200,
            mockOptionsResponse
        ]) )
}


export const mockOptionsResponse = {
    'regions': [
        {'name': 'North & East Europe', 'code': '038e8e03-58b7-eb11-8236-000d3adc15df'},
        {'name': 'Canada', 'code': '1c55ffb3-56b7-eb11-8236-000d3adc15df'},
        {'name': 'China', 'code': '3b59ff72-4197-e811-8148-e0071b6641b1'},
        {'name': 'Japan', 'code': '811c0695-8649-e811-812f-e0071b6641b1'},
        {'name': 'Brazil', 'code': '45dc5538-6625-ed11-b83d-0022489e50d3'}],
        'accountTypes': [
        {'name': 'Dental Office', 'code': '925140001'},
        {'name': 'Dealer', 'code': '925140000'},
        {'name': 'Distributor', 'code': '925140003'},
        {'name': 'Large Group', 'code': '925140004'},
        {'name': 'University', 'code': '925140005'}],
        'salesReps': [
        {'name': 'Jose Gomez', 'code': '5be722e4-58b7-eb11-8236-000d3ab0664a'},
        {'name': 'Enis Cetin', 'code': '55e722e4-58b7-eb11-8236-000d3ab0664a'},
        {'name': 'Julien Cachet', 'code': '60e722e4-58b7-eb11-8236-000d3ab0664a'},
        {'name': 'Anja Holler', 'code': '4de722e4-58b7-eb11-8236-000d3ab0664a'},
        {'name': 'Mario Osvald', 'code': '68e722e4-58b7-eb11-8236-000d3ab0664a'}]
};
