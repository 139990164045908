import React, {useMemo} from 'react'
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {Show, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';
import {toFileSize} from '@ultradent/utilities/Strings';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

export const PreviewPane = ( {children} ) =>
    <div className="max-w-lg mx-auto mb-8">{children}</div>;

export const DetailPane = ( {children, className} ) =>
    <div className={cx( 'md:min-w-md xl:min-w-lg', className )}>{children}</div>;

export const ActionBar = ( {children, className} ) =>
    <div className={cx( 'flex items-center mb-4 justify-end flex-wrap', className )}>{children}</div>;

export const Taglist = ( {tags = [], className} ) =>
    tags.map( tag => <Link key={tag} className={cx( 'tag text-grey-darker hover:link-2 mr-2 mb-2 truncate', className )}
                         to={`/search?keyword=&Tag=${encodeURIComponent( tag )}`}>{tag}</Link> );

export const AssetFileSizeMeta = ( {sizes = [], fileExtension} ) => {
    const {resolutions, dimensions, fileSizes} = useMemo( () => {
        const meta = {
            resolutions: [],
            dimensions: [],
            fileSizes: []
        };

        if ( isEmptyOrNil( sizes ) ) {
            return meta;
        }

        return sizes.reduce( ( acc, size ) => {
            acc.resolutions.push( size.Resolution );
            acc.dimensions.push( {
                width: size.Width,
                height: size.Height
            } );
            acc.fileSizes.push( size.FileSize );
            return acc;
        }, meta );
    }, [] );

    if ( isEmptyOrNil( sizes ) ) {
        return null;
    }

    return (
        <div className="flex">
            <div className="mb-4 mr-5 md:mr-8">
                <dt className="text-sm text-grey-darker mb-1">
                    {t( 'assetDetail.label.fileType', _.assetDetail.label.fileType )}
                </dt>
                <dd className="mb-2 flex">
                    <FileFormatIcon format={fileExtension} className="text-near-black"/>
                    <span className="ml-1">{fileExtension.toUpperCase()}</span>
                </dd>
            </div>
            <div className="mb-4 mr-5 md:mr-8">
                <dt className="text-sm text-grey-darker mb-1">
                     {t( 'assetDetail.label.resolution', _.assetDetail.label.resolution )}
                </dt>
                {resolutions.map( meta =>
                    <Show when={notEmptyOrNil( meta ) && meta > 0}>
                        <dd>{meta}dpi</dd>
                    </Show>
                )}
            </div>
            <div className="mb-4 mr-5 md:mr-8">
                <dt className="text-sm text-grey-darker mb-1">
                    {t( 'assetDetail.label.dimensions', _.assetDetail.label.dimensions )}
                </dt>
                {dimensions.map( ( {width, height} ) =>
                    <Show when={width && height}>
                        <dd>{width} x {height}</dd>
                    </Show>
                )}
            </div>
            <div className="mb-4">
                <dt className="text-sm text-grey-darker mb-1">
                    {t( 'assetDetail.label.fileSize', _.assetDetail.label.fileSize )}
                </dt>
                {fileSizes.map( meta =>
                    <Show when={notEmptyOrNil( meta ) && meta > 0}>
                        <dd>{toFileSize( meta )}</dd>
                    </Show>
                )}
            </div>
        </div>
    );
}

