import React, {useEffect, useState} from 'react';
import {getAssetDownloadUrl} from '@/apps/ResourceCenter/util/AssetHelpers';
import cx from 'classnames';
import {Button, Modal, Show, t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';
import {Download} from '@/components/common/Icons';
import {FormikProvider} from 'formik';
import {AssetDownloadOptions} from '@/components/common/AssetDownload/AssetDownloadOptions';

export const AssetDownloadDialog = ( {assetDownloadApi, label, children, className, allowAnonymous} ) => {
    const {
        formProvider,
        assets,
        sizeOptions,
        isSelectingDownloadOption,
        isDownloading,
        isComplete,
        progress,
        onPromptDownloadOptions,
        onDownload,
        onCancelDownload
    } = assetDownloadApi;

    useEffect( () => {
        assetDownloadApi.setAllowAnonymous( allowAnonymous );
    }, [] );

    const asset = assets[0];

    return (
        <>
            {isSelectingDownloadOption &&
                <Modal size="md"
                       title={t( 'label.download', _.label.download )}
                       isOpen={isSelectingDownloadOption}
                       onClose={onCancelDownload}>
                    <FormikProvider value={formProvider}>
                        <p className="mb-4">{asset.name}</p>
                        <div className="mb-4">
                            <h3 className="text-title mb-2">{t( 'assetDetail.label.fileType',
                                _.assetDetail.label.fileType )}</h3>
                            <div className="flex">
                                <FileFormatIcon format={asset.fileExtension} className="mr-1 text-grey-dark"/>
                                <span>{asset.fileExtension}</span>
                            </div>
                        </div>
                        <Show when={notEmptyOrNil( sizeOptions )}>
                            <AssetDownloadOptions sizeOptions={sizeOptions} sizeDetails={asset.sizes}/>
                        </Show>
                        <div className="flex mt-8">
                            <Button data-testid="AssetDownloadDialog.DownloadAction" className="mr-2"
                                    onClick={onDownload} tertiary icon={Download}>
                                {t( 'actionLabel.download', _.actionLabel.download )}
                            </Button>
                            <Button className="mr-2" onClick={onCancelDownload} tertiary>
                                {t( '', _.actionLabel.cancel )}
                            </Button>
                        </div>
                    </FormikProvider>
                </Modal>
            }
            <a data-testid="AssetDownloadDialog.OpenDownloadAction"
               href={getAssetDownloadUrl( asset.id, asset.scheme )}
               className={cx( className, {
                   'cursor-wait': isDownloading
               } )}
               title={label}
               download={asset.name}
               onClick={e => {
                   e.preventDefault();
                   if ( !isDownloading ) {
                       onPromptDownloadOptions();
                   }
               }}>
                {children( {label, isSelectingDownloadOption, isDownloading, isComplete, progress} )}
            </a>
        </>
    );
}

export const AssetDownloadMenu = ( {assetDownloadApi, label, children, className, allowAnonymous} ) => {
    const [visible, setVisible] = useState( false );
    const show = () => setVisible( true );
    const hide = () => setVisible( false );

    const {
        formProvider,
        assets,
        sizeOptions,
        isSelectingDownloadOption,
        isDownloading,
        isComplete,
        progress,
        onPromptDownloadOptions,
        onDownload
    } = assetDownloadApi;

    useEffect( () => {
        assetDownloadApi.setAllowAnonymous( allowAnonymous );
    }, [] );

    const asset = assets[0];

    return (
        <>
            <Tooltip
                visible={visible}
                maxWidth={640}
                arrow={false}
                placement="bottom-end"
                interactive={true}
                theme="light"
                onClickOutside={hide}
                content={
                    <div className="w-full md:min-w-md p-4 text-base">
                        <FormikProvider value={formProvider}>
                            <Show when={notEmptyOrNil( sizeOptions )}>
                                <AssetDownloadOptions sizeOptions={sizeOptions} sizeDetails={asset.sizes}/>
                            </Show>
                            <Show when={isEmptyOrNil( sizeOptions )}>
                                <p className="italic">
                                    {t( 'downloadAssets.notify.metadataNotAvailable',
                                        _.downloadAssets.notify.metadataNotAvailable )}
                                </p>
                            </Show>
                            <div className="flex mt-8">
                                <Button
                                    data-testid="AssetDownloadMenu.DownloadAction"
                                    className="mr-r download-action"
                                    tertiary
                                    icon={Download}
                                    onClick={() => {
                                        hide();
                                        onDownload();
                                    }}>
                                    {t( 'actionLabel.download', _.actionLabel.download )}
                                </Button>
                            </div>
                        </FormikProvider>
                    </div>
                }>
                <a data-testid="AssetDownloadMenu.OpenDownloadAction"
                   href={getAssetDownloadUrl( asset.id, asset.scheme )}
                   className={cx( className, {
                       'cursor-wait': isDownloading
                   } )}
                   title={label}
                   download={asset.name}
                   onClick={e => {
                       e.preventDefault();
                       if ( !isDownloading ) {
                           show();
                           onPromptDownloadOptions();
                       }
                   }}>
                {children( {label, isSelectingDownloadOption, isDownloading, isComplete, progress} )}
                </a>
            </Tooltip>

        </>
    );
}
