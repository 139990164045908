import React from 'react';
import {Button, Modal, Show} from '@ultradent/components';
import {noop} from '@ultradent/utilities/Functions';
import {RegistrantDetail} from '@/containers/EventManagement/RegistrantDetail';
import {RegistrantForm} from '@/containers/EventManagement/RegistrantForm';

export const ManageEventRegistrant = ( {eventManager, formManager, isOpen} ) => {
    if ( !eventManager.registrant ) {
        return null;
    }

    const {registrant, isProcessing, isEditing, onEditRegistrant, postErrors, resetCurrentRegistrant} = eventManager;

    return (
        <Modal id="event-registrant-detail" isOpen={isOpen} onClose={resetCurrentRegistrant} size="xl">
            <div className="modal-header">
                <div className="flex">
                    <h2 className="headline-sm mr-2">Response Detail</h2>
                    <Show when={!isEditing}>
                        <Button type="button" textLink onClick={onEditRegistrant}>Edit</Button>
                    </Show>
                </div>
            </div>
            <div className="modal-body">
                <Show when={!isEditing}>
                    <RegistrantDetail {...registrant} countryList={formManager.countryList}/>
                </Show>
                <Show when={isEditing}>
                    <RegistrantForm
                        formManager={formManager}
                        isProcessing={isProcessing}
                        postErrors={postErrors}
                        isEventAdmin={true}/>
                </Show>
            </div>
        </Modal>
    );
};

ManageEventRegistrant.defaultProps = {
    isOpen: false,
    form: {},
    eventManager: {
        isEditing: false,
        processing: false,
        postErrors: null,
        resetCurrentRegistrant: noop,
        onSubmit: noop,
        onEditRegistrant: noop,
        registrant: {
            id: null,
            dietaryRequirements: [],
            status: [] // ['Call Back', 'Confirmed', 'Waitlisted', 'Cancelled']
        }
    }
}
