import {atom, selector, useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil';
import AssetService from '@/providers/assets';
import {transformAssetModel} from '@/containers/FacetedSearch/transforms';

export const sharingAssetsState = atom( {
    key: 'sharingAssetsState',
    default: []
} );

export const shareAssetsDialogState = atom( {
    key: 'shareAssetsDialogState',
    default: false
} );

const sharingAssetsDetail = selector( {
    key: 'sharingAssetsDetail',
    get: ( {get} ) => {
        const assets = get( sharingAssetsState );
        return {
            hasItems: Array.isArray( assets ) && assets.length > 0,
            list: assets.map( ( {id, name} ) => ({id, name}) )
        };
    }
} );

export const sharedAssetsRefreshCount = atom( {
    key: 'sharedAssetsRefreshCount',
    default: 0
} );

export const sharedAssetsSelector = selector( {
    key: 'sharedAssetsSelector',
    get: async ( {get} ) => {
        get( sharedAssetsRefreshCount ) // note -> registers refetch count as a dependency to allow for force refresh
        const response = await AssetService.anon.getSharedAssets();
        const list = response.data.map( transformAssetModel );

        return {
            all: list,
            validated: list.filter( a => a.valid )
        }
    }
} );

export function useAssetSharing () {
    const [isShareAssetsDialogOpen, setIsShareAssetsDialogOpen] = useRecoilState( shareAssetsDialogState );
    const setSharingAssets = useSetRecoilState( sharingAssetsState );
    const sharingAssets = useRecoilValue( sharingAssetsDetail );

    const onShareAssets = assets => () => {
        if ( !Array.isArray( assets ) ) {
            assets = [assets];
        }

        setSharingAssets( assets );
        openAssetSharing();
    };

    const openAssetSharing = () => {
        setIsShareAssetsDialogOpen( true );

    };
    const closeAssetSharing = () => {
        setIsShareAssetsDialogOpen( false );
        setSharingAssets( [] );
    };

    return {
        onShareAssets,
        isShareAssetsDialogOpen,
        openAssetSharing,
        closeAssetSharing,
        sharingAssets,
        setSharingAssets
    }
}
