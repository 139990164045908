import Cookie from 'js-cookie';
import namespace from  '@ultradent/utilities/Namespace';

const UNKNOWN = 'unspecified';
const FEATURE_TESTS_KEY = 'upi-feature-tests';

/**
 * activate
 * Set a cookie that can then be used as a feature check within modules
 * to determine an implementation to render for FeatureTests ran from Feature testing tools
 * @param data = {key:String, name:String, description:String, implementation:String, page:String, dateRange:String}
 * example usage
 * activate( {
        key: 'SyncedCartButton',
        name: 'Reorder cart button',
        page: 'site wide',
        description: 'Implements an add to cart button that is synced with current cart on reorder page',
        implementation: 'deploys upi-frontend@1.6.3 with implementation under "upiFeatureTest.SyncedAddButton" feature flag',
        dateRange: '10/20/2020 - 11/20/2020'
    } );
 */
function activate ( data ) {
    const tests = getActiveTests() || [];

    if ( !data.key ) {
        throw Error( 'FeatureTest.activate requires a "key" parameter to be specified' );
    }

    if ( !isActive( data.key ) ) {
        tests.push( data );
        Cookie.set( FEATURE_TESTS_KEY, tests, {expires: 1} );
    }

    logTestDetail( data );
    return true;
}

function deactivate ( key ) {
    const tests = getActiveTests() || [];

    if ( !key ) {
        throw Error( 'FeatureTest.function deactivate( data ) {\n requires a "key" parameter to be specified' );
    }

    const activeTests = tests.filter( t => t.key !== key );

    // if change detected
    if ( tests.length !== activeTests.length ) {
        Cookie.set( FEATURE_TESTS_KEY, activeTests, {expires: 1} );
        console.log( `Deactivated test with key "${key}"` );
        return true;
    }

    console.log( 'There were no active tests with this key...' );
    return null;
}

function clearAll () {
    const runningTests = getActiveTests();

    if ( runningTests.length > 0 ) {
        Cookie.remove( FEATURE_TESTS_KEY );
        console.group( 'Deactivated the following test keys:' );
        runningTests.forEach( t => console.log( t.key ) );
        console.groupEnd();
        return true;
    }

    console.log( 'There were no active test keys...' );
    return null;
}

/**
 * Return an array of active tests, Array.empty if none
 * @returns {*|*[]}
 */
function getActiveTests () {
    return Cookie.getJSON( FEATURE_TESTS_KEY ) || [];
}

/**
 * Checks if a test key is stored for current sessions to be used as feature check from components
 * @param key
 * @returns {boolean}
 */
function isActive ( key ) {
    const tests = getActiveTests() || [];
    const foundTest = tests.find( t => t.key === key );
    return Boolean( foundTest );
}

function logTestDetail ( {key, name, description, implementation, page, dateRange} ) {
    console.groupCollapsed( `Feature test running: ${name || key}` );
    console.table( {
        'TestKey (cookie)': key,
        'Description': description || UNKNOWN,
        'Affected page': page || UNKNOWN,
        'Implementation': implementation || UNKNOWN,
        'Expected date range': dateRange || UNKNOWN
    } );
    console.groupEnd();
}

function logActiveTests () {
    getActiveTests().forEach( logTestDetail );
}

const publicApi = {activate, deactivate, isActive, logActiveTests, getActiveTests, clearAll};
Object.assign( namespace( 'FeatureTests' ), publicApi );

export default publicApi;





