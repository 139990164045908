import {CategoryList} from '@/containers/FacetedSearch/Facets/CategoryList';
import {FacetFilterList} from '@/containers/FacetedSearch/Facets/FacetFilterList';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

const sectionStyle = 'px-4 pt-4 pb-2 border-b border-grey-lighter';

const Facets = ( {categories, facets, loading, onChange, onCategoryClick, className = ''} ) => {
    const onFacetLineChange = ( facet, isChecked ) => onChange && onChange( facet, isChecked );
    // const onCategoryLineClick = ( category ) => onCategoryClick && onCategoryClick( category );

    if ( loading ) {
        return <Facets.Skeleton/>
    }

    return (
        <div data-testid="Facets" className={className}>
            {/*
            note -> removing the category list from the search facets per business request
            {notEmptyOrNil( categories ) &&
                <CategoryList
                list={categories}
             className={sectionStyle}
             maxLength={12}
             onLineClick={onCategoryLineClick}/>
            */}
            {notEmptyOrNil( facets ) &&
                facets.map( facet =>
                    <FacetFilterList
                        key={facet.id}
                        {...facet}
                        onLineChange={onFacetLineChange}
                        className={sectionStyle}/> )
            }
        </div>
    )
}

Facets.Skeleton = () =>
    <div data-testid="Facets.Skeleton"
         className="hidden md:block w-full flex-none md:mr-8 mb-6">
            <CategoryList.Skeleton className={sectionStyle}/>
            <FacetFilterList.Skeleton className={sectionStyle}/>
            <FacetFilterList.Skeleton className={sectionStyle}/>
    </div>

export {Facets}
