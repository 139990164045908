class ClipboardRepository {

    constructor ( store ) {
        this.clipboardStore = store;
    }

    subscribe ( fn ) {
        return this.clipboardStore.subscribe( fn );
    }

    getStoreKey () {
        return this.clipboardStore.storeKey;
    }

    getClipboard ( id ) {
        return this.clipboardStore.getStore( id );
    }

    setClipboard ( id, clipboardData ) {
        this.clipboardStore.setStore( id, clipboardData );
    }

    clearClipboard ( id ) {
        this.clipboardStore.clearStoreItem( id );
    }
}

export default ClipboardRepository;
