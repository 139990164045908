import flatten from 'ramda/src/flatten';
import {Card, CardActions, CardContent} from '@/components/common/Card';
import {AssetThumbnail} from '@/apps/ResourceCenter/Assets/AssetThumbnail';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {AssetDownloadDialog} from '@/components/common/AssetDownload';
import useAssetDownload from '@/components/common/AssetDownload/useAssetDownload';
import {AssetDownloadIconRenderer} from '@/components/common/AssetDownload/AssetDownloadRenderer';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';
import PropType from 'prop-types';
import React from 'react';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const AssetSharingItem = ( {
                                      id,
                                      additional,
                                      scheme,
                                      mimetype,
                                      sizes,
                                      fileExtension,
                                      name,
                                      productFamilies,
                                      products,
                                      assetCategory,
                                      assetType
                                  } ) => {

    const assetDownloadApi = useAssetDownload( {
        assets: [{id, additional, scheme, name, sizes, mimetype, fileExtension}]
    } );
    const categories = flatten( [productFamilies, products, assetCategory, assetType].filter( Boolean ) );

    return (
        <Card actionPosition="bottom">
            <CardContent>
                <div className="mb-2 relative">
                    <AssetThumbnail imgRatio="16x9" name={name} id={id} isPreviewOnly={true} scheme={scheme}/>
                </div>
                <h3 className="text-title mb-2 break-words">
                    {additional?.AssetTitle ?? name}
                </h3>
                {notEmptyOrNil( categories ) &&
                    <p title={categories.join( ', ' )}
                       className="cursor-help text-grey-darker text-sm md:text-body-sm mt-0 mb-2 line-clamp-3">
                        {t( 'asset.label.appearsIn', _.asset.label.appearsIn )} {categories.join( ', ' )}
                    </p>
                }
            </CardContent>
            <CardActions className="justify-between">
                <div>
                    <FileFormatIcon format={fileExtension} className="ml-1 inline-block text-grey-dark"/>
                </div>
                <AssetDownloadDialog
                    allowAnonymous={true}
                    assetDownloadApi={assetDownloadApi}
                    fileExtension={fileExtension}
                    className="inline-block p-2 link-2 relative">
                    {AssetDownloadIconRenderer}
                </AssetDownloadDialog>
            </CardActions>
        </Card>
    )
}

export const InvalidatedAssetSharingItem = ( {id, name} ) =>
    <div className="relative p-4 border-2 bg-white border-dashed border-grey rounded-lg">
        <div className="mb-2 relative leading-tight">
            <AssetThumbnail imgRatio="1x1"/>
        </div>
        <h3 className="text-title mb-2 text-grey-darker leading-normal break-words">{name}</h3>
    </div>;

InvalidatedAssetSharingItem.propTypes = {
    id: PropType.string.isRequired,
    name: PropType.string.isRequired
};

AssetSharingItem.Ivalidated = InvalidatedAssetSharingItem;

AssetSharingItem.Skeleton = () =>
    <Card>
        <CardContent>
            <SkeletonElement type="thumbnail-full" className="mb-4"/>
            <SkeletonElement type="title"/>
            <SkeletonElement type="text"/>
            <SkeletonElement type="text"/>
        </CardContent>
        <CardActions className="justify-between">
            <SkeletonElement type="icon"/>
            <SkeletonElement type="icon"/>
        </CardActions>
    </Card>
