import React, {useMemo} from 'react';
import cx from 'classnames';
import {CheckmarkBold, ReportProblemTriangle} from '@/components/common/Icons';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {Show} from '@ultradent/components';

const css = {
    Wrapper: 'flex items-center justify-between py-4 px-6 text-lg rounded-lg shadow-lg border border-moon-grey',
    Counter: 'ml-4 font-bold',
    Indicator: 'ml-4 flex flex-none items-center justify-center rounded text-white w-10 h-10'
};
export const RegistrationCounter = ( {registrantList, className, availableSeats} ) => {
    const calculateRegistrantCount = useMemo( () => {
        let count = 0;
        registrantList.forEach( ( registrant ) => {
            if ( registrant.status === 'Confirmed' ) {
                count++;
            }
        } );
        return count;
    }, [registrantList] );

    const registrantInBounds = useMemo(
        () => calculateRegistrantCount <= availableSeats,
        [calculateRegistrantCount, availableSeats]
    );

    return (
        <div className={cx( css.Wrapper, className )}>
            <h3 className="mr-4">Confirmed Registration</h3>
            <Show when={isEmptyOrNil( availableSeats )}>
                <div>pending...</div>
            </Show>
            <Show when={notEmptyOrNil( availableSeats )}>
                <div className="whitespace-nowrap">
                    <span
                        data-testid="registrant-count"
                        className={cx( css.Counter, {
                            'text-fail': !registrantInBounds,
                            'text-blue': registrantInBounds
                        } )}>{calculateRegistrantCount}</span> /
                    <span data-testid="available-seats">{availableSeats}</span>
                </div>
                <div
                    data-testid="status-indicator"
                    className={cx( css.Indicator, {
                        'bg-fail': !registrantInBounds,
                        'bg-blue': registrantInBounds
                    } )}>
                    {registrantInBounds
                        ? <CheckmarkBold width="20" height="20"/>
                        : <ReportProblemTriangle width="20" height="20"/>
                    }
                </div>
            </Show>
        </div>
    );
}
