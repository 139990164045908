import {t, Tooltip} from '@ultradent/components';
import {Cross} from '@/components/common/Icons';
import {AssetThumbnail} from '@/apps/ResourceCenter/Assets/AssetThumbnail';
import PropType from 'prop-types';
import React from 'react';
import {translationKeys as _} from '@/constants/translations';

export const InvalidatedBookmark = ( {id, name, onRemove} ) =>
    <div className="relative p-2 border-2 border-dashed border-grey rounded-lg leading-tight">
        <Tooltip content={t(
            'bookmarks.message.removeItem',
            _.bookmarks.message.removeItem
        )}>
            <button
                onClick={onRemove}
                className="absolute z-10 top-1 right-1 p-2 bg-white rounded hover:text-fail">
                <Cross width="16"/>
            </button>
        </Tooltip>
        <div className="mb-2 relative">
            <AssetThumbnail name={t(
                'asset.notAvailable',
                _.asset.notAvailable
            )} imgRatio="1x1"/>
        </div>
        <h3 className="text-title mb-2">{name}</h3>
        <p className="text-caption mt-0 text-grey-dark">
            {t(
                'bookmarks.label.pleaseRemoveItem',
                _.bookmarks.label.pleaseRemoveItem
            )}
        </p>
    </div>;

InvalidatedBookmark.defaultProps = {
    name: 'Unknown item'
};
InvalidatedBookmark.propTypes = {
    id: PropType.string.isRequired,
    onRemove: PropType.func.isRequired
};
