import {Link} from 'react-router-dom';
import {Checkmark, LinkExternal, MenuVertical} from '@/components/common/Icons';
import {t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {followCursor} from 'tippy.js';
import namespace from '@ultradent/utilities/Namespace';
import {allEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

const {requestpublicationemailto, requestpublicationemailcc} = namespace( 'SiteLevelConfiguration.studies' );

export const Taglist = ( {tags = []} ) =>
    tags.map( tag => <Link key={tag.value} className="tag hover:link-2 mr-2 mb-2"
                           to={`/search?keyword=&${tag.group}=${encodeURIComponent( tag.value )}`}>{tag.value}</Link> );

export const ArticleTitle = ( {requestRequired, url, title} ) => {
    const heading = <h3 className="text-lg font-semibold leading-tight mb-1">{title}</h3>;

    if ( requestRequired ) {
        return <TooltipUponRequest>{heading}</TooltipUponRequest>;
    }

    if ( url ) {
        return (
            <a title="Visit article" className="flex items-start hover:link-2" href={url} target="_blank"
               rel="noopener">
                {heading}
                <LinkExternal width="20" className="w-6 ml-2 text-blue flex-none"/>
            </a>
        )
    }

    return heading;
}

export const MentionLine = ( {withMention} ) => {
    if ( !withMention ) {
        return null;
    }

    return <p className="flex items-center mb-4">
        <Checkmark width="20" className="mr-2"/>
        {t( 'publicationDetail.label.mentionsUltradent', _.publicationDetail.label.mentionsUltradent )}
    </p>
};

export const TooltipUponRequest = ( {children} ) =>
    <Tooltip
        maxWidth={260}
        followCursor="horizontal"
        plugins={followCursor}
        content={t(
            'publicationDetail.requestPublication.tip',
            _.publicationDetail.requestPublication.tip
        )}>
        {children}
    </Tooltip>;

const RequestPublicationAction = ( {title, className = '', children} ) => {
    if ( allEmptyOrNil( [requestpublicationemailto, requestpublicationemailcc] ) ) {
        return null;
    }

    let mailto = `mailto:${requestpublicationemailto}?`;
    if ( notEmptyOrNil( requestpublicationemailcc ) ) {
        mailto += `cc=${requestpublicationemailcc}&`;
    }

    const emailSubject = t(
        'publicationDetail.requestPublication.emailSubject',
        _.publicationDetail.requestPublication.emailSubject );

    const emailBody = t(
        'publicationDetail.requestPublication.emailBody',
        _.publicationDetail.requestPublication.emailBody );

    return <a className={className}
              href={`${mailto}subject=${emailSubject}&body=${emailBody} "${title}"`}>{children}</a>;
}

export const RequestLine = ( {withRequest, title, assetId} ) => {
    if ( !withRequest ) {
        return null;
    }

    return (
        <RequestPublicationAction title={title} className="block text-blue font-bold">
            <TooltipUponRequest>
                <span>
                {t( 'publicationDetail.requestPublication.label', _.publicationDetail.requestPublication.label )}
                </span>
            </TooltipUponRequest>
        </RequestPublicationAction>
    )
};

export const SynopsisLine = ( {withClaim} ) => {
    if ( !withClaim ) {
        return null;
    }

    return <p className="mt-1 text-caption">{withClaim}</p>
}

export const ContextMenu = props => {
    if ( allEmptyOrNil( [props.requestRequired, props.articleUrl].filter( Boolean ) ) ) {
        return (
            <button className="cursor-not-allowed">
                <Tooltip content="No actions available" maxWidth={260}>
                    <MenuVertical width="24" height="24" className="text-grey-light"/>
                </Tooltip>
            </button>
        );
    }

    return (
        <Tooltip
            trigger="click"
            maxWidth={480}
            arrow={false}
            placement="bottom-end"
            interactive={true}
            theme="light"
            content={<ContextMenuContent {...props} />}>
            <button className="cursor-pointer">
                <MenuVertical width="24" height="24"/>
            </button>
        </Tooltip>
    )
};

export const ContextMenuContent = ( {requestRequired, articleUrl} ) => {
    return (
        <div className="flex flex-col w-40 text-base">
            {requestRequired &&
                <RequestPublicationAction className="menu-context--item">
                    {t( 'publicationDetail.actionLabel.requestPublication',
                        _.publicationDetail.actionLabel.requestPublication )}
                </RequestPublicationAction>
            }
            {articleUrl &&
                <a href={articleUrl} target="_blank" rel="noreferrer" className="menu-context--item">
                {t( 'publicationDetail.actionLabel.visitArticle', _.publicationDetail.actionLabel.visitArticle )}
            </a>
            }
        </div>
    );
}
