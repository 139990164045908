import React, {useEffect, useState} from 'react';
import {AppRouter} from './router';
import ResourceCenterProvider from '@/providers/resource-center';
import {ResourceAppContext} from '@/apps/ResourceCenter/appContext';
import {HashRouter as Router} from 'react-router-dom';
import {ASSET_CLIPBOARD_KEY, AssetClipboard} from '@/containers/AssetClipboard';
import {ClipboardContext, useClipboard} from '@/components/common/Clipboard';
import {ASSET_BOOKMARKS_KEY, BookmarksContext, useBookmarks} from '@/containers/Bookmarks';
import usePageModel from '@/containers/PageModel/usePageModel';

function App () {
    const [categoryTaxonomy, setCategoryTaxonomy] = useState( null );

    const bookmarksApi = useBookmarks( ASSET_BOOKMARKS_KEY );
    const clipboardApi = useClipboard( ASSET_CLIPBOARD_KEY );
    const pageModelApi = usePageModel( 'resourceslandingpage' );

    useEffect(
        () => {
            async function fetchTaxonomy () {
                try {
                    const resp = await ResourceCenterProvider.getTaxonomy();
                    setCategoryTaxonomy( resp.data );
                }
                catch ( err ) {
                    console.error( 'Failed to load ResourceApp taxonomy', err.message );
                    setCategoryTaxonomy( [] );
                }
            }

            fetchTaxonomy();
        },
        [] );

    return (
        <ResourceAppContext.Provider value={{pageModelApi, categoryTaxonomy}}>
            <BookmarksContext.Provider value={bookmarksApi}>
                <ClipboardContext.Provider value={clipboardApi}>
                    <Router basename={'/'}>
                        <AppRouter/>
                        <AssetClipboard/>
                    </Router>
                </ClipboardContext.Provider>
            </BookmarksContext.Provider>
        </ResourceAppContext.Provider>
    );
}

export default App;
