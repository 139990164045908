import React, {useEffect} from 'react';
import {RecoilRoot, useRecoilRefresher_UNSTABLE, useRecoilSnapshot} from 'recoil';
import {AssetSharingSubmission} from '@/containers/AssetSharing';

export const AppContext = React.createContext();

const AppWrapper = ( {children} ) =>
    <React.StrictMode>
        <RecoilRoot>
            {children}
            <AssetSharingSubmission/>
        </RecoilRoot>
    </React.StrictMode>

const Context = ( {children} ) => {
    return (
        <AppContext.Provider value={{User: {}}}>
            {children}
        </AppContext.Provider>
    );
}

export const AppEntry = ( {children} ) =>
    <AppWrapper>
        <Context>
            {children}
        </Context>
    </AppWrapper>

// Note - special use cases fro testing component that use Recoil
AppWrapper.TestHarness = ( {children} ) =>
    <React.StrictMode>
        <RecoilRoot>
            <RecoilCacheReset/>
            {children}
            <AssetSharingSubmission/>
        </RecoilRoot>
    </React.StrictMode>

AppEntry.TestHarness = ( {children} ) =>
    <AppWrapper.TestHarness>
        <Context>
            {children}
        </Context>
    </AppWrapper.TestHarness>

const RecoilCacheResetEntry = ( {node} ) => {
    const resetNode = useRecoilRefresher_UNSTABLE( node );
    useEffect(
        () => () => {
            console.log( 'RESET CACHE' );
            resetNode();
        },
        [resetNode]
    );
    return null;
};

export const RecoilCacheReset = () => {
    const snapshot = useRecoilSnapshot();
    return (
        <>
            {[...snapshot.getNodes_UNSTABLE()].map( node => (
                <RecoilCacheResetEntry key={node.key} node={node}/>
            ) )}
        </>
    );
};
