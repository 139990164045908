import ResourceCenterService from '@/providers/resource-center';
import {SearchBar, SuggestionItem} from '@/containers/FacetedSearch/PageNavigation/SearchBar';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FileFormatIcon} from '@/components/common/Icons/FileFormatIcon';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {formatQueryParams} from '@/containers/FacetedSearch/transforms';

const AUTOCOMPLETE_RESULT_PROP = 'queryPlusText';
const SUGGESTION_RESULT_PROP = '@search.text';

export const SearchSuggestions = ( {initialQuery, selectedCategories, prependSearchSlot, onSubmit} ) => {
    const navigate = useNavigate();
    return <SearchBar
        placeholder={t( 'searchBar.resourcesPlaceholder', _.searchBar.resourcesPlaceholder )}
        querySuggestionsRepository={ResourceCenterService}
        initialQuery={initialQuery}
        onSubmit={onSubmit}
        prependSearchSlot={prependSearchSlot}
        queryGroup={[
            {
                transientQueryProp: AUTOCOMPLETE_RESULT_PROP,
                renderer: result => (
                    <SuggestionItem>{result[AUTOCOMPLETE_RESULT_PROP]}</SuggestionItem>
                )
            },
            {
                groupName: t( 'search.suggestion.inResources', _.search.suggestion.inResources ),
                renderer: result => (
                    <SuggestionItem>
                        <div className="flex items-center">
                            <FileFormatIcon format="generic" className="mr-2 text-grey-darker flex-none"/>
                            <span>{result[SUGGESTION_RESULT_PROP]}</span>
                        </div>
                    </SuggestionItem>
                )
            }
        ]}
        onResultSelect={( evt, result ) => {
            if ( result[AUTOCOMPLETE_RESULT_PROP] ) {
                const searchStr = formatQueryParams( {
                    keyword: result[AUTOCOMPLETE_RESULT_PROP],
                    categoryPath: selectedCategories
                } );

                navigate( {
                    pathname: '/search',
                    search: searchStr
                } );
                return;
            }
            if ( result?.Id ) {
                navigate( `/assets/${result.Id}` );
            }
        }}

    />
}

SearchSuggestions.defaultProps = {
    initialQuery: '',
    onSubmit: () => {},
    slot: null
};
