export const eventManagementReducer = ( state, action ) => {
    switch ( action.type ) {

        case 'setEventId':
            return {
                ...state,
                eventId: action.payload.eventId
            };

        case 'setRequestState':
            const rlReq = action.payload.request;
            const registrantList = rlReq.state === 'hasValue' ? rlReq.contents : {list: []};
            return {
                ...state,
                registrantList: {
                    isLoading: rlReq.state !== 'hasValue' && rlReq.state !== 'hasError',
                    loadError: rlReq.state === 'hasError' ? rlReq.contents : null,
                    ...registrantList
                }
            }

        case 'setRegistrantList':
            return {
                ...state,
                postErrors: null,
                registrantList: action.payload.list
            };

        case 'updateRegistrant':
            let newRegistrantList = [...state.registrantList.list];
            const index = newRegistrantList.findIndex( r => r.id === action.payload.registrant.id );

            if ( index >= 0 ) {
                newRegistrantList[index] = {
                    ...newRegistrantList[index],
                    ...action.payload.registrant
                };
            }
            return {
                ...state,
                registrantList: {
                    ...state.registrantList,
                    list: newRegistrantList
                }
            };

        case 'setRegistrant':
            return {
                ...state,
                postErrors: null,
                registrant: state.registrantList.list.find( r => r.id === action.payload.registrantId )
            };

        case 'resetRegistrant':
            return {
                ...state,
                postErrors: null,
                registrant: null,
                isEditing: false
            };

        case 'setIsEditing':
            return {
                ...state,
                postErrors: null,
                isEditing: action.payload.isEditing
            };

        case 'setIsProcessing':
            return {
                ...state,
                isProcessing: action.payload.isProcessing
            };

        case 'setPostErrors':
            return {
                ...state,
                postErrors: action.payload.errors
            };

        default:
            throw Error( `Unknown action "${action.type}" specified for eventManagementReducer` );
    }
};
