import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/page',
    mockSrc: process.env.MOCK_API && 'page-model'
} );

svc.getContentType = function getContentType ( contenttype ) {
    return this.xhr.get( 'content', {params: {contenttype}} );
};

export default svc;

