import React, {useContext} from 'react';
import SEO from '@/components/common/SEO';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {StudiesAppContext, verifyOrFallBackContent} from '@/apps/Studies/appContext';
import {HeaderSection} from './Header';
import {PopularTopicsSection} from './PopularTopics';
import {PopularStudiesSection} from './PopularStudies';

const StudiesQuery = () => {
    const {pageModelApi} = useContext( StudiesAppContext );

    if ( pageModelApi.loading ) {
        return (
            <div className="condense--pad-for-site-header">
                <HeaderSection loading={true}/>
                <PopularTopicsSection loading={true}/>
            </div>
        );
    }

    const {
        heroImageSrc,
        pageTitle,
        pageSubtitle,
        popularTopics,
        popularStudies
    } = verifyOrFallBackContent( pageModelApi.pageModel );

    return (
        <div className="condense--pad-for-site-header">
            <SEO title={pageTitle}/>
            <HeaderSection backgroundImage={heroImageSrc} title={pageTitle} subtitle={pageSubtitle}/>
            {notEmptyOrNil( popularTopics.tiles ) && <PopularTopicsSection {...popularTopics}/>}
            {notEmptyOrNil( popularStudies.articleCollections ) && <PopularStudiesSection {...popularStudies}/>}
        </div>
    );
}

export {StudiesQuery}
