import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {AxiosProvider, Get} from 'react-axios';
import {QueryController} from '@/containers/FacetedSearch/QueryController';

/**
 *
 * @param queryRepository - Axios instance; endpoint to issue queries against
 * @param constructQuery - Function; construct a query string to pass to endpoint must return Object {keyword, query, startAt [,categoryFilter[]]}
 * @param itemsPerPage - Int; Number of results per page (optional)
 * @returns {JSX.Element}
 * @constructor
 */
const SearchQuery = ( {
                          slotHeader,
                          slotResults,
                          sortOptions,
                          queryRepository,
                          constructQuery,
                          itemsPerPage = 50
                      } ) => {

    const [isInitialRequest, setIsInitialRequest] = useState( true );
    const location = useLocation();
    const navigate = useNavigate();

    const {query, keyword, startAt, orderBy, categoryFilter} = useMemo(
        () => constructQuery( location.search ),
        [location.search]
    );

    return (

        <AxiosProvider instance={queryRepository.xhr}>
            <Get url={`search${query}`}>
                {( error, response, isLoading, makeRequest, axios ) => {
                    let searchResultProps = {};

                    if ( error ) {
                        searchResultProps = {
                            error: true,
                            onRetry: () => makeRequest( {params: {reload: true}} )
                        };
                    }
                    else if ( isInitialRequest && isLoading ) {
                        searchResultProps = {loading: true};
                    }
                    else if ( response && response.data ) {
                        searchResultProps = {
                            resultData: response.data,
                            categoryFilter: categoryFilter,
                            onQuery: ( q ) => {
                                setIsInitialRequest( false );
                                // ensure search string is always unique so router treats as new request
                                const requestId = parseInt( Math.random() * 10000, 10 );
                                navigate( `/search?${q}&requestId=${requestId}` );
                            }
                        };
                    }

                    return (
                        <QueryController
                            slots={{
                                header: slotHeader,
                                results: slotResults
                            }}
                            sortOptions={sortOptions}
                            queryRepository={queryRepository}
                            keyword={keyword || ''}
                            startAt={startAt}
                            orderBy={orderBy}
                            itemsPerPage={itemsPerPage}
                            {...searchResultProps}/>
                    );
                }}
            </Get>
        </AxiosProvider>
    )
}

SearchQuery.propTypes = {
    queryRepository: PropTypes.any.isRequired,
    constructQuery: PropTypes.func.isRequired
}

export {SearchQuery}
