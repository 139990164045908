import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/event',
    mockSrc: process.env.MOCK_API && 'events'
} );

const anonsvc = new ServiceProvider( {
    basePath: '/event',
    mockSrc: process.env.MOCK_API && 'events',
    options: {
        useAccessToken: false
    }
} );

function submitEventRegistrants ( registrants ) {
    return this.xhr.post( 'registrationrequests', registrants );
}

function getEventRegistrants ( eventId ) {
    return this.xhr.get( `getregistrationrequests`, {params: {id: eventId}} );
}

function updateEventRegistrant ( registrant ) {
    return this.xhr.post( `updateregistration`, registrant );
}

function getEventDetails ( eventId ) {
    return this.xhr.get( `geteventdetails`, {params: {id: eventId}} );
}

svc.submitEventRegistrants = submitEventRegistrants;
svc.updateEventRegistrant = updateEventRegistrant;
svc.getEventDetails = getEventDetails;
svc.getEventRegistrants = getEventRegistrants;

anonsvc.submitEventRegistrants = submitEventRegistrants;
anonsvc.getEventDetails = getEventDetails;
export const EventManagementProvider = svc.xhr;

svc.anon = anonsvc;
export default svc;


