import {forwardRef} from 'react';
import cx from 'classnames';
import {getAssetImageSrc} from '@/apps/ResourceCenter/util/AssetHelpers';
import useAssetLoadError from '@/apps/ResourceCenter/Assets/useAssetLoadError';

export const AssetImage = forwardRef( ( {id, name, className, size = 400}, ref ) => {
    const assetStyle = cx( 'lazyload bg-checkerboard fade-in', className );
    const {onError} = useAssetLoadError( {id, name} );

    return (
        <img className={assetStyle}
             alt={name}
             onError={onError}
             ref={ref}
             data-src={getAssetImageSrc( id, {size} )}/>
    )
} )
