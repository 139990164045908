import React, {useContext, useEffect, useMemo, useState} from 'react';
import namespace from '@ultradent/utilities/Namespace';
import {format} from 'date-fns';
import {notify} from '@/modules/notifier';
import {Button, MediumDevice, Show} from '@ultradent/components';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {ManageEventRegistrant} from '@/components/dialogs/ManageEventRegistrant';
import {PersonAdd} from '@/components/common/Icons';
import {TabPanel, Tabs} from '@/components/common/Tabs';
import {useEventDetails} from './useEventDetails';
import {useEventManagement} from './useEventManagement';
import {useEventRegistration} from './useEventRegistration';
import {useRegistrantForm} from './useRegistrantForm';
import {RegistrantForm} from './RegistrantForm';
import {RegistrantList} from './RegistrantList';
import {ResponseList} from './ResponseList';
import {RegistrationSubmitted} from './RegistrationSubmitted';
import {RegistrationCounter} from './RegistrationCounter';
import {fileDownload} from '@ultradent/utilities/Media';

const userInfo = namespace( 'User.info' );
export const EventManagementContext = React.createContext();

export function EventManagementApp ( {eventId} ) {
    const managementApi = useEventManagement( {eventId} );
    const eventDetails = useEventDetails( {eventId} );

    return (
        <EventManagementContext.Provider value={{eventDetails, eventManager: managementApi}}>
            <Tabs>
                <TabPanel label="Register">
                    <EventRegistration
                        eventId={eventId}
                        isEventAdmin={true}
                        onSubmissionSuccess={managementApi.invalidateRegistrantList}/>
                </TabPanel>
                <TabPanel label="Responses">
                    <EventManagementController eventId={eventId}/>
                </TabPanel>
            </Tabs>
        </EventManagementContext.Provider>
    )
}

export function EventRegistrationApp ( {eventId} ) {
    const eventDetails = useEventDetails( {eventId} );

    return (
        <EventManagementContext.Provider value={{eventDetails}}>
            <EventRegistration eventId={eventId} isEventAdmin={false}/>
        </EventManagementContext.Provider>
    )
}

export const EventRegistration = ( {eventId, isEventAdmin, onSubmissionSuccess} ) => {
    const {eventDetails} = useContext( EventManagementContext );
    const registrationApi = useEventRegistration( {eventId, onSuccess: onSubmissionSuccess} );
    const registrantForm = useRegistrantForm( {
        eventId,
        eventName: eventDetails.Name,
        onSubmit: registrationApi.onSaveRegistrant,
        onCancel: registrationApi.onCancelEditRegistrant,
        registrant: registrationApi.currentRegistrant,
        userInfo,
        // note - business only want to populate fields for the first registrant entered,
        prepopulate: !isEventAdmin && isEmptyOrNil( registrationApi.registrants )
    } );
    const [isRegistrationOpen, setIsRegistrationOpen] = useState( isEventAdmin );

    useEffect( () => {
        if ( eventDetails.EndDate ) {
            setIsRegistrationOpen( isEventAdmin || new Date( eventDetails.EndDate ) > new Date() );
        }
    }, [eventDetails.EndDate] );

    useEffect( () => {
        if ( registrationApi.isSubmitted ) {
            registrantForm.formik.resetForm();
        }
    }, [registrationApi.isSubmitted] );

    if ( eventDetails.isLoading ) {
        return <RegistrantForm.Skeleton/>;
    }

    if ( registrationApi.isSubmitted ) {
        return <RegistrationSubmitted eventId={eventId}/>
    }

    if ( isRegistrationOpen ) {
        return <>
            <h2 className="headline-sm mb-10">Request to register for the event</h2>
            <Show when={notEmptyOrNil( registrationApi.registrants ) && !registrationApi.isEditing}>
                <div>
                    <h3 className="text-title mb-8">Review and Register</h3>
                    <RegistrantList
                        errors={registrationApi.submissionErrors}
                        list={registrationApi.registrants}
                        onRemove={registrationApi.onRemoveRegistrant}
                        onEdit={registrationApi.onEditRegistrant}
                        disabled={registrationApi.isProcessing}
                    />
                    <Button onClick={registrationApi.onAddRegistrant}
                            className="mb-8"
                            tertiary
                            icon={PersonAdd}
                            disabled={registrationApi.isProcessing}>Add Another Person</Button>
                    <br/>
                    <Button onClick={registrationApi.onSubmitRegistration}
                            disabled={registrationApi.isProcessing}
                            processing={registrationApi.isProcessing}>Submit Registration</Button>
                </div>
            </Show>
            <Show when={isEmptyOrNil( registrationApi.registrants ) || registrationApi.isEditing}>
                <RegistrantForm formManager={registrantForm} postErrors={registrationApi.postErrors}/>
            </Show>
        </>
    }

    return <RegistrantForm.Closed/>;
}

export const EventManagementController = ( {eventId} ) => {
    const {eventManager, eventDetails} = useContext( EventManagementContext );

    const registrantForm = useRegistrantForm( {
        eventId,
        onSubmit: eventManager.onSaveRegistrant,
        onCancel: eventManager.onCancelEditRegistrant,
        registrant: eventManager.registrant,
        prepopulate: false
    } );

    const handleDownloadCSV = useMemo( () => () => {
        try {
            fileDownload( {
                data: eventManager.toCSV( {countryList: registrantForm.countryList} ),
                filename: `Registrants - ${eventDetails.Name} - ${format( new Date(),
                    'MMM d, yyyy p' )}.csv`,
                mimetype: 'text/csv'
            } )
        }
        catch ( e ) {
            console.error( e );
            notify.error(
                'Sorry, there was a problem while attempting to export the registrants. Please try again.' );
        }
    }, [registrantForm.countryList, eventDetails] );

    return (
        <>
            <ManageEventRegistrant
                formManager={registrantForm}
                eventManager={eventManager}
                isOpen={notEmptyOrNil( eventManager.registrant )}
                onClose={eventManager.resetCurrentRegistrant}/>
            <Show when={notEmptyOrNil( eventManager.registrantList.list )}>
                <h2 className="fade-in headline-sm mb-6">Responses for {eventDetails.Name}</h2>
                <div className="fade-in mb-6 flex justify-between items-end">
                    <MediumDevice className="max-w-sm w-full">
                        <RegistrationCounter
                            registrantList={eventManager.registrantList.list}
                            availableSeats={eventDetails.AttendeeLimit}/>
                    </MediumDevice>
                    <Button tertiary onClick={handleDownloadCSV}>Download CSV</Button>
                </div>
            </Show>
            <ResponseList
                eventId={eventId}
                loading={eventManager.registrantList.isLoading}
                error={eventManager.registrantList.loadError}
                registrantList={eventManager.registrantList.list}
            />
        </>
    )
}

