import Cookie from 'js-cookie';

function show ( el ) {
    el.classList.remove( 'hidden' );
}

function hide ( el ) {
    el.classList.add( 'hidden' );
}

function isActive ( el, name, isPersistent ) {
    const hasMessage = el?.childElementCount > 0;

    // check that the messageBar is on DOM and a cookie has not been set for message (which means it's been read and closed)
    if ( isPersistent ) {
        const siteMsg = Cookie.get( `sitemessage-${name}` );
        return hasMessage && !siteMsg;
    }

    return hasMessage;
}

function createMessageBar ( node ) {
    if ( !node ) {
        throw new Error( 'createMessageBar expects param "parent" to be of type NodeList or Node' );
    }

    const cookieName = node.dataset?.msgName || 'general';
    const hideNumDays = Number( node.dataset?.hideNumDays ) || 1;
    const isPersistent = node.classList?.contains( 'notice' ) || !!hideNumDays;

    if ( isActive( node, cookieName, isPersistent ) ) {
        show( node );
        const closeBtn = node.querySelector( '[data-action="close"]' );

        if ( closeBtn != null ) {
            closeBtn.addEventListener( 'click', () => {
                if ( isPersistent ) {
                    Cookie.set( `sitemessage-${cookieName}`, 'disabled', {expires: hideNumDays} );
                }
                hide( node );
            } );
        }
    }
}

export {createMessageBar}
