import {atomFamily, selectorFamily, useRecoilValue} from 'recoil';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import clipboardStore from '@/components/common/Clipboard/store';
import ClipboardRepository from '@/components/common/Clipboard/repository';

export const repository = new ClipboardRepository( clipboardStore );

const syncStorageEffect = storeKey => ( {setSelf, trigger} ) => {
    // initialize
    if ( trigger === 'get' ) { // Avoid expensive initialization
        setSelf( repository.getClipboard( storeKey ) );
    }
    // subscribe
    const unsubscribe = repository.subscribe( data => setSelf( data ) );

    // cleanup
    return () => unsubscribe()
}

const clipboardState = atomFamily( {
    key: 'clipboard',
    default: {},
    effects_UNSTABLE: ( storeKey ) => [
        syncStorageEffect( storeKey )
    ]
} );

export const clipboardSelector = selectorFamily( {
    key: 'clipboardSelector',
    get: ( storeKey ) => ( {get} ) => {
        const clipboard = get( clipboardState( storeKey ) );

        return {
            hasItems: notEmptyOrNil( clipboard ),
            assetIds: notEmptyOrNil( clipboard ) ? clipboard.map( i => i.id ) : []
        };
    }
} );

export default function useClipboard ( storeKey ) {

    const STORE_KEY = storeKey;
    const clipboard = useRecoilValue( clipboardState( STORE_KEY ) );
    const clipboardStatus = useRecoilValue( clipboardSelector( STORE_KEY ) );

    const onClearClipboard = () => {
        repository.clearClipboard( STORE_KEY );
    };

    const onAddClipboardItem = ( item ) => () => {
        const foundItem = clipboard.find( i => i.id === item.id );
        if ( foundItem ) {
            console.warn( `Item with id "${item.id}" is already in clipboard` );
            return;
        }
        repository.setClipboard( STORE_KEY, [...clipboard, item] );
    };

    const onRemoveClipboardItem = ( item ) => () => {
        const foundIndex = clipboard.findIndex( e => e.id === item.id );
        repository.setClipboard( STORE_KEY, [
            ...clipboard.slice( 0, foundIndex ),
            ...clipboard.slice( foundIndex + 1 )
        ] );
    };

    const isInClipboard = ( {id} ) => clipboardStatus.assetIds.indexOf( id ) !== -1;

    return {
        clipboard,
        clipboardStatus,
        isInClipboard,
        onClearClipboard,
        onAddClipboardItem,
        onRemoveClipboardItem
    };
}
