import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Modal, t, Token} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import TokenInput from 'react-tag-autocomplete';
import {validateEmail} from '@ultradent/utilities/Strings';
import UserService from '@/providers/user';
import {ErrorCodes, ErrorMessages, getErrorList, getInvalidPostValues} from '@/util/Errors';
import invoke from 'lodash.invoke';
import {uniqList} from '@ultradent/utilities/Arrays';
import {Roles} from '@/constants/appConstants';
import {Checkmark, Cross} from '@/components/common/Icons';
import {PermissionOptionList} from '@/components/dialogs/ManageUserPermissions';
import {RolesOptionList} from '@/components/dialogs/ManageUserRole';
import {RegionOptionList} from '@/components/dialogs/ManageUserRegions';
import {FormikProvider, useFormik} from 'formik';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import FormPostError from '@/components/common/Form/FormPostError';

// resource -react-tag-autocomplete Docs; https://www.npmjs.com/package/react-tag-autocomplete#autoresize-optional
const tokenInputClassNames = {
    root: 'react-tags md:flex flex-auto items-center',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search min-w-2xs flex-auto',
    searchWrapper: 'react-tags__search-wrapper',
    searchInput: 'react-tags__search-input text-field ghosted w-full',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled'
};
// Remove trailing period - some OSs will add a period at end of string on double press of [SPACE] key
const sanitizeTagString = ( value ) => value.trim().replace( /(.*)\.$/, '$1' );
const hasInvalidTag = ( tags ) => tags.filter( tag => !tag.isValid ).length > 0;
const hasValidInput = query => validateEmail( sanitizeTagString( query ) ) || !query;
const getValidUserNames = ( tags ) => tags.filter( tag => tag.isValid ).map( tag => tag.name );

export const InviteUsers = ( props ) => {
    const reactTags = useRef( null );
    const [tags, setTags] = useState( props.tags || [] );
    const [query, setQuery] = useState( '' );
    const [isProcessing, setIsProcessing] = useState( props.processing );
    const [isSubmitDisabled, setIsSubmitDisabled] = useState( false );
    const [formPostError, setFormPostError] = useState( null );
    const [transientPermissions, setTransientPermissions] = useState( [] );

    const formik = useFormik( {
        enableReinitialize: true,
        validateOnChange: true,
        validate: ( values ) => {
            const errors = {};
            if ( values.permissions.length <= 0 ) {
                errors.permissions = t(
                    'userManagement.notify.atLeastOnePermissionRequired',
                    _.userManagement.notify.atLeastOnePermissionRequired
                )
            }
            return errors;
        },
        initialValues: {
            permissions: [],
            role: Roles.MEMBER,
            regions: []
        },
        onSubmit: async ( values ) => {
            setFormPostError( null );
            const errors = await formik.validateForm( values );

            if ( !isProcessing && isEmptyOrNil( errors ) ) {
                setIsProcessing( true );

                try {
                    const {role, permissions, regions} = values;
                    const resp = await UserService.sendInvite( {
                        crmAccount: props.crmAccountId,
                        regions,
                        users: getValidUserNames( tags ),
                        securityGroups: uniqList( [role, permissions] )
                    } );
                    invoke( props, 'onInviteSuccess', resp );
                }
                catch ( err ) {
                    handleRequestError( err );
                }
                finally {
                    setIsProcessing( false );
                }
            }

            return false;
        }
    } );

    useEffect( () => {
        let submitDisabled = false;
        if (
            notEmptyOrNil( formik.errors )
            || (!tags.length && !query)
            || !hasValidInput( query )
            || hasInvalidTag( tags ) ) {
            submitDisabled = true;
        }

        setIsSubmitDisabled( submitDisabled );
    }, [tags, query, formik.errors] );

    const handlePermissionChange = useCallback( ( value ) => {
            console.log( 'handlePermissionChange', formik.values );
            setTransientPermissions( formik.values.permissions );
        },
        [formik.values]
    );

    const handleRoleChange = useCallback( ( value ) => {
            const isAdminRole = value === Roles.PARTNER_ADMIN;

            if ( isAdminRole ) {
                setTransientPermissions( formik.values.permissions );
            }

            formik.setFieldValue( 'permissions', isAdminRole
                ? props.availableFeatures
                : transientPermissions );
        },
        [formik.values, props.availableFeatures]
    );

    const onAddTag = ( tag ) => {
        const sName = sanitizeTagString( tag.name );
        const newTag = {
            id: sName,
            name: sName,
            isValid: validateEmail( sName )
        };

        setTags( list => uniqList( [...list, newTag] ) );
        setQuery( '' );
    };

    const onRemoveTag = ( index ) => {
        setTags( tagList => {
            const list = tagList.slice( 0 );
            list.splice( index, 1 );
            return list;
        } );
    };

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            parseRequestErrorResponse( err.response.data );
            return;
        }
        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function parseRequestErrorResponse ( err ) {
        switch ( err.code ) {
            case ErrorCodes.Validation:
            case ErrorCodes.InvitationUserExistsOtherAccount:
                handleValidationError( err );
                break;

            case ErrorCodes.Invitation:
                handleInvitationError( err );
                break;

            default:
                setFormPostError( {
                    message: ErrorMessages.UnknownError
                } );
        }
    }

    function handleValidationError ( err ) {
        const _tags = tags.concat();
        const invalidValues = getInvalidPostValues( err.data );

        _tags.forEach( tag => {
            if ( invalidValues.includes( tag.name ) ) {
                tag.isValid = false;
            }
        } );

        setFormPostError( {
            message: err.message,
            errorList: getErrorList( err.data )
        } );
        setTags( _tags );
    }

    function handleInvitationError ( err ) {
        const successfulInvites = [];
        const failedInvites = [];

        const invalidValues = getInvalidPostValues( err.data );
        tags.forEach( tag => {
            const ctx = invalidValues.includes( tag.name ) ? failedInvites : successfulInvites;
            ctx.push( tag );
        } );

        setFormPostError( {
            // message: err.message,
            customMessage: (
                <div className="text-near-black">
                    {successfulInvites.length > 0 &&
                        <div className="p-4 mb-2 bg-success-lightest">
                        <span className="flex items-center text-success text-sm font-bold mb-1">
                            <Checkmark width="16" height="16" className="mr-1"/> {t( 'label.succeeded',
                            _.label.succeeded )}
                        </span>
                            {successfulInvites.map( tag =>
                                <div key={tag.name} className="font-medium">{tag.name}</div>
                            )}
                    </div>
                    }
                    <div className="p-4 bg-fail-lightest">
                        <span className="flex items-center text-fail text-sm font-bold mb-1">
                            <Cross width="16" height="16" className="mr-1"/> {t( 'label.failed', _.label.failed )}
                        </span>
                        <p className="text-sm font-medium leading-snug">{err.message}</p>
                        {failedInvites.map( tag =>
                            <div key={tag.name} className="font-medium">{tag.name}</div>
                        )}
                    </div>
                </div>
            )
        } );
        setTags( failedInvites );
    }

    return (
        <Modal
            title={t( 'userManagement.label.inviteFormTitle', _.userManagement.label.inviteFormTitle )}
            size="md"
            onClose={props.onClose}
            isOpen={props.isOpen}>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <h3 className="subheading-base">{t( 'userManagement.label.role',
                        _.userManagement.label.role )}*</h3>
                    <RolesOptionList onChange={handleRoleChange}/>
                    <h3 className="subheading-base">{t( 'userManagement.label.sitePermissions',
                        _.userManagement.label.sitePermissions )}*</h3>
                    <PermissionOptionList
                        onChange={handlePermissionChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.values.role === Roles.PARTNER_ADMIN}
                        error={formik.touched.permissions && formik.errors.permissions}
                        availableFeatures={props.availableFeatures}/>
                    <h3 className="subheading-base">{t( 'userManagement.label.preferredRegion',
                        _.userManagement.label.preferredRegion )}</h3>
                    <RegionOptionList
                        onBlur={formik.handleBlur}
                        error={formik.touched.regions && formik.errors.regions}/>
                    {formPostError &&
                        <FormPostError {...formPostError} />
                    }
                    <h3 className="subheading-base">{t( 'label.emailAddress', _.label.emailAddress )}*</h3>
                    <label htmlFor="invitelist">{t(
                        'label.acceptMultipleEmail',
                        _.label.acceptMultipleEmail
                    )}</label>
                    <p className="leading-tight text-grey-darker mb-2 text-caption">{t(
                        'userManagement.label.inviteEmailCaption',
                        _.userManagement.label.inviteEmailCaption
                    )}</p>
                    <div
                        className="input-group items-center flex md:flex-nowrap w-full p-1 border border-solid border-grey-lighter rounded">
                        <TokenInput
                            ref={reactTags}
                            classNames={tokenInputClassNames}
                            name="invitelist"
                            placeholderText={t( 'label.emailAddressHint', _.label.emailAddressHint )}
                            allowNew={true}
                            autoresize={false}
                            addOnBlur={true}
                            delimiters={['Enter', ',']}
                            tags={tags}
                            tagComponent={Token}
                            onInput={setQuery}
                            onAddition={onAddTag}
                            onDelete={onRemoveTag}
                        />
                        <Button className="button w-full md:w-auto md:ml-1"
                                type="submit"
                                loading={isProcessing}
                                disabled={isSubmitDisabled || isProcessing}>
                            {isProcessing
                                ? t( 'actionLabel.sending', _.actionLabel.sending )
                                : t( 'actionLabel.invite', _.actionLabel.invite )
                            }
                        </Button>
                </div>
                    {hasInvalidTag( tags ) &&
                        <p className="text-xs font-semibold text-fail leading-tight">
                            {t(
                                'account.notify.oneOrMoreInvalidEmailAddresses',
                                _.account.notify.oneOrMoreInvalidEmailAddresses
                            )}
                        </p>
                    }
                </form>
            </FormikProvider>
        </Modal>
    );
}

InviteUsers.defaultProps = {
    isOpen: false,
    onClose: () => {},
    tags: [],
    availableFeatures: []
}
