const PlayVideoIcon = ( {size = 40} ) =>
    <div
        className="absolute-center w-full h-full bg-dark text-white font-bold opacity-60 hover:opacity-100 t-opacity t-duration-short">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 128 128"
             width={size} height={size}
             className="absolute-center"
             fill="none">
            <path fill="#fff" fillRule="evenodd" clipRule="evenodd"
                  d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0Zm0 124C30.9 124 4 97.1 4 64S30.9 4 64 4s60 26.9 60 60-26.9 60-60 60Z"/>
            <path fill="#fff" fillRule="evenodd" clipRule="evenodd"
                  d="M100.3 66.2 47.6 96.3c-.4.2-.8.3-1.2.3-.4 0-.9-.1-1.3-.3-.8-.5-1.2-1.3-1.2-2.2V33.9c0-.9.5-1.7 1.2-2.2.8-.4 1.7-.5 2.5 0l52.7 30.1c.8.5 1.3 1.3 1.3 2.2 0 .9-.5 1.7-1.3 2.2Z"/>
            <path fill="#000" fillRule="evenodd" clipRule="evenodd" opacity=".6"
                  d="M64 4C30.9 4 4 30.9 4 64s26.9 60 60 60 60-26.9 60-60S97.1 4 64 4Zm36.3 62.2L47.6 96.3c-.4.2-.8.3-1.2.3-.4 0-.9-.1-1.3-.3-.8-.5-1.2-1.3-1.2-2.2V33.9c0-.9.5-1.7 1.2-2.2.8-.4 1.7-.5 2.5 0l52.7 30.1c.8.5 1.3 1.3 1.3 2.2 0 .9-.5 1.7-1.3 2.2Z"/>
        </svg>
    </div>

export {PlayVideoIcon}
