function createTabs ( parent ) {
    // bypass if already initialized through React component
    if ( parent.dataset.tabs === 'react' ) {
        console.warn( '[Tabs.js] Exited on React controlled Tab set' )
        return;
    }

    const tabs = parent.querySelectorAll( ':scope > [data-tab-list] > [data-tab-target]' );
    const tabContents = parent.querySelectorAll( ':scope > [data-tab-content]' );

    function getTabById ( id ) {
        return parent.querySelector( `:scope > [data-tab-list] > [data-tab-target="${id}"]` );
    }

    function selectTab ( targetTab ) {
        tabs.forEach( tab => {
            tab.classList.remove( 'active' );
            tab.setAttribute( 'aria-selected', false );
        } );
        tabContents.forEach( tabContent => {
            tabContent.classList.remove( 'active' );
        } );

        const targetContent = Array.from( tabContents )
            .find( item => item.dataset.tabContent === targetTab.dataset.tabTarget );

        if ( targetContent ) {
            targetTab.classList.add( 'active' );
            targetTab.setAttribute( 'aria-selected', true );
            targetContent.classList.add( 'active' );

            window.location.hash = targetTab.dataset.tabTarget;
        }
    }

    function applyA11y () {
        tabs.forEach( tab => {
            tab.setAttribute( 'type', 'button' );
            tab.setAttribute( 'role', 'tab' );
            tab.setAttribute( 'aria-controls', tab.dataset.tabTarget );
            tab.setAttribute( 'aria-selected', false );
        } );

        tabContents.forEach( tabPanel => {
            tabPanel.setAttribute( 'role', 'tabpanel' );
            tabPanel.setAttribute( 'tabindex', '0' );
            tabPanel.setAttribute( 'aria-labelledby', tabPanel.dataset.tabContent )
        } );
    }

    tabs.forEach( tab => {
        console.log( 'tab', tab )
        tab.addEventListener( 'click', e => selectTab( e.currentTarget ) );
    } );
    // initialize with first tab
    applyA11y();
    const tab = getTabById( window.location.hash?.substring( 1 ) );
    selectTab( tab || tabs[0] );

    return {
        setTabByIndex: ( index ) => {
            const tab = tabs[index];
            if ( !tab ) {
                console.error( `[Tab:Error] Tab index "${index}" not found` );
                return;
            }
            selectTab( tab );
        },
        setTab: ( id ) => {
            const tab = getTabById( id );
            if ( !tab ) {
                console.error( `[Tab:Error] Tab "${id}" not found` );
                return;
            }
            selectTab( tab );
        }
    };
}

export {
    createTabs
}
