import React, {useState} from 'react';
import {FormikProvider, useFormik} from 'formik';
import {Button, FormFeedback, t} from '@ultradent/components';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {trimFormData, validateEmail} from '@ultradent/utilities/Strings'
import {ErrorCodes, ErrorMessages} from '@/util/Errors';
import EquipmentRepairService from '@/providers/equipment-repair';
import {EquipmentRepairFields} from './EquipmentRepairFields';
import {translationKeys as _} from '@/constants/translations';

const EquipmentRepairApp = ( props ) => {

    const [attachments, setAttachments] = useState( [] );
    const [isProcessing, setIsProcessing] = useState( props.processing );
    const [formPostError, setFormPostError] = useState( null );
    const [formSubmitted, setFormSubmitted] = useState( false );

    const formik = useFormik( {
        enableReinitialize: true,
        validateOnChange: true,
        validate: ( values ) => {
            const errors = {};
            const valuesTrimmed = trimFormData( values );
            const {
                accountName,
                accountNumber,
                address,
                fullName,
                email,
                serialNumber,
                description
            } = valuesTrimmed;

            if ( !accountName ) {
                errors.accountName = t(
                    'equipmentRepair.message.accountName',
                    _.equipmentRepair.message.accountName
                );
            }
            if ( !accountNumber ) {
                errors.accountNumber = t(
                    'equipmentRepair.message.accountNumber',
                    _.equipmentRepair.message.accountNumber
                )
            }
            if ( !address ) {
                errors.address = t(
                    'equipmentRepair.message.address',
                    _.equipmentRepair.message.address
                )
            }
            if ( !fullName ) {
                errors.fullName = t(
                    'equipmentRepair.message.fullName',
                    _.equipmentRepair.message.fullName
                );
            }
            if ( !email || !validateEmail( email ) ) {
                errors.email = t(
                    'equipmentRepair.message.email',
                    _.equipmentRepair.message.email
                );
            }
            if ( !serialNumber ) {
                errors.serialNumber = t(
                    'equipmentRepair.message.serialNumber',
                    _.equipmentRepair.message.serialNumber
                );
            }
            if ( !description ) {
                errors.description = t(
                    'equipmentRepair.message.description',
                    _.equipmentRepair.message.description
                );
            }

            return errors;
        },
        initialValues: {
            accountName: '',
            address: '',
            accountNumber: '',
            fullName: '',
            email: '',
            serialNumber: '',
            description: '',
            additionalInformation: ''
        },
        onSubmit: async ( values ) => {
            setFormPostError( null );
            const errors = await formik.validateForm( values );

            if ( !isProcessing && isEmptyOrNil( errors ) ) {
                setIsProcessing( true );

                try {
                    // our apis expect a Base64 encoded string; we need to do some cleanup by removing the
                    // data:*/* encoding declaration at the beginning of the dataUri returned
                    // result.replace( /data:.*;base64,/gm, '' )
                    const requestBody = {
                        ...values,
                        attachments: attachments.map( ( {fileName, data} ) => ({
                            fileName,
                            base64: data.replace( /data:.*;base64,/gm, '' )
                        }) )
                    };

                    await EquipmentRepairService.submitRepairRequest( requestBody );
                    setFormSubmitted( true );
                }
                catch ( err ) {
                    handleRequestError( err );
                }
                finally {
                    setIsProcessing( false );
                }
            }

            return false;
        }
    } );

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            parseRequestErrorResponse( err.response.data );
            return;
        }

        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function parseRequestErrorResponse ( err ) {
        if ( err.code === ErrorCodes.Validation ) {
            handleValidationError( err );
            return;
        }
        setFormPostError( {
            message: ErrorMessages.UnknownError
        } );
    }

    function handleValidationError ( err ) {
        /*{
            "code": "ValidationError",
            "message": String,
            "data": [{ "value": String, "issue": Sting }]
        }*/
        setFormPostError( {
            message: err.message,
            errors: err.data.map( ( {value, issue} ) => `${value} ${issue}` )
        } );
    }

    function handleAddAttachment ( {fileName, data} ) {
        setAttachments( current => [...current, {fileName, data}] )
    }

    function handleRemoveAttachment ( index ) {
        setAttachments( current => [
            ...current.slice( 0, index ),
            ...current.slice( index + 1 )
        ] )
    }

    function handleReset () {
        formik.resetForm();
        setAttachments( [] );
        setFormSubmitted( false );
    }

    return (
        <FormikProvider value={formik}>
            {formSubmitted
                ?
                <div className="max-w-sm mx-auto py-12 text-center">
                    <h3 className="headline-sm">
                        {t( 'equipmentRepair.message.successHeadline', _.equipmentRepair.message.successHeadline )}
                    </h3>
                    <p className="text-body mb-8">
                        {t( 'equipmentRepair.message.successSubheading', _.equipmentRepair.message.successSubheading )}
                    </p>
                    <Button className="button lg w-full md:w-auto" onClick={handleReset}>
                        {t( 'actionLabel.submitNewRequest', _.actionLabel.submitNewRequest )}
                    </Button>
                </div>
                :
                <form onSubmit={formik.handleSubmit} className="max-w-sm mx-auto">
                    {formPostError && <FormFeedback className="bg-fail text-white" {...formPostError} />}
                    <FormFeedback className="bg-fail text-white" {...formik}/>
                    <EquipmentRepairFields
                        {...formik}
                        onAddAttachment={handleAddAttachment}
                        onRemoveAttachment={handleRemoveAttachment}
                        attachments={attachments}/>
                    <Button className="button w-full md:w-auto md:ml-1"
                            type="submit"
                            loading={isProcessing}
                            disabled={isProcessing}>
                        {isProcessing
                            ? t( 'actionLabel.sending', _.actionLabel.sending )
                            : t( 'actionLabel.submitRequest', _.actionLabel.submitRequest )
                        }
                    </Button>
                </form>
            }

        </FormikProvider>
    );
}

export {EquipmentRepairApp}
