import {useRef} from 'react';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import useAssetLoadError from '@/apps/ResourceCenter/Assets/useAssetLoadError';
import {PlayVideoIcon} from '@/components/common/Icons/PlayVideoIcon';

export const AssetVideo = ( {id, stream, name, className} ) => {
    const player = useRef();
    const {onError} = useAssetLoadError( {id, name} );
    const assetStyle = cx( 'react-player', className );

    return (
        <ReactPlayer
            ref={player}
            data-testid="AssetVideo-Player"
            className={assetStyle}
            config={{
                file: {forceDASH: true}
            }}
            playIcon={<PlayVideoIcon/>}
            onError={onError}
            light={false}
            volume={0.33}
            width="100%"
            height="auto"
            preload="none"
            controls={true}
            // note -> it's important to append "(format=mpd-time-cmaf)" to the end of manifest path for dash player to load format properly
            url={`${stream}(format=mpd-time-cmaf)`}
            // url={`https://mediavaletnawams01.streaming.mediaservices.windows.net/2e9e436b-4a0f-41d2-907d-73feb4e613ae/manifest.ism/manifest(format=mpd-time-cmaf)`}
        />
    )
}
