import flatten from 'ramda/src/flatten';
import {CardActions, CardContent} from '@/components/common/Card';
import {ModalLink} from '@/components/common/Routing/ModalLink';
import {AssetThumbnail} from '@/apps/ResourceCenter/Assets/AssetThumbnail';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {AssetTagList} from '@/apps/ResourceCenter/Assets/AssetTagList';
import {BookmarkAdd, BookmarkFilled, Share} from '@/components/common/Icons';
import {AssetDownloadDialog} from '@/components/common/AssetDownload';
import useAssetDownload from '@/components/common/AssetDownload/useAssetDownload';
import {AssetDownloadIconRenderer} from '@/components/common/AssetDownload/AssetDownloadRenderer';
import {t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {BookmarksContext} from '@/containers/Bookmarks';
import {useContext} from 'react';
import {useAssetSharing} from '@/containers/AssetSharing/useAssetSharing';

export const AssetClipboardItem = props => {
    const {
        id,
        additional,
        scheme,
        mimetype,
        fileExtension,
        sizes,
        name,
        tag,
        productFamilies,
        products,
        assetCategory,
        assetType
    } = props;

    const assetDownloadApi = useAssetDownload( {
        assets: [{id, additional, scheme, name, sizes, mimetype, fileExtension}]
    } );
    const categories = flatten( [productFamilies, products, assetCategory, assetType].filter( Boolean ) );

    const {bookmarks, onAddBookmarkItem, onRemoveBookmarkItem} = useContext( BookmarksContext );
    const {onShareAssets} = useAssetSharing();
    const isBookmarked = bookmarks && notEmptyOrNil( bookmarks.find( item => item.id === id ) );

    return (
        <>
            <CardContent>
                <div className="mb-2 relative">
                    <ModalLink root="/resources" to={`/assets/${id}`} className="block">
                        <AssetThumbnail imgRatio="16x9" name={name} id={id} isPreviewOnly={true} scheme={scheme}/>
                    </ModalLink>
                </div>
                <h3 className="text-title mb-2">
                    <ModalLink root="/resources" to={`/assets/${id}`} className="link-hover">{additional?.AssetTitle ??
                        name}</ModalLink>
                </h3>
                {notEmptyOrNil( categories ) &&
                    <p title={categories.join( ', ' )}
                       className="cursor-help text-grey-darker text-sm md:text-body-sm mt-0 mb-2 line-clamp-3">
                        {t( 'asset.label.appearsIn', _.asset.label.appearsIn )} {categories.join( ', ' )}
                    </p>
                }
                {notEmptyOrNil( tag ) && <AssetTagList tags={tag}/>}
            </CardContent>
            <CardActions className="justify-end">
                {isBookmarked
                    ? <Tooltip content={t( 'menu.removeBookmark', _.menu.removeBookmark )}>
                        <button
                            aria-label={t( 'menu.removeBookmark', _.menu.removeBookmark )}
                            className="p-2 link-2"
                            onClick={onRemoveBookmarkItem( props )}>
                            <BookmarkFilled width="24"/>
                        </button>
                    </Tooltip>
                    : <Tooltip content={t( 'menu.addBookmark', _.menu.addBookmark )}>
                        <button
                            aria-label={t( 'menu.addBookmark', _.menu.addBookmark )}
                            className="p-2 hover:link-2"
                            onClick={onAddBookmarkItem( props )}>
                            <BookmarkAdd width="24"/>
                        </button>
                    </Tooltip>
                }
                <Tooltip content="Share">
                    <button aria-label={t( 'actionLabel.share', _.actionLabel.share )}
                            onClick={onShareAssets( props )} className="p-2 hover:link-2">
                        <Share width="24"/>
                    </button>
                </Tooltip>
                <AssetDownloadDialog
                    assetDownloadApi={assetDownloadApi}
                    className="inline-block p-2 link-2 relative">
                    {AssetDownloadIconRenderer}
                </AssetDownloadDialog>
            </CardActions>
        </>
    )
}
