function toFacetKey ( f ) {
    return `${f.groupId}:${f.id}`
}

export const searchReducer = ( state, action ) => {
    let facets = null;
    let targetKey = null;

    switch ( action.type ) {
        case 'newQuery':
            return {
                ...state,
                startAt: 0,
                processing: true
            };

        case 'loadMore':
            return {
                ...state,
                startAt: state.startAt + state.itemsPerPage,
                loadingMore: true,
                isDirty: true
            };

        case 'setFacetDrawer':
            return {
                ...state,
                isFacetDrawerActive: action.payload.facetDrawerActive
            };

        case 'setOrderBy':
            return {
                ...state,
                orderBy: action.payload.orderBy,
                isDirty: true
            };

        case 'setStart':
            return {
                ...state,
                startAt: Number( action.payload.startAt )
            };

        case 'receivedResults':
            const results = state.results || [];
            return {
                ...state,
                results: Number( state.startAt ) === 0
                    ? action.payload.nextResults
                    : results.concat( action.payload.nextResults ),
                lastKeyword: action.payload.lastKeyword.trim(),
                isDirty: false,
                loadingMore: false,
                processing: false
            };

        case 'updateKeyword':
            // note -> keyword updates should clear out all facets but retain the category filter
            return {
                ...state,
                selectedFacets: [],
                orderBy: '',
                keyword: action.payload.keyword.trim(),
                isDirty: Boolean( action.payload.dirty )
            };

        case 'updateSelectedFacets':
            return {
                ...state,
                selectedFacets: action.payload.facets,
                isDirty: Boolean( action.payload.dirty )
            };

        case 'updateSelectedCategory':
            return {
                ...state,
                // category path e.g. ['Images','Clinical photo'] -> AssetCategory=Images&AssetType=Clinical%20photo
                selectedCategories: action.payload.categoryPath?.filter( Boolean ),
                isDirty: Boolean( action.payload.dirty )
            }

        case 'clearAllCategories':
            return {
                ...state,
                startAt: 0,
                selectedCategories: null,
                isDirty: true
            }

        case 'addSelectedFacet':
            targetKey = toFacetKey( action.payload );
            const hasFacet = state.selectedFacets.find( facet => toFacetKey( facet ) === targetKey );
            if ( hasFacet ) {
                return state;
            }

            return {
                ...state,
                startAt: 0,
                selectedFacets: state.selectedFacets.concat( action.payload.facet ),
                isDirty: Boolean( action.payload.dirty )
            }

        case 'removeSelectedFacet':
            facets = state.selectedFacets || [];
            targetKey = toFacetKey( action.payload.facet );

            return {
                ...state,
                startAt: 0,
                selectedFacets: facets.filter( facet => toFacetKey( facet ) !== targetKey ),
                isDirty: Boolean( action.payload.dirty )
            }

        case 'clearAllFilters':
            return {
                ...state,
                startAt: 0,
                selectedFacets: [],
                isDirty: true
            };

        default:
            throw Error( `Unknown action "${action.type}" specified for queryReducer for SearchResults` );
    }
}
