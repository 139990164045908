import {guid} from '@ultradent/utilities/Guid';
import React from 'react';

const FormPostError = ( {message, errorList, customMessage, children} ) =>
    <div className="my-4 text-fail text-base leading-snug">
        {children}
        {message &&
            <h3 className="mb-2 font-medium text-sm">{message}</h3>
        }
        {Array.isArray( errorList ) &&
            <ul className="bullets-x m-0 p-0">
                {errorList.map( error =>
                    <li key={guid()} className="text-sm font-medium">{error}</li>
                )}
            </ul>
        }
        {customMessage}
    </div>;

export default FormPostError;
