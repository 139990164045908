import {translationKeys as _} from '@/constants/translations';
import {Card} from '@/components/common/Card';
import {Cross} from '@/components/common/Icons';
import {t, Tooltip} from '@ultradent/components';

const removeLabel = t( 'clipboard.actionLabel.removeItem', _.clipboard.actionLabel.removeItem );

export const ClipboardItemCard = ( {children, onRemove} ) =>
    <Card actionPosition="bottom" data-testid="ClipboardItem">
        <Tooltip content={removeLabel}>
            <button
                aria-label={removeLabel}
                onClick={onRemove}
                className="absolute z-10 top-1 right-1 p-2 bg-white rounded hover:text-fail">
                <Cross width="16"/>
            </button>
        </Tooltip>
        {children}
    </Card>
