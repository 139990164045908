import PropTypes from 'prop-types';
import {Search, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const SEARCH_RESULTS_CLASS = 'absolute z-10 py-2 w-full bg-white shadow-3 border border-grey-lightest border-1 rounded-b-lg rounded-t-sm';
const SEARCH_GROUP_LABEL_CLASS = 'px-4 py-1 italic mt-2 text-sm text-grey border-t border-grey-lightest';
const SUGGESTION_ITEM_CLASS = 'group w-full flex items-center px-4 py-1 cursor-pointer';
const SUGGESTION_ITEM_HINT_CLASS = 'flex items-center text-grey-dark text-sm opacity-0 group-focus:opacity-100';

export const SuggestionItem = ( {children} ) =>
    <div className={SUGGESTION_ITEM_CLASS}>
        <div className="flex-1">
            {children}
        </div>
        <div className={SUGGESTION_ITEM_HINT_CLASS}>
            <span className="mr-2">{t( 'search.suggestion.pressEnter', _.search.suggestion.pressEnter )}</span>
            <img className="opacity-75" width="18" src="/images/svg/icons/keystroke-enter.svg"/>
        </div>
    </div>

const SearchBar = ( {
                        querySuggestionsRepository,
                        queryGroup,
                        initialQuery,
                        placeholder = '',
                        onResultSelect,
                        prependSearchSlot,
                        onSubmit
                    } ) => {

    return (
        <Search
            minQueryLength={1}
            initialQuery={initialQuery}
            showNoResults={false}
            hideOnResultSelect={true}
            hideOnFocusout={true}
            onSubmit={onSubmit}
            queryProvider={async ( query ) => {
                const resp = await querySuggestionsRepository.querySuggestions( {query} );
                return [
                    {results: resp.data?.queries, resultLimit: 7},
                    {results: resp.data?.documents, resultLimit: 5}
                ];
            }}
            queryGroup={queryGroup}
            onResultSelect={onResultSelect}>
            {searchProps =>
                <div className="relative">
                    <div className="w-full flex">
                        <Search.Input
                            placeholder={placeholder}
                            showSubmitAction={true}
                            prependSlot={prependSearchSlot}
                        />
                    </div>
                    <Search.Results activeClass="text-brand focus" groupLabelClass={SEARCH_GROUP_LABEL_CLASS}>
                        {results =>
                            <div className={SEARCH_RESULTS_CLASS}>
                                {results}
                            </div>
                        }
                    </Search.Results>
                </div>
            }
        </Search>
    );
}

SearchBar.propTypes = {
    queryGroup: PropTypes.array.isRequired,
    prependSearchSlot: PropTypes.node
};
SearchBar.defaultProps = {
    initialQuery: '',
    onResultSelect: () => {},
    onSubmit: () => {}
};

export {SearchBar}
