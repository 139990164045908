import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {toCamelCase} from '@ultradent/utilities/Strings';
import flatten from 'ramda/src/flatten';
import groupBy from 'ramda/src/groupBy';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import compose from 'ramda/src/compose';
import join from 'ramda/src/join';

/**
 * @example
 * Transforms list from
 * [
 *      {Id: '1', Name: 'A', Children: [
 *          {Id: '11', Name: 'A1'},
 *          {Id: '12', Name: 'A2'}
 *      ]},
 *      {Id: '2', Name: 'B'}
 * ]
 *  to
 * [
 *      {id: '1', name: 'A'},
 *      {id: '11', name: 'A1', parent: 'A', hasParent: true},
 *      {id: '22', name: 'A2', parent: 'A', hasParent: true},
 *      {id: '2', name: 'B'},
 * ]
 * @param list
 * @returns {[]}
 */
export const transformCategoryList = ( {list, selections} ) => {
    if ( isEmptyOrNil( list ) ) {
        return [];
    }
    // if arry, get last item in this list
    const currentCategory = Array.isArray( selections ) && [].concat( selections ).reverse()[0];

    return list.reduce( ( acc, parent ) => {
        acc.push( {
            id: parent.Id,
            name: parent.Name,
            isSelected: parent.Id === currentCategory
        } );

        if ( notEmptyOrNil( parent.Children ) ) {
            const additions = parent.Children.map( child => ({
                parentId: parent.Id,
                parent: parent.Name,
                id: child.Id,
                name: child.Name,
                isSelected: child.Id === currentCategory,
                hasParent: true
            }) );
            return acc.concat( additions );
        }
        return acc;
    }, [] );
};

// convert object properties to camelCase
export const transformObjectKeys = ( obj ) => {
    if ( isEmptyOrNil( obj ) ) {
        return {};
    }

    const newObj = {};

    for ( const [key, value] of Object.entries( obj ) ) {
        newObj[toCamelCase( key )] = value;
    }

    return newObj;
};

/**
 * @example
 * Transforms object from
 * [
 *     { Name: 'groupName", Id: 'groupId', "Values": {"Astringedent X": 18, "Astringedent": 16}
 * [
 *  to
 * {
 *      groupTitle: 'Some Group Title',
 *      list: [
 *          {id: '1', name: 'someGroupTitle', title: 'Opalescence Go', resultCount: 1, selected: Boolean},
 *          {id: '2', name: 'someGroupTitle', title: 'Opal Bond', resultCount: 2}
 *      ]
 * }
 * @param obj
 * @returns {[]}
 */
export const transformFacetList = ( {list, selections} ) => {
    if ( isEmptyOrNil( list ) ) {
        return [];
    }

    return list.map( ( {Name, ID, Values} ) => notEmptyOrNil( Values ) && ({
        groupTitle: Name,
        id: ID,
        list: formatFacetList( {
            groupId: ID,
            groupName: Name,
            values: Values,
            selections
        } )
    }) ).filter( Boolean ); // filter out facets which have an empty "Values" list
};

const formatFacetList = ( {groupId, groupName, values = {}, selections} ) => {
    let list = [];

    if ( isEmptyOrNil( values ) ) {
        return list;
    }

    list = values.map( ( {ID, Name, ResultCount} ) => createFacetLine( {
        id: ID,
        displayName: Name,
        groupId,
        groupName,
        resultCount: ResultCount,
        selected: !isEmptyOrNil( selections.find( facetLine => facetLine.id === ID && facetLine.groupId === groupId ) )
    } ) );

    return list.sort( ( a, b ) => {
        if ( a.resultCount > b.resultCount ) {
            return -1;
        }
        return a.resultCount < b.resultCount ? 1 : 0;
    } );
};

const createFacetLine = ( {id, displayName, groupId, groupName, resultCount, selected = false} ) => ({
    id,
    groupId, // for input="name"
    groupName,
    title: displayName,
    resultCount,
    selected
});

export function getSelectedFilters ( list ) {
    if ( isEmptyOrNil( list ) ) {
        return [];
    }

    const selections = list.map( ( grp ) => {
        return grp.Values
            .filter( f => f.Selected )
            .map( f => createFacetLine( {
                id: f.ID,
                displayName: f.Name,
                groupId: grp.ID,
                groupName: grp.Name,
                resultCount: f.ResultCount,
                selected: f.Selected
            } ) )
    } );

    return flatten( selections );
}

/**
 * Helpers for constructing a queryString for search
 */
const encodeId = compose( encodeURIComponent, prop( 'id' ) );
const joinLikeParams = compose( join( '|' ), map( encodeId ) );
const toParamGroup = ( [key, list] ) => `${encodeURIComponent( key )}=${joinLikeParams( list )}`;
const toParamCollection = groups => map( toParamGroup, Object.entries( groups ) );
const joinAllParams = compose( join( '&' ), toParamCollection, groupBy( prop( 'groupId' ) ) );
const toCategoryFacets = ( categoryPath = [] ) => {
    if ( !categoryPath ) {
        return [];
    }
    const categoryMap = ['AssetCategory', 'AssetType'];
    return categoryPath.map( ( id, i ) => ({
        id,
        groupId: categoryMap[i]
    }) );
};

/**
 * Accept a query and list of facet groups and convert to a query string for search api
 * @param facets = Array [{groupId: 'Type', id: 'dog'}, {groupId: 'Type', id: 'cat'}, {groupId: 'Color', id: 'black'}]
 * @param categoryPath = Array = ['Images', 'Clinical photos']
 * @param keyword = String =  animal
 * @returns {string} = '?keyword=animal&type=dog|cat&color=black'
 */
export const formatQueryParams = ( {
                                       keyword,
                                       orderBy,
                                       categoryPath,
                                       facets = [],
                                       startAt = 0,
                                       itemsPerPage = 30
                                   } ) => {
    const _facet = Array.isArray( facets ) ? facets : [facets]
    let queryStr = `keyword=${encodeURIComponent( keyword )}`;
    const combinedFacets = _facet.concat( toCategoryFacets( categoryPath ) );
    const facetParams = joinAllParams( combinedFacets );

    if ( facetParams ) {
        queryStr += '&' + facetParams;
    }

    if ( orderBy ) {
        queryStr += `&orderBy=${orderBy}`
    }

    queryStr += `&start=${startAt}&limit=${itemsPerPage}`;

    return queryStr;
}

/**
 * Accepts a formatted string for search and parses to search object
 * @param searchStr String '?keyword=animal%20crackers&type=dog|cat&color=black'
 * @returns Object {query: 'animal crackers', facets: [{groupId: 'Type', id: 'dog'}, {groupId: 'Type', id: 'cat'}, {groupId: 'Color', id: 'black'}]}
 */
export const parseQueryParams = ( searchStr ) => {
    const searchQuery = {};

    // remove leading "?" and create facet parts
    const parts = searchStr.substr( 1 ).split( '&' );

    // first item is always "Query"
    searchQuery.keyword = parts.shift().split( '=' )[1];
    // split combine facet groups into single list tagging with appropriate groupId
    searchQuery.facets = parts.map( f => {
        const facetParts = decodeURIComponent( f ).split( '=' );
        const groupId = facetParts[0];
        return facetParts[1].split( '|' ).map( id => ({groupId, id}) );
    } )

    searchQuery.facets = flatten( searchQuery.facets );
    return searchQuery;
}

export const transformAssetModel = ( data ) => {
    const model = {
        id: data.Id,
        isNew: data.IsNew,
        dateApproved: data.DateApproved,
        additional: data.Additional,
        name: data.Name,
        assetType: data.AssetType,
        description: data.Description,
        sku: data.Sku,
        productFamilies: data.ProductFamilies,
        products: data.Products,
        mimetype: data.MimeType,
        fileExtension: data.FileExtension,
        formats: [data.FileExtension],
        scheme: data.Scheme,
        size: data.Size,
        sizes: data.Sizes?.map( transformAssetSizes ) || [],
        tag: data.Tag,
        relatedAssets: data.RelatedAssets
    };

    if ( 'Valid' in data ) {
        model.valid = data.Valid;
    }

    if ( 'valid' in data ) {
        model.valid = data.valid;
    }

    return model;
};

export const transformAssetSizes = ( {Alias, FileSize, Width, Height, Resolution, Mimetype, Url} ) => ({
    alias: Alias,
    fileSize: FileSize,
    width: Width,
    height: Height,
    resolution: Resolution,
    mimetype: Mimetype,
    url: Url
});

export const transformToDictionary = ( obj ) => {
    const dict = {};

    for ( const [keyA, valueA] of Object.entries( obj ) ) {
        if ( typeof valueA === 'object' ) {
            const temp = transformToDictionary( valueA );
            for ( const [keyB, valueB] of Object.entries( temp ) ) {
                dict[`${keyA}.${keyB}`] = valueB;
            }
            continue;
        }

        dict[keyA] = valueA;
    }

    return dict;
}
