import PropTypes from 'prop-types';
import {Checkmark, MenuVertical, UserPending, Users} from '@/components/common/Icons';
import {SkeletonElement, t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const companyLineCss = 'group w-full flex items-center border-b border-solid border-grey-lightest p-4 hover:bg-eggshell-white';
const companyLineAvatarCss = 'hidden lg:block flex-none mr-4 text-center leading-10 w-10 h-10 bg-grey text-near-white text-lg rounded-full';
const companyLineTitleCss = 'subheading-base leading-tight lg:w-1/3 mr-8 flex-none';
const companyLineDetailCss = 'flex-wrap flex-auto text-base mb-4 lg:mr-8 lg:mb-0';
const companyLineDetailUserCss = 'text-title mr-4';
const companyLineDetailOfficeCss = 'lg:flex flex-wrap mr-4';

export const toCompanyInitials = ( str ) => {
    if ( !str ) {
        return 'UK';
    }
    const parts = str.split( ' ' );
    let alias = parts[0].charAt( 0 );
    if ( parts[1] ) {
        alias += parts[1].charAt( 0 );
    }
    return alias.toUpperCase();
}

const AccountLine = ( {AccountId, Name, Email, Phone, Region, InviteStatus = 'inactive', ActiveUserCount = 0} ) =>
    <div key={AccountId} className={companyLineCss}>
        <div className="w-full lg:flex justify-start items-center">
            <div className={companyLineAvatarCss}>{toCompanyInitials( Name )}</div>
            <h3 className={companyLineTitleCss}>
                <a className="hover:link-2-underline capitalize"
                   href={`/partner-detail/${AccountId}`}>{Name.toLowerCase()}</a>
            </h3>
            <div className={companyLineDetailCss}>
                <div className={companyLineDetailUserCss}>
                    <EmailLine email={Email}/>
                </div>
                <div className={companyLineDetailOfficeCss}>
                    <PhoneLine phone={Phone}/>
                    <AddressLine region={Region}/>
                </div>
            </div>
        </div>
        <div className="flex items-center">
            <ActiveUserBadge activeUserCount={ActiveUserCount}/>
            <InviteStatusBadge status={InviteStatus} inviteUrl={`/partner-detail/${AccountId}#team-members`}/>
        </div>
        <div className="flex justify-end w-10">
            <Tooltip
                trigger="click"
                maxWidth={480}
                arrow={false}
                placement="bottom-end"
                interactive={true}
                theme="light"
                content={
                    <div className="flex flex-col w-40 text-base">
                        <a href={`/partner-detail/${AccountId}#team-members`} className="menu-context--item">
                            {t( 'accountLine.actionLabel.inviteMembers', _.accountLine.actionLabel.inviteMembers )}
                        </a>
                        <hr className="menu-context--divider"/>
                        <a href={`/partner-detail/${AccountId}`} className="menu-context--item">
                           {t( 'accountLine.actionLabel.manageAccount', _.accountLine.actionLabel.manageAccount )}
                        </a>
                    </div>
                }>
                <button className="cursor-pointer lg:invisible group-hover:visible active:visible focus:visible">
                    <MenuVertical width="24" height="24"/>
                </button>
            </Tooltip>


        </div>
    </div>;

AccountLine.Skeleton = () =>
    <div className={companyLineCss}>
        <div className="w-full lg:flex justify-start items-center">
            <SkeletonElement className={`${companyLineAvatarCss}`}/>
            <SkeletonElement className={`${companyLineTitleCss} w-64`} type="text"/>
             <div className={companyLineDetailCss}>
                <div className={companyLineDetailUserCss}>
                    <SkeletonElement className="w-24" type="text"/>
                </div>
                <div className={companyLineDetailOfficeCss}>
                    <SkeletonElement className="w-24" type="text"/>
                </div>
            </div>
        </div>
        <div className="flex items-center justify-end">
            <SkeletonElement className="w-32 h-10"/>
        </div>
    </div>;

AccountLine.propTypes = {
    AccountId: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    IsEligible: PropTypes.any.isRequired
};

export {AccountLine};

/**
 * Helpers
 */
const EmailLine = ( {email} ) =>
    email
        ? <a className="mr-4 link-2" href={`mailto:${email}`}>{email}</a>
        : <span className="font-normal italic">{t( 'accountLine.noEmail', _.accountLine.noEmail )}</span>;

const PhoneLine = ( {phone} ) =>
    phone && <div className="mr-4 whitespace-nowrap">{phone}</div>;

const AddressLine = ( {address, region} ) =>
    region && <div>{region}</div>;

const InviteStatusBadge = ( {status, inviteUrl} ) => {
    switch ( status ) {
        case 'pending':
            return <Tooltip content={t(
                'accountLine.label.invitePendingTip',
                _.accountLine.label.invitePendingTip )}>
                <span className="ml-6 badge-hollow text-blue">
                    {
                        t( 'accountLine.label.invitePending', _.accountLine.label.invitePending )
                    } <UserPending className="ml-2 inline-block text-blue" width="16" height="16"/>
                </span>
            </Tooltip>;

        case 'active':
            return <span className="ml-6 badge-hollow text-grey-darkest">
                {
                    t( 'accountLine.label.active', _.accountLine.label.active )
                } <Checkmark className="ml-2" width="14" height="14"/>
            </span>;

        default:
            return <a href={inviteUrl} className="ml-6 button sm tertiary whitespace-nowrap">
                {t( 'accountLine.actionLabel.inviteMembers', _.accountLine.actionLabel.inviteMembers )}
            </a>
    }
};

const ActiveUserBadge = ( {activeUserCount} ) => {
    if ( activeUserCount <= 0 ) {
        return null;
    }

    const tip = t(
        'accountLine.label.activeTip',
        _.accountLine.label.activeTip,
        {count: activeUserCount}
    );

    return <Tooltip content={tip}>
        <span className="flex items-center">
            <Users width="24" height="24"/>
            <span className="ml-2 font-semibold">{activeUserCount}</span>
        </span>
    </Tooltip>
}




