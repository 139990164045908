import React, {useEffect, useState} from 'react';
import {ArrowDown, ArrowUp} from '../Icons';
import cx from 'classnames';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {toSlug} from '@ultradent/utilities/Strings';
import {Show} from '@ultradent/components';

const TabTrigger = ( {slug, label, isActive, onClick} ) =>
    <a type="button"
       role="tab"
       href={`#${slug}`}
       data-tab-target={slug}
       aria-controls={slug}
       aria-selected={isActive ? 'true' : 'false'}
       className={cx( 'mr-4', {'active': isActive} )}
       onClick={onClick}>
        {label}
    </a>

const AccordionTrigger = ( {slug, label, isActive, onClick} ) => {
    const Indicator = isActive ? ArrowUp : ArrowDown;
    return (
        <a type="button"
           role="tab"
           href={`#${slug}`}
           data-tab-target={slug}
           aria-controls={slug}
           aria-selected={isActive ? 'true' : 'false'}
           className={cx(
               'md:hidden py-4 w-full flex justify-between items-center text-sm text-left leading-snug border-t border-grey-lightest',
               {
                   'active': isActive
               }
           )}
           onClick={onClick}>
            <span>{label}</span>
            <Indicator width="24"/>
        </a>
    );
}

export const TabPanel = ( {isActive, className, slug, children} ) =>
    <div data-tab-content="true"
         role="tabpanel"
         tabIndex="0"
         aria-labelledby={slug}
         className={cx( {'active': isActive}, className )}>
        {children}
    </div>

export const Tabs = ( {children, enableMobileAccordion} ) => {
    const [activeTab, setActiveTab] = useState( 0 );
    const [panels, setPanels] = useState( [] );
    const location = typeof window !== 'undefined' ? window.location : {};

    useEffect( () => {
        // filter out valid child sections based on whitelist
        const childPanels = children.reduce( ( acc, next ) => {
            if ( !next || !next.type ) {
                return acc;
            }

            if ( next.type === TabPanel ) {
                acc.push( React.cloneElement( next, {
                    ...next.props,
                    slug: toSlug( next.props.label )
                } ) );
            }

            return acc;
        }, [] );

        if ( childPanels.length > 0 ) {
            setPanels( childPanels );
            setActiveTab( childPanels[0].props.slug );
        }

    }, [] );

    useEffect( () => {
            const slug = notEmptyOrNil( location.hash )
                ? location.hash.substr( 1 )
                : panels[0]?.props.slug;

            if ( slug ) {
                setActiveTab( slug );
            }
        },
        [location.hash]
    );

    return (
        <div
            data-tabs="react">
            {enableMobileAccordion && <a id={activeTab}/>}
            {notEmptyOrNil( panels ) &&
                <nav data-tab-list={''} className={cx( 'flex-start mt-8', {
                    'flex': !enableMobileAccordion,
                    'hidden md:flex': enableMobileAccordion
                } )}>
                    {panels.map( ( {props: {label, slug}} ) =>
                        <TabTrigger key={slug}
                                    slug={slug}
                                    label={label}
                                    isActive={activeTab === slug}
                                    hideOnMobile={enableMobileAccordion}
                                    onClick={() => setActiveTab( slug )}
                        />
                    )}
                </nav>
            }
            {panels.map( panel => {
                const {props: {label, slug}} = panel;
                const isActive = activeTab === slug;

                return (
                    <div key={slug} className="relative">
                        <Show when={enableMobileAccordion}>
                            <AccordionTrigger slug={slug}
                                              label={label}
                                              isActive={isActive}
                                              onClick={() => setActiveTab( slug )}/>
                        </Show>
                        {React.cloneElement( panel, {...panel.props, isActive} )}
                    </div>
                )
            } )}
        </div>
    )
}
