const env = {
    COOKIE_DOMAIN: '.ultradent.com',
    API_ORIGIN: 'https://api.ultradent.com/partners/v1',
    CDN_ORIGIN: 'https://my.cdn.ultradent.com',
    UPI_TENANT_ID: 'f59af1b5-74af-43c1-85fc-99356f5e888a',
    PARTNER_PORTAL_APP_ID: '278db197-f6d3-4221-b53e-ed784f268eeb',
    PARTNER_PORTAL_API_APP_ID: 'e5a6ffa3-e325-4d6c-8674-58e62f3dc180',
    CRM_LOGIN_URL: 'https://ultradentintl.crm4.dynamics.com/main.aspx?appid=66bf10c3-7f4e-4d69-849a-76393a882e53',
    API_TOKEN: 'partner_token_my',
    API_TOKEN_ANON: 'anonymous_token',
    API_KEY: process.env.REACT_APP_API_KEY,
    APPLICATION_INSIGHTS: {
        connectionString: 'InstrumentationKey=a7f00393-74d8-4027-9967-466c46cd9dbc;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/'
    }
};

export default env;
