const pageModel = {
    heroImageSrc: '',
    pageTitle: 'Research & studies',
    pageSubtitle: 'Browse or search from hundreds of studies related to products developed and manufactured by Ultradent Products, Inc. to supplement your training and marketing materials.',
    popularTopics: {
        title: 'Popular topics to browse',
        subtitle: 'Not sure where to start? Here are a few of our most frequently searched topics to get you started.',
        tiles: [
            {
                productFamily: 'Tooth Whitening',
                topicKeyword: 'Whitening'
            },
            {
                productFamily: 'Prevent',
                topicKeyword: 'Prevent'
            },
            {
                productFamily: 'Equipment',
                topicKeyword: 'Equipment'
            },
            {
                productFamily: 'Etch & Bond',
                topicKeyword: 'Etch & Bond'
            }
        ]
    },
    popularStudies: {
        title: 'Popular studies',
        articleCollections: [
            {
                headline: 'Tooth whitening',
                subtitle: 'These are some of our most popular studies on Tooth Whitening',
                articles: [
                    {
                        'product': 'Opalustre',
                        'title': 'Clinical effectiveness of two microabrasion materials for the removal of enamel fluorosis stains.',
                        'citation': 'Loguercio AD, Correia LD, Zago C, Tagliari D, Neumann E, Gomes OM, Barbieri DB, Reis A. per Dent. 2007 Nov-Dec;32(6):531-8. doi: 10.2341/06-172. PMID: 18051001.',
                        'claim': 'The micro-abrasion technique study on 10-12 years old kids with fluorosis showed a 97% acceptance rate. Opalustre abrasion slurry showed a higher improvement rate compared to Prema compound. No more than .2mm of enamel is to be removed.',
                        'url': 'https://pubmed.ncbi.nlm.nih.gov/18051001/'
                    },
                    {
                        'product': 'Opalescence Go',
                        'title': 'Clinical Evaluation of Different Delivery Methods of At-Home Bleaching Gels Composed of 10% Hydrogen Peroxide.',
                        'citation': 'Cordeiro D, Toda C, Hanan S, et al. Oper Dent. 2019;44(1):13-23. doi:10.2341/17-174-C.',
                        'claim': 'The prefilled disposable trays (Opalescence Go, Ultradent) produced lower intensity of tooth sensitivity and lower risk of gingival irritation compared to conventional bleaching tray (White Class, FGM)',
                        'url': 'https://pubmed.ncbi.nlm.nih.gov/30142037/'
                    },
                    {
                        'product': 'Opalescence PF for Aligners',
                        'title': 'Dental bleaching during orthodontic treatment with aligners.',
                        'citation': 'Levrini, Luca, et al. Int J Esthet Dent 15 (2020): 44-54.',
                        'claim': 'the whitening with Opalescence PF 10% during orthodontic treatment with aligners was effective. a clinician should verify fit of the orthodontic tray to the teeth and gingival margin contour before treatment.',
                        'url': 'https://www.quintpub.com/userhome/ejed/ejed_15_1_levrini_p44.pdf'
                    }
                ]
            },
            {
                headline: 'Other studies',
                subtitle: 'Explore some of our most popular studies on broader topics within the dental industry',
                articles: [
                    {
                        'product': 'Valo Grand',
                        'title': 'Effect of Curing Light and Exposure Time on the Polymerization of Bulk-Fill Resin-Based Composites in Molar Teeth.',
                        'citation': 'Shimokawa C, Turbino ML, Giannini M, Braga RR, Price RB. Oper Dent. 2020;45(3):E141‐E155. doi:10.2341/19-126-L.',
                        'claim': 'VALO Grand curing light is the best option for curing bulk-fill resin-based composite',
                        'url': 'https://pubmed.ncbi.nlm.nih.gov/32053458/'
                    },
                    {
                        'product': 'Peak Universal Bond',
                        'title': 'Laboratory performance of universal adhesive systems for luting CAD/CAM restorative materials.',
                        'citation': 'Siqueira, Fabiana, et al. (2016).',
                        'claim': 'Peak Universal Bond has higher bond strengths across more indirect restorative materials than leading competitors',
                        'url': 'https://pubmed.ncbi.nlm.nih.gov/27419246/'
                    },
                    {
                        'product': 'MTA Flow',
                        'title': 'Biocompatibility of mineral trioxide aggregate flow and biodentine',
                        'citation': 'Mondelli JAS, Hoshino RA, Weckwerth PH, Cerri PS, Leonardo RT, Guerreiro-Tanomaru JM, Tanomaru-Filho M, da Silva GF. Int Endod J. 2019 Feb;52(2):193-200. doi: 10.1111/iej.12989. Epub 2018 Aug 17. PMID: 30035812.',
                        'claim': 'Comparing MTA Flow with Biodentine on biocompatibility, evaluated materials showed a favorable response after 60 days.',
                        'url': 'https://pubmed.ncbi.nlm.nih.gov/30035812/'
                    }
                ]
            }
        ]
    }
}

export default pageModel;
