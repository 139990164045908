import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {basePath: '/adGroups'} );

svc.getSiteInfo = function getSiteInfo ( {accountId} ) {
    const body = {accountId};
    return svc.xhr.post( 'siteinfo', body );
}

svc.updateSiteInfo = function updateSiteInfo ( {accountId, features} ) {
    const body = {accountId, features};
    return svc.xhr.post( 'updatesiteinfo', body );
}

svc.updateUserRole = function updateUserRole ( {azureAdId, isAdmin} ) {
    const body = {azureAdId, isAdmin};
    return svc.xhr.post( 'changerole', body );
}

svc.updateUserPermissions = function updateUserPermissions ( {azureAdId, permissions} ) {
    const body = {azureAdId, permissions};
    return svc.xhr.post( 'setpermissions', body );
}

export const AccountManagementProvider = svc.xhr;

export default svc;
