import React from 'react';
import {AxiosProvider, Get} from 'react-axios';
import {CRMProvider} from '@/providers/crm';
import {AccountLookupList} from './AccountLookupList';
import {LoadError} from '@/components/dialogs/PageLoadError';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const AccountsContext = React.createContext();

const Fetching = () =>
    <div className="w-full max-w-md m-auto text-center">
        <h3 className="subheading-lg">
            {t( 'account.notify.pleaseWait', _.account.notify.pleaseWait )}
        </h3>

        <div className="bg-near-white rounded-full inline-block shadow-1 m-4">
            <div className="flex items-center leading-collapse p-2">
                <span className="mr-2 w-6 h-6 spinner-reverse"></span> {
                t( 'message.loading', _.message.loading )
            }
            </div>
        </div>
    </div>;

const Empty = () =>
    <div className="w-full max-w-md m-auto text-center">
        <h3 className="subheading-lg">
            {t(
                'account.notify.noAccounts',
                _.account.notify.noAccounts
            )}
        </h3>
        <p className="text-body">
            {t(
                'account.notify.noAccountsNote1',
                _.account.notify.noAccountsNote1
            )}
        </p>
    </div>;

const responseIsEmpty = ( response ) => {
    return !response
        || !response.data
        || !response.data.CrmAccounts
        || response.data.CrmAccounts.length <= 0;
}

function Accounts () {
    return (
        <div className="w-full">
            <AxiosProvider instance={CRMProvider}>
                <Get url={`partneraccounts`}>
                    {( error, response, isLoading, makeRequest, axios ) => {
                        if ( error ) {
                            return <LoadError onRetry={() => makeRequest( {params: {reload: true}} )}/>;
                        }
                        if ( isLoading ) {
                            return <AccountLookupList.Skeleton/>
                        }
                        if ( responseIsEmpty( response ) ) {
                            return <Empty/>
                        }

                        return <AccountLookupList list={response.data.CrmAccounts}
                                                  refetch={() => makeRequest( {params: {reload: true}} )}/>;
                    }}
                </Get>
            </AxiosProvider>
        </div>
    );
}

export {Accounts};
