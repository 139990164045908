import cx from 'classnames';
import {TokenRequestError} from '@/util/Errors';
import {LogoUltradentSymbol} from '@/components/common/Icons';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const LoadError = ( {error, onRetry, className} ) => {

    if ( error instanceof TokenRequestError ) {
        return (
            <div className="w-full h-full py-8 md:py-16 max-w-md m-auto flex justify-center items-center text-center">
                <div className="fade-in">
                    <LogoUltradentSymbol className="mb-8 inline-block text-brand" width="64"/>
                    <h3 className="subheading-lg">
                        {t( 'message.oneMoment', _.message.oneMoment )}
                    </h3>
                    <div className="bg-near-white rounded-full inline-block shadow-1 m-4">
                        <div className="flex items-center leading-collapse p-2">
                            <span className="mr-2 w-6 h-6 spinner-reverse"/>
                            {t( 'label.authorizing', _.label.authorizing )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cx( 'w-full py-8 md:py-16 max-w-md m-auto text-center', className )}>
            <h3 className="subheading-lg text-fail">
                {t( 'message.problemLoadingPage', _.message.problemLoadingPage )}
            </h3>
            <p className="text-body">
                {t( 'message.retryOrRefresh', _.message.retryOrRefresh )}
            </p>
            {onRetry &&
                <button onClick={onRetry} className="mt-8 button lg tertiary">
                    {t( 'actionLabel.retry', _.actionLabel.retry )}
                </button>
            }
        </div>
    );
};
