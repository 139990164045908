export const userManagementReducer = ( state, action ) => {
    const {type, payload} = action;

    switch ( type ) {
        case 'setCrmAccountId':
            return {
                ...state,
                crmAccountId: payload.crmAccountId
            };

        case 'setSiteFeatures':
            return {
                ...state,
                siteFeatures: payload.siteFeatures || []
            };

        case 'setProcessing':
            return {
                ...state,
                processing: payload.processing
            };

        case 'setError':
            return {
                ...state,
                error: payload.error
            };

        // dialogs
        case 'editPermissions':
            return {
                ...state,
                error: null,
                isEditing: {
                    accountDetails: false,
                    permissions: true,
                    role: false,
                    regions: false,
                    invitation: false
                },
                userInEdit: {
                    userId: payload.userId,
                    permissions: payload.permissions,
                    role: payload.role,
                    regions: payload.regions
                }
            };

        case 'editRole':
            return {
                ...state,
                error: null,
                isEditing: {
                    accountDetails: false,
                    permissions: false,
                    role: true,
                    regions: false,
                    invitation: false
                },
                userInEdit: {
                    userId: payload.userId,
                    permissions: payload.permissions,
                    role: payload.role,
                    regions: payload.regions
                }
            };

        case 'editRegion':
            return {
                ...state,
                error: null,
                isEditing: {
                    accountDetails: false,
                    permissions: false,
                    role: false,
                    regions: true,
                    invitation: false
                },
                userInEdit: {
                    userId: payload.userId,
                    permissions: payload.permissions,
                    role: payload.role,
                    regions: payload.regions
                }
            };

        case 'editMembers':
            return {
                ...state,
                error: null,
                userInEdit: {},
                isEditing: {
                    accountDetails: false,
                    permissions: false,
                    role: false,
                    regions: false,
                    invitation: true
                }
            };

        case 'editAccountDetails':
            return {
                ...state,
                error: null,
                isEditing: {
                    accountDetails: true,
                    permissions: false,
                    role: false,
                    regions: false,
                    invitation: false
                },
                userInEdit: {
                    id: payload.id,
                    accountName: payload.accountName,
                    salesRep: payload.salesRep,
                    accountType: payload.accountType
                }
            };

        case 'stopEditing':
            return {
                ...state,
                error: null,
                userInEdit: {},
                isEditing: {
                    accountDetails: false,
                    permissions: false,
                    role: false,
                    regions: false,
                    invitation: false
                }
            }

        default:
            throw Error( `Unknown action "${action.type}" specified for userManagementReducer` );
    }
};
