console.warn( '[Mocking] "assets" api' );

export default function ( instance ) {
    instance
        .onGet( '/getsharedassets' )
        .reply( () => ([
            200,
            [
                {
                    'DateApproved': '2022-04-14T12:00:00.000Z',
                    'Id': '00033948-5810-4f01-9194-5619ca8a3338',
                    'IsNew': true,
                    'Taxonomies': [
                        [
                            'Images',
                            'Product Image'
                        ]
                    ],
                    'Time': '2022-04-14T12:00:00.000Z',
                    'Tag': [
                        'reflection',
                        'syringes',
                        '1.2ml'
                    ],
                    'Description': 'Image of Seek refill contents',
                    'Created': '2022-04-14T12:00:00.000Z',
                    'Width': 4972,
                    'Height': 2308,
                    'Dpi': 240.0,
                    'Additional': {
                        'VariantAsset': 'Primary',
                        'AssetCategory': [
                            'Images'
                        ],
                        'Product': [
                            'Etch and Bond - Silane'
                        ],
                        'AssetTitle': 'Silane Syringe No Tip Reflection',
                        'CEMark': 'Yes',
                        'ProductFamily': [],
                        'Regions': [
                            'APAC & SS',
                            'Canada',
                            'China',
                            'LATAM',
                            'NE Europe',
                            'SEMENA'
                        ],
                        'AssetType': [
                            'Product Image'
                        ],
                        'AssociatedSKUs': '410, 410-1',
                        'PaperSize': []
                    },
                    'LatestVersionNumber': 0,
                    'LastUploaded': '2022-04-14T12:00:00.000Z',
                    'ApprovalStatus': 'Approved',
                    'Name': 'silane syringe notip_BONDETCH_08refl.jpg',
                    'Size': 832805,
                    'Scheme': 'JPG',
                    'MimeType': 'image/jpeg',
                    'FileExtension': 'jpg',
                    'Valid': true,
                    'Sizes': [
                        {
                            'Alias': 'Original',
                            'MimeType': 'image/jpeg',
                            'Width': 4972,
                            'Height': 2308,
                            'Resolution': 240.0,
                            'FileSize': 832805,
                            'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                        },
                        {
                            'Alias': 'Large',
                            'MimeType': 'image/jpeg',
                            'Width': 1504,
                            'Height': 698,
                            'Resolution': 72.0,
                            'FileSize': 0,
                            'Url': 'https://localhost.ultradent.com/images/large-file.pptx'
                        }
                    ]
                }
            ]
        ]) )
}
