import {useNavigate} from 'react-router-dom';
import {BackButton, SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const LocationBar = ( {basename, pageName, linkBack, loading, ...props} ) => {
    const navigate = useNavigate();
    const showLinkBack = window.history.length > 0 && linkBack;

    if ( loading ) {
        return <LocationBar.Skeleton/>
    }
    return (
        <div className="md:flex items-center w-full py-4">
            <div className="flex flex-1 items-baseline">
                {showLinkBack &&
                    <span className="hidden md:block">
                        <BackButton className="flex mr-8" onClick={() => navigate( -1 )}>
                            {t( 'actionLabel.back', _.actionLabel.back )}
                        </BackButton>
                    </span>
                }
                <h2 className="headline-sm mb-4 md:mb-0 break-words">{pageName}</h2>
            </div>
            <div className="justify-end">
                {props.children}
            </div>
        </div>
    );
};

LocationBar.Skeleton = () =>
    <div className="my-4">
        <SkeletonElement className="w-16 mb-3" type="text"/>
        <SkeletonElement type="title"/>
    </div>

export {LocationBar}
