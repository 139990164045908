import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const DEBUG = process.env.NODE_ENV === 'development';

/* Events */
export const DAYS = 1000 * 60 * 60 * 24;
export const TOKEN_TIMEOUT = 1000 * 60 * 15;

export const FULLNAME_PATTERN = /^\s?([a-zA-Z'-]+[,.]?[ ]+)+[a-zA-Z'-]{1,}\s?$/i;
export const PHONE_PATTERN = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
export const PATHNAME = /#(.*?)(?:\?|$)/g; // extracts just pathname from location hash

export const Roles = {
    MEMBER: 'Portal Users',
    PARTNER_ADMIN: 'Partner Admin',
    REGION_ADMIN: 'Region Admin',
    GLOBAL_ADMIN: 'Global Admin',
    INTERNAL_USER: 'Internal User'
};

export const SiteRegions = {
    'LATAM': 'Latin America',
    'APAC & SS': 'Asia-Pacific & Sub-Saharan Africa',
    'SEMENA': 'Southern Europe, Middle East & North Africa',
    'NE Europe': 'North & East Europe',
    'Canada': 'Canada',
    'China': 'China',
    'Japan': 'Japan',
    'Brazil': 'Brazil'
};

export const validVideoFormats = [
    'mp4',
    'mpeg',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'ppt',
    'pptm',
    'pptx',
    'swf',
    'mkv',
    'webm',
    'html5'
];

export const webOptimizedFormats = [
    'jpg',
    'jpeg',
    'png'
];

export const fileTypeMap = {
    doc: t( 'fileFormatTip.doc', _.fileFormatTip.doc ),
    docx: t( 'fileFormatTip.docx', _.fileFormatTip.docx ),
    indd: t( 'fileFormatTip.indd', _.fileFormatTip.indd ),
    generic: t( 'fileFormatTip.generic', _.fileFormatTip.generic ),
    jpeg: t( 'fileFormatTip.jpeg', _.fileFormatTip.jpeg ),
    jpg: t( 'fileFormatTip.jpg', _.fileFormatTip.jpg ),
    pdf: t( 'fileFormatTip.pdf', _.fileFormatTip.pdf ),
    png: t( 'fileFormatTip.png', _.fileFormatTip.png ),
    ppt: t( 'fileFormatTip.ppt', _.fileFormatTip.ppt ),
    pptx: t( 'fileFormatTip.pptx', _.fileFormatTip.pptx ),
    psd: t( 'fileFormatTip.psd', _.fileFormatTip.psd ),
    mp4: t( 'fileFormatTip.mp4', _.fileFormatTip.mp4 ),
    mpeg: t( 'fileFormatTip.mpeg', _.fileFormatTip.mpeg ),
    tif: t( 'fileFormatTip.tif', _.fileFormatTip.tif ),
    txt: t( 'fileFormatTip.txt', _.fileFormatTip.txt ),
    video: t( 'fileFormatTip.video', _.fileFormatTip.video ),
    zip: t( 'fileFormatTip.zip', _.fileFormatTip.zip )
};

export const AssetData = {
    AssociatedSkus: 'Associated SKU\'s',
    Regions: 'Region(s)',
    RelatedDetail: 'Related Asset Details',
    Title: 'Asset Title',
    CEMark: 'CE Mark'
}

export const EventResponseStatus = ['Call Back', 'Confirmed', 'Waitlisted', 'Cancelled'];







