console.warn( '[Mocking] "bookmarks" api' );

export default function ( instance ) {
    instance
        .onGet( '/bookmarks' )
        .reply( () => ([
            200,
            [
                {
                    'Id': '07aqspkn1h72f1ej3e7ls5qn6a',
                    'Width': 0,
                    'Height': 0,
                    'Dpi': 0.0,
                    'Name': 'Ultradent Logo Corner Treatment Lower Right',
                    'Size': 0,
                    'Valid': false
                }
            ]
        ]) )
}
