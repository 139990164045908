import React, {useContext} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {AxiosProvider, Get} from 'react-axios';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {ResourceAppContext} from '@/apps/ResourceCenter/appContext';
import {ResourceCenterProvider} from '@/providers/resource-center';
import {AssetDetail} from './AssetDetail';

export const AssetDetailQuery = () => {
    const routeParams = useParams();
    const {categoryTaxonomy} = useContext( ResourceAppContext );

    return (
        <AxiosProvider instance={ResourceCenterProvider}>
            <Get url="AssetDetails" params={{id: routeParams.assetId}}>
                {( error, response, isLoading, makeRequest, axios ) => {
                    if ( isLoading ) {
                        return <AssetDetail loading={true} categoryTaxonomy={categoryTaxonomy}/>
                    }
                    if ( error ) {
                        return (error.response?.status === 404)
                            ? <Navigate to="/not-found" replace/>
                            : <AssetDetail
                                error={error}
                                onRetry={() => makeRequest( {params: {reload: true, id: routeParams.assetId}} )}
                                categoryTaxonomy={categoryTaxonomy}/>
                    }
                    else if ( response != null ) {
                        return isEmptyOrNil( response.data )
                            ? <Navigate to="/not-found" replace/>
                            : <AssetDetail {...response.data} categoryTaxonomy={categoryTaxonomy}/>
                    }

                    return null;
                }}
            </Get>
        </AxiosProvider>
    );
}
