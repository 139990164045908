import {CheckCirlce, Download} from '@/components/common/Icons';
import {ProgressBar, t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

const DOWNLOAD_LABEL = t( 'actionLabel.download', 'Download' );
const DOWNLOADING_LABEL = t( 'actionLabel.downloading', _.actionLabel.downloading );
const COMPLETE_LABEL = t( 'actionLabel.complete', _.actionLabel.complete );

export const AssetDownloadIconRenderer = ( {
                                               progress,
                                               isComplete,
                                               isDownloading
                                           } ) => {
    if ( isDownloading ) {
        if ( progress === 0 ) {
            return <Download className="text-near-black pulse" width="24" height="24"/>
        }
        return <div className="w-6">
            <span className="font-semibold">{progress + '%'}</span>
            <ProgressBar progress={progress} className="-mb-1.5 absolute bottom-0 left-0"/>
        </div>
    }

    if ( isComplete ) {
        return <CheckCirlce className="text-success" width="24" height="24"/>
    }

    return <Tooltip content={DOWNLOAD_LABEL}>
        <Download aria-label={DOWNLOAD_LABEL} width="24" height="24"/>
    </Tooltip>
}

export const AssetDownloadButtonRenderer = ( {label = DOWNLOAD_LABEL, progress, isComplete, isDownloading} ) => {
    if ( isDownloading ) {
        return <>
            <span>
                {progress === 0 ? DOWNLOADING_LABEL : progress + '%'}
            </span>
            <ProgressBar progress={progress} className="-mb-1.5 absolute bottom-0 left-0"/>
        </>
    }

    if ( isComplete ) {
        return <span className="flex items-center">
            <CheckCirlce width="24" height="24" className="flex-none mr-2"/>
            <span>{COMPLETE_LABEL}</span>
        </span>
    }

    return <span className="flex items-center">
        <Download width="24" height="24" className="flex-none mr-2"/>
        <span>{label}</span>
    </span>
}
