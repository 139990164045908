import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {AssetVideo} from '@/apps/ResourceCenter/Assets/AssetVideo';
import {AssetImage} from '@/apps/ResourceCenter/Assets/AssetImage';
import {PlayVideoIcon} from '@/components/common/Icons/PlayVideoIcon';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

function getImgRatio ( val ) {
    if ( val > 0.75 ) {
        return '1x1';
    }
    if ( val > 0.667 ) {
        return '4x3';
    }
    if ( val > 0.5625 ) {
        return '3x2';
    }

    return '16x9';
}

export const AssetThumbnail = ( {
                                    id,
                                    name,
                                    className,
                                    imgClassName,
                                    imgRatio,
                                    isPreviewOnly,
                                    stream,
                                    scheme,
                                    size = 400
                                } ) => {
    const imgRef = useRef();
    const [imgRatioClass, setImgRatio] = useState( imgRatio || '16x9' );
    const isPlayable = notEmptyOrNil( stream );

    useEffect( () => {
        const interval = setInterval( () => {
            if ( imgRef.current?.naturalWidth ) {
                clearInterval( interval );
                resizeImage( imgRef.current.naturalWidth, imgRef.current.naturalHeight )
            }
        }, 10 );
        return () => clearInterval( interval );
    }, [] );

    function resizeImage ( w, h ) {
        if ( !imgRatio ) {
            setImgRatio( getImgRatio( h / w ) );
        }
        if ( w < h ) {
            imgRef.current.classList.add( 'img-ratio-portrait' );
        }
    }

    const wrapperStyle = cx(
        'relative w-full flex justify-center items-center overflow-hidden spinner object-contain',
        {[`img-ratio img-ratio-${imgRatioClass}`]: isPreviewOnly || !isPlayable},
        className
    );

    if ( !id ) {
        return <AssetErrorMessage/>;
    }
    return (
        <AssetErrorBoundary>
            <div className={wrapperStyle}>
                {(isPlayable && !isPreviewOnly)
                    ? <AssetVideo stream={stream} alt={name} id={id} size={size} className={imgClassName}/>
                    : <AssetImage ref={imgRef} alt={name} id={id} size={size} className={imgClassName}/>
                }
                {(isPlayable && isPreviewOnly) && <PlayVideoIcon/>}
            </div>
        </AssetErrorBoundary>
    )
}

const AssetErrorMessage = () =>
    <div
        className="relative img-ratio img-ratio-16x9 w-full flex justify-center items-center bg-moon-grey rounded-lg">
        <h3 className="absolute-center p-4 subheading-base text-center text-grey-darker w-full italic">
            {t( 'asset.notAvailable', _.asset.notAvailable )}
        </h3>
    </div>

export class AssetErrorBoundary extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = {hasError: false};
    }

    static getDerivedStateFromError ( error ) {
        // console.error( '[AssetErrorBoundary]', error );
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    render () {
        if ( this.state.hasError ) {
            // You can render any custom fallback UI
            return <AssetErrorMessage/>
        }

        return this.props.children;
    }
}

