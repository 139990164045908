import {Tooltip} from  '@ultradent/components';
import cx from 'classnames';

const transformTagLabel = ( groupName, label ) => {
    if ( /^yes$/i.test( label ) ) {
        return `With ${groupName}`
    }
    if ( /^no$/i.test( label ) ) {
        return `Without ${groupName}`
    }
    return label;
}

const FilterTag = ( {groupName, label, removable, className, onClick} ) =>
    <Tooltip content={`Remove filter`}>
        <span className={cx( 'tag hover:link-2 mr-2 mb-2', className )} onClick={onClick}>
            {transformTagLabel( groupName, label )}
            {removable && <span className="tag-append">&times;</span>}
        </span>
    </Tooltip>;

FilterTag.defaultProps = {
    onClick: () => {}
};

export {
    FilterTag
}
