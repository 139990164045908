import cx from 'classnames';
import {Link} from 'react-router-dom';
import {toLegibleStringList} from  '@ultradent/utilities/Strings';

const FeatureTile = ( {
                          id,
                          src,
                          url,
                          title,
                          categories,
                          overline,
                          children,
                          className
                      } ) =>
    <div className={cx( 'leading-tight text-grey-darker', className )} data-asset-id={id}>
        <div className="mb-2 max-w-md">
            <Link to={url} className="link-hover">
                <img className="bg-near-white lazyload fade-up" alt={title} data-src={src}/>
            </Link>
        </div>
        {overline && <p className="eyebrow-headline-sm mb-2">{overline}</p>}
        <h3 className="headline-sm mb-2">
            <Link to={url} className="text-near-black link-hover">
                {title}
            </Link>
        </h3>
        {categories && categories.length > 0 &&
        <p className="text-body mt-0 mb-3">{toLegibleStringList( categories )}</p>
        }
        {children}
    </div>;

FeatureTile.defaultProps = {
    categories: []
};

export {FeatureTile}
