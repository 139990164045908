import {Button, Checkbox, Modal, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {FormikProvider, useFormik} from 'formik';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {SiteRegions} from '@/constants/appConstants';

export const RegionOptionList = ( {error, disabled, onChange, onBlur} ) =>
    <div className="mb-8">
        <p className="text-body-sm mb-4">
            {t(
                'userManagement.label.whichRegions',
                _.userManagement.label.whichRegions )}
        </p>
        {error &&
            <div className="my-4 text-fail text-base leading-snug">
                <h3 className="mb-2 font-medium text-sm">{error}</h3>
            </div>
        }
        <fieldset className="grid grid-cols-2">
            {Object.entries( SiteRegions ).map( ( [key, label] ) =>
                <Checkbox name="regions"
                          label={label}
                          labelClassName="flex pr-4"
                          disabled={disabled}
                          key={key}
                          value={label}
                          onChange={onChange}
                          onBlur={onBlur}/>
            )}
        </fieldset>
    </div>

export const ManageUserRegions = ( {user, processing, error, isOpen, onSubmit, onClose} ) => {

    const formik = useFormik( {
        enableReinitialize: true,
        onSubmit: values => {
            onSubmit( {
                azureAdId: user.userId,
                regions: values.regions
            } );
            return false;
        },
        initialValues: {
            regions: user.regions
        }
    } );

    return (
        <Modal
            title={t( 'userManagement.label.regionsFormTitle', _.userManagement.label.regionsFormTitle )}
            size="md"
            onClose={onClose}
            isOpen={isOpen}>
            {error &&
                <div className="my-4 text-fail text-base leading-snug">
                    <h3 className="mb-2 font-medium text-sm">{t(
                        'userManagement.notify.changeSubmissionFailed',
                        _.userManagement.notify.changeSubmissionFailed )}</h3>
                </div>
            }
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <RegionOptionList onBlur={formik.handleBlur}
                                      error={formik.errors.permissions}/>
                    <div className="flex w-full justify-end">
                        <Button
                            type="submit"
                            disabled={!isEmptyOrNil( formik.errors )}
                            loading={processing}>
                            {t( 'userManagement.actionLabel.updateRegions',
                                _.userManagement.actionLabel.updateRegions )}
                        </Button>
                    </div>
                </form>
            </FormikProvider>
        </Modal>
    )
};

ManageUserRegions.defaultProps = {
    isOpen: false,
    processing: false,
    onClose: () => {},
    onSubmit: () => {},
    user: {
        userId: null,
        regions: [] // ['Resource Center','Sales Dashboard'] etc
    }
}
