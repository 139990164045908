import {Button, SkeletonElement, SmallDevice, t} from '@ultradent/components';
import {FilterTag} from '@/containers/FacetedSearch/FilterBar/FilterTag';
import {Filter} from '@/components/common/Icons';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';

const FilterBar = ( {
                        resultCount,
                        filters,
                        sortOptions,
                        currentSortBy,
                        loading,
                        onRemoveFilter,
                        onClearFilter,
                        onChangeSort,
                        onOpenFacets
                    } ) => {
    if ( loading ) {
        return <FilterBar.Skeleton/>
    }
    return (
        <div className="mb-6">
            <div className="md:flex items-center justify-between mb-4">
                <p className="flex-none mb-2 w-24 text-title">
                    {t(
                        'search.label.resultWithCount',
                        '{{count}} results',
                        {count: Number( resultCount )}
                    )}
                </p>
                <div className="flex justify-between">
                    <SmallDevice>
                        <Button tertiary small onClick={onOpenFacets} icon={Filter}>
                            {t( 'search.label.filter', 'Filter' )}
                        </Button>
                    </SmallDevice>
                    {notEmptyOrNil( sortOptions ) &&
                        <select data-testid="Search.SortBy" value={currentSortBy}
                                onChange={( e ) => onChangeSort( e.target.value )}>
                            {sortOptions.map( ( {label, value} ) =>
                                <option data-testid={`Search.SortBy.${value || 'Default'}`}
                                        key={value}
                                        value={value}>{label}</option>
                            )}
                        </select>
                    }
                </div>
            </div>
            {filters.length > 0 &&
                <div className="flex-auto">
                <span className="text-title w-24 mr-2">{t( 'search.label.appliedFilters', 'Applied filters' )}:</span>
                    {filters.map( facet =>
                        <FilterTag key={`${facet.groupName}:${facet.id}`}
                                   groupName={facet.groupName}
                                   label={facet.title}
                                   className="mb-2 text-grey-darker"
                                   removable
                                   onClick={() => onRemoveFilter( facet )}/>
                    )}
                    <button className="link-2 ml-4" onClick={onClearFilter}>
                    {t( 'search.actionLabel.clear', 'Clear all' )}
                </button>
            </div>
            }
        </div>
    )
};

FilterBar.Skeleton = () =>
    <div>
        <div className="flex items-center justify-between">
            <SkeletonElement type="title" className="fixed-width"/>
            <SkeletonElement type="btn"/>
        </div>
    </div>

FilterBar.defaultProps = {
    resultCount: 0,
    filters: []
};

export {FilterBar}
