import React, {useCallback, useEffect, useReducer} from 'react';
import PropType from 'prop-types';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {useDidUpdateEffect, useMediaQuery} from '@/util/Hooks';
import {breakpoints} from '@ultradent/utilities/MediaQueries';
import {BackButtonDrawer, Button, Modal, ProcessingBar, Show, SmallDevice, StickyPane, t} from '@ultradent/components';
import {searchReducer} from '@/containers/FacetedSearch/reducers/search';
import {LoadError} from '@/components/dialogs/PageLoadError';
import {Facets} from '@/containers/FacetedSearch/Facets';
import {FilterBar} from '@/containers/FacetedSearch/FilterBar';

import {
    formatQueryParams,
    getSelectedFilters,
    transformCategoryList,
    transformFacetList
} from '@/containers/FacetedSearch/transforms';
import {QueryProvider} from '@/containers/FacetedSearch/Provider';
import {FilterTag} from '@/containers/FacetedSearch/FilterBar/FilterTag';

const QueryController = ( {
                              slots,
                              loading, error, processing, resultData, onQuery, onRetry, // from AxiosProvider (request)
                              sortOptions, keyword, startAt, orderBy, itemsPerPage, categoryFilter // passed props
                          } ) => {

    const [state, dispatch] = useReducer( searchReducer, {
        processing,
        keyword: keyword,
        lastKeyword: keyword,
        results: resultData.Results || [],
        selectedCategories: categoryFilter,
        selectedFacets: getSelectedFilters( resultData.Facets ),
        orderBy: orderBy,
        startAt: startAt,
        isFacetDrawerActive: false,
        itemsPerPage: itemsPerPage
    } );

    const isMediumDevice = useMediaQuery( `(min-width: ${breakpoints.screenMedium}px)` );

    const makeQuery = async ( s ) => {
        const queryStr = formatQueryParams( {
            keyword: s.keyword,
            facets: s.selectedFacets,
            categoryPath: s.selectedCategories,
            orderBy: s.orderBy,
            startAt: s.startAt,
            itemsPerPage: s.itemsPerPage
        } );

        if ( onQuery ) {
            await onQuery( queryStr );
        }
    };

    // update state from prop change
    /**
     * set "keyword" state set from props
     */
    useEffect(
        () => dispatch( {type: 'updateKeyword', payload: {keyword}} ),
        [keyword] );

    /**
     * set "startAt" state set from props
     */
    useEffect(
        () => dispatch( {type: 'setStart', payload: {startAt}} ),
        [startAt] );

    useEffect(
        () => dispatch( {type: 'setOrderBy', payload: {orderBy}} ),
        [orderBy] );
    // dispatch( {type: 'setOrderBy', payload: {orderBy}}

    useEffect(
        () => dispatch( {
            type: 'updateSelectedCategory',
            payload: {categoryPath: categoryFilter}
        } ),
        [categoryFilter] );

    useEffect(
        () => dispatch( {
            type: 'updateSelectedFacets',
            payload: {facets: getSelectedFilters( resultData.Facets )}
        } ),
        [resultData.Facets] );

    useEffect(
        () => {
            if ( !Array.isArray( resultData.Results ) ) {
                return;
            }

            dispatch( {
                type: 'receivedResults',
                payload: {
                    nextResults: resultData.Results,
                    lastKeyword: keyword
                }
            } )
        },
        [resultData.Results] )

    // issue new queries (for changes) after component mounts
    useDidUpdateEffect( async () => {
        if ( state.isDirty ) {
            if ( !state.loadingMore ) {
                await dispatch( {type: 'newQuery'} );
            }
            makeQuery( state );
        }
    }, [state.keyword, state.orderBy, state.selectedFacets, state.selectedCategories, state.loadingMore] );

    const onKeywordChange = value => {
        dispatch( {type: 'setStart', payload: {startAt: 0}} );
        dispatch( {type: 'updateKeyword', payload: {keyword: value, dirty: true}} )
    };

    const onFacetChange = ( facet, isAdding ) => {
        dispatch( {
            type: isAdding ? 'addSelectedFacet' : 'removeSelectedFacet',
            payload: {facet, dirty: true}
        } )
    };

    const onCategoryChange = useCallback(
        categoryPath => {
            dispatch( {type: 'setStart', payload: {startAt: 0}} );
            dispatch( {type: 'updateSelectedCategory', payload: {categoryPath, dirty: true}} );
        },
        [state.selectedCategories] );

    const onFilterRemove = facet => dispatch( {
        type: 'removeSelectedFacet',
        payload: {facet, dirty: true}
    } );

    const toggleFacetDrawer = () => dispatch( {
        type: 'setFacetDrawer',
        payload: {facetDrawerActive: !state.isFacetDrawerActive}
    } );
    const onLoadMore = () => dispatch( {type: 'loadMore'} );
    const onClearCategories = () => dispatch( {type: 'clearAllCategories'} );
    const onClearFilter = () => dispatch( {type: 'clearAllFilters'} );
    const onChangeSort = order => dispatch( {type: 'setOrderBy', payload: {orderBy: order}} );

    const facetList = transformFacetList( {
        list: resultData.Facets,
        selections: state.selectedFacets
    } );
    const categoryList = transformCategoryList( {
        list: resultData.Categories,
        selections: state.selectedCategories
    } );

    const hasResults = notEmptyOrNil( state.results );
    const showSkeleton = loading || (!hasResults && resultData.ResultCount > 0);
    const showFacets = hasResults || showSkeleton;
    const showLoadMore = state.loadingMore || resultData.ResultsThisRequest >= itemsPerPage;

    const sendProps = {
        ...state,
        hasResults,
        showSkeleton,
        showFacets,
        showLoadMore,
        onLoadMore,
        onKeywordChange,
        onCategoryChange,
        onClearCategories
    };

    return (
        <QueryProvider searchProps={sendProps}>
        <div className="contain-content pad-content mb-16">
            {slots.header( sendProps )}

            <div className="relative">
                {state.processing && <ProcessingBar/>}
                {
                    error
                        ? <LoadError className="my-8" onRetry={onRetry} error={error}/>
                        : <div className="md:flex pt-2 fade-in">
                            {showFacets &&
                                <>
                                    <Show when={isMediumDevice}>
                                        <StickyPane
                                            className="w-1/4 max-w-sm flex-none md:mr-8 mb-6"
                                            innerPaneClassName="border border-grey-light"
                                            expandedOffset={80}>
                                            <Facets
                                                loading={showSkeleton}
                                                categories={categoryList}
                                                facets={facetList}
                                                onCategoryClick={onCategoryChange}
                                                onChange={onFacetChange}/>
                                        </StickyPane>
                                    </Show>
                                    <SmallDevice>
                                        <Modal isOpen={state.isFacetDrawerActive}
                                               drawer
                                               processing={state.processing}
                                               slotHeader={( {onClose} ) =>
                                                   <header
                                                       className="px-4 py-1 border-b border-solid border-grey-lighter">
                                                   <div className="flex justify-between">
                                                       <BackButtonDrawer onClick={onClose}>
                                                           {t( 'actionLabel.done', 'Done' )}
                                                       </BackButtonDrawer>
                                                       <Button textLink onClick={onClearFilter}>
                                                           {t( 'search.actionLabel.clear', 'Clear all' )}
                                                       </Button>
                                                   </div>
                                                       {state.selectedFacets.length > 0 &&
                                                           <div className="flex-auto">
                                                            {state.selectedFacets.map( facet =>
                                                                <FilterTag key={`${facet.groupName}:${facet.id}`}
                                                                           groupName={facet.groupName}
                                                                           label={facet.title}
                                                                           className="mb-2 text-grey-darker"
                                                                           removable
                                                                           onClick={() => onFilterRemove( facet )}/>
                                                            )}
                                                        </div>
                                                       }
                                                   </header>
                                               }
                                               onClose={toggleFacetDrawer}
                                               title={t( 'search.label.filter', 'Filter', {count: 2} )}>
                                            <Facets loading={showSkeleton}
                                                    categories={categoryList}
                                                    facets={facetList}
                                                    onCategoryClick={onCategoryChange}
                                                    onChange={onFacetChange}/>
                                        </Modal>
                                    </SmallDevice>
                                </>
                            }
                            <div className="flex-auto">
                                <FilterBar loading={showSkeleton}
                                           resultCount={resultData.ResultCount || 0}
                                           filters={state.selectedFacets}
                                           sortOptions={sortOptions}
                                           currentSortBy={state.orderBy}
                                           onOpenFacets={toggleFacetDrawer}
                                           onRemoveFilter={onFilterRemove}
                                           onClearFilter={onClearFilter}
                                           onChangeSort={onChangeSort}/>
                                <div className="md:flex flex-wrap justify-between">
                                    {slots.results( sendProps )}
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
        </QueryProvider>
    )
}

QueryController.defaultProps =
    {
        slots: {
            header: () => null,
            results: () => null
        },
        resultData: {},
        startAt: 0
    }

QueryController.prototype =
    {
        slots: PropType.shape( {
            header: PropType.func.isRequired,
            results: PropType.func.isRequired
        } ).isRequired
    }
;

export
{
    QueryController
}
