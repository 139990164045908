import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import compose from 'ramda/src/compose';
import uniq from 'ramda/src/uniq';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import reverse from 'ramda/src/reverse';
import sortBy from 'ramda/src/sortBy';
import flatten from 'ramda/src/flatten';
import countBy from 'ramda/src/countBy';
import identity from 'ramda/src/identity';
import {fileDownload} from '@ultradent/utilities/Media';
import JSZip from 'jszip';
import {useFormik} from 'formik';
import {useEffect, useRef, useState} from 'react';
import {notify} from '@/modules/notifier';
import {ErrorMessages} from '@/util/Errors';
import AssetDownloadService from '@/providers/assetDownload';

const DEFAULT_FILE_NAME = t( 'asset.defaultFileName', _.asset.defaultFileName );

/**
 * Transform and Asset model into expected download format
 * @param id
 * @param name
 * @param fileExtension
 * @param mimetype
 * @param scheme
 * @param additional?
 * @returns {{scheme, fileExtension, name: (string|*), mimetype, id}}
 */
const normalizeForDownload = ( {id, name, sizes, fileExtension, mimetype, scheme, additional} ) => ({
    id,
    name: additional?.AssetTitle ?? name,
    fileExtension,
    mimetype,
    scheme,
    sizes: sizes || []
});

const getAvailableSizeOptions = compose(
    uniq,
    map( prop( 'alias' ) ),
    reverse,
    sortBy( prop( 'fileSize' ) ),
    flatten,
    map( prop( 'sizes' ) )
);

/**
 *
 * @param asset Asset{id, scheme, name, fileExtension, mimetype}
 * @param sizeAlias String - one of ['original','large'], requested asset size alias from client
 * @param onProgress Function ({loaded, total}) => {}
 * @param allowAnonymous Boolean
 * @returns {Promise<void>}
 */
const downloadSingle = async ( {asset, sizeAlias, onProgress = () => {}, allowAnonymous} ) => {
    const svc = allowAnonymous ? AssetDownloadService.anon : AssetDownloadService;
    const {name, fileExtension, mimetype} = asset;
    const resp = await svc.downloadAsset( {asset, sizeAlias, onDownloadProgress: onProgress} );

    fileDownload( {
        data: resp?.data,
        filename: `${name}.${fileExtension}`,
        mimetype
    } );
};

/**
 *
 * @param assets [Asset{id, scheme, name, fileExtension, mimetype}]
 * @param sizeAlias String - one of ['original','large'], requested asset size alias from client
 * @param onProgress Function ({loaded, total}) => {}
 * @param bundleName String
 * @param allowAnonymous Boolean
 * @returns {Promise<void>}
 */
const downloadBundle = async ( {assets, sizeAlias, onProgress = () => {}, bundleName, allowAnonymous} ) => {
    const svc = allowAnonymous ? AssetDownloadService.anon : AssetDownloadService;
    const resp = await svc.downloadAssetList( {
        assets,
        sizeAlias,
        name: bundleName,
        onDownloadProgress: onProgress
    } );

    const zip = new JSZip();
    const nameList = [];

    resp.forEach( ( item, index ) => {
        const {name, fileExtension} = assets[index];
        const n = `${name}.${fileExtension}`;
        // detect files with the same name and append index to filename so it doesn't get overwritten
        const nameMap = countBy( identity )( nameList );
        const fileName = (nameMap[n] > 0)
            ? `${name} (${nameMap[n]})`
            : name;

        zip.file( `${fileName}.${fileExtension}`, item.data );
        nameList.push( n );
    } );

    const blob = await zip.generateAsync( {type: 'blob'} );
    return fileDownload( {
        data: blob,
        filename: `${bundleName || DEFAULT_FILE_NAME}.zip`
    } );
};

/**
 *
 * @param assets [Asset{id, scheme, name, fileExtension, mimetype}]
 * @param allowAnonymous Boolean
 * @returns {{onDownload: ((function(): Promise<void>)|*), isDownloading: boolean, progress: number, isComplete: boolean}}
 */
export default function useAssetDownload ( {assets, allowAnonymous = false} ) {
    assets = assets.map( normalizeForDownload );

    const sizeOptions = getAvailableSizeOptions( assets );

    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            downloadFilename: '',
            assetSize: 'original'
        }
    } );

    // note -> if [id, scheme, name, mimetype] are missing the file can not be downloaded
    const timerId = useRef();
    const [isDownloading, setDownloading] = useState( false );
    const [isSelectingDownloadOption, setIsSelectingDownloadOption] = useState( false );
    const [isComplete, setComplete] = useState( false );
    const [progress, setProgress] = useState( 0 );
    const [_allowAnonymous, setAllowAnonymous] = useState( allowAnonymous );

    // prompt user that there is still a download in progress
    useEffect( () => {
        if ( isDownloading ) {
            window.onbeforeunload = e => {
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            };
            return null;
        }
        window.onbeforeunload = null;

        return () => window.onbeforeunload = null;
    }, [isDownloading] );

    useEffect( () => {
        if ( isComplete ) {
            timerId.current = setTimeout( resetState, 3000 );
        }
    }, [isComplete] );

    const onPromptDownloadOptions = () => {
        setIsSelectingDownloadOption( true );
    };

    const onCancelDownload = () => {
        setIsSelectingDownloadOption( false );
    };

    const onDownload = async () => {
        if ( isDownloading ) {
            return;
        }
        const {assetSize, downloadFilename} = formik.values;

        try {
            onDownloadStart();
            if ( assets.length > 1 ) {
                await downloadBundle( {
                    assets,
                    allowAnonymous: _allowAnonymous,
                    sizeAlias: assetSize,
                    bundleName: downloadFilename,
                    onProgress
                } );
            }
            else {
                await downloadSingle( {
                    asset: assets[0],
                    allowAnonymous: _allowAnonymous,
                    sizeAlias: assetSize,
                    onProgress
                } );
            }
        }
        catch ( err ) {
            resetState();
            console.error( err );
            notify.error( ErrorMessages.DownloadError )
        }
    };

    function onDownloadStart () {
        timerId.current && clearTimeout( timerId.current );
        timerId.current = null;
        setProgress( 0 );
        setComplete( false );
        setIsSelectingDownloadOption( false );
        setDownloading( true );
    }

    function onProgress ( {loaded, total} ) {
        const p = loaded / total;
        setProgress( parseInt( p * 100, 10 ) );

        if ( p >= 1 ) {
            setComplete( true );
            setDownloading( false );
        }
    }

    function resetState () {
        setProgress( 0 );
        setComplete( false );
        setIsSelectingDownloadOption( false );
        setDownloading( false );
    }

    return {
        formProvider: formik,
        assets,
        sizeOptions,
        isSelectingDownloadOption,
        isDownloading,
        isComplete,
        progress,
        setAllowAnonymous,
        onPromptDownloadOptions,
        onDownload,
        onCancelDownload
    };
}
