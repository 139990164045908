import PropTypes from 'prop-types';
import {SkeletonElement} from '@ultradent/components';
import {noop} from '@ultradent/utilities/Functions';

export const CategorySelect = ( {loading, taxonomy, selectedCategory, onChange} ) => {
    if ( loading ) {
        return <CategorySelect.Skeleton/>;
    }

    return (
        <select
            data-testid="category-select"
            className="mr-0.5 rounded-r-none"
            value={selectedCategory}
            onChange={( e ) => onChange( e.target.value )}>
            {taxonomy.map( ( {id, name} ) =>
                <option key={id} value={id}>{name}</option>
            )}
        </select>
    );
}

CategorySelect.Skeleton = () =>
    <button data-testid="category-select-skeleton" className="button sm tertiary mr-0.5 rounded-r-none">
        <SkeletonElement className="w-40 mx-4 my-0"/>
    </button>

CategorySelect.propTypes = {
    taxonomy: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    } ) ).isRequired,
    selectedCategory: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

CategorySelect.defaultProps = {
    selectedCategory: '',
    onChange: noop
};
