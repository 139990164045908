import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/bookmarks',
    mockSrc: process.env.MOCK_API && 'bookmarks'
} );

svc.getAll = function getAll () {
    return this.xhr.get();
}

svc.add = function add ( body ) {
    if ( !Array.isArray( body ) ) {
        throw new TypeError( `[BookmarkService.add] expects "body" to be an array but received ${body} instead` );
    }
    return this.xhr.post( 'create', body );
}

svc.remove = function remove ( body ) {
    if ( !Array.isArray( body ) ) {
        throw new TypeError( `[BookmarkService.remove] expects "body" to be an array but received ${body} instead` );
    }
    return this.xhr.post( 'delete', body );
}

export const BookmarksProvider = svc.xhr;

export default svc;


