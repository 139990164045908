import {FormFeedback, Show} from '@ultradent/components';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import React from 'react';
import {noop} from '@ultradent/utilities/Functions';
import RegistrantCard from '../RegistrantCard';

const RegistrantList = ( {list, onEdit, onRemove, disabled, errors} ) => {
    if ( isEmptyOrNil( list ) ) {
        return <p className="mb-8">You have not added any people yet.</p>
    }

    return (
        <>
            <Show when={errors !== null && !Array.isArray( errors )}>
                <FormFeedback {...errors} className="mb-6 bg-fail text-white"/>
            </Show>
            <ul className="mb-8 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {list.map( ( registrant, index ) => {
                    const lineErrors = Array.isArray( errors ) && errors.find( item => item.index === index );
                    return <RegistrantCard
                        key={`registrant-${index}`}
                        {...registrant}
                        disabled={disabled}
                        postError={lineErrors?.responseData}
                        onRemove={onRemove}
                        onEdit={onEdit}/>
                } )}
            </ul>
        </>
    );
}

RegistrantList.defaultProps = {
    onRemove: noop,
    onEdit: noop,
    errors: []
}

export {RegistrantList};
