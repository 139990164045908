import AccountService from '@/providers/account';
import {useEffect, useState} from 'react';

const useAccountFieldOptions = () => {
    const [fieldOptions, setFieldOptions] = useState( null );
    const [error, setError] = useState( null );

    useEffect( async () => {
        try {
            const {data} = await AccountService.getFieldOptions();
            setFieldOptions( data );
        }
        catch ( err ) {
            console.error( err );
            setError( err );
        }
    }, [] );

    return {
        isLoading: !fieldOptions && !error,
        error,
        fieldOptions
    };
}

export default useAccountFieldOptions;
