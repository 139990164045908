import {useEffect, useState} from 'react';

export default function useAssetLoadError ( {id, name} ) {
    const [hasError, setHasError] = useState( false );

    useEffect( () => {
        if ( hasError ) {
            throw Error( `[AssetError] - Preview not available for "${name}" ID:"${id}"` );
        }
    }, [hasError] );

    return {
        onError: () => setHasError( true )
    };
}
