import {TokenRequestError} from '@/util/Errors';
import Cookie from 'js-cookie';
import env from '@env';
import axios from 'axios';
import {ClearStoreEvent} from '@/util/Events';

const TOKEN = env.API_TOKEN;
const ANONYMOUS_TOKEN = env.API_TOKEN_ANON;

const handleTokenRequestError = ( err ) => {
    throw new TokenRequestError( err );
}

// note - About Token security
// resource https://dev.to/cotter/localstorage-vs-cookies-all-you-need-to-know-about-storing-jwt-tokens-securely-in-the-front-end-15id
const instance = axios.create( {
    baseURL: `${env.API_ORIGIN}/oauth2/v2.0`,
    withCredentials: true,
    timeout: 0 // no-timeout
} );

const svc = {
    ...instance,
    acquireTokenFromRefresh: async function () {
        try {
            console.log( 'acquireTokenFromRefresh: Try refresh token' );
            // request will fail if requestToken invalid - throw TokenRequestError
            await this.requestTokens();
        }
        catch ( err ) {
            handleTokenRequestError( err );
        }
    },
    requestTokens: function () {
        return this.post( '/token' );
    },
    resetAuth: function () {
        this.clearToken();
        window.dispatchEvent( ClearStoreEvent );
    },
    clearToken: function () {
        Cookie.remove( TOKEN, {path: '/', domain: env.COOKIE_DOMAIN} );
    },

    async getAuthTokens () {
        let authToken = Cookie.get( TOKEN );

        if ( !authToken ) {
            await this.acquireTokenFromRefresh();
            authToken = Cookie.get( TOKEN );
        }

        return authToken;
    },

    // get auth token from session
    async getAuthHeader () {
        try {
            const authToken = await this.getAuthTokens();
            return `Bearer ${authToken}`;
        }
        catch ( err ) {
            console.error( '[Get cached tokens failed]', err );
            this.redirectTo( 'login' );
        }
        return null;
    },

    // get anonymous token from session
    getAnonymousAuthHeader () {
        const authToken = Cookie.get( ANONYMOUS_TOKEN );

        if ( !authToken ) {
            console.error( '[Invalid auth token] - Could not find token for anonymous request' );
            this.redirectTo( 'notauthorized' );
        }

        return `Bearer ${authToken}`;

    },

    redirectTo ( action ) {
        const url = new URL( window.location.origin );

        switch ( action ) {
            case 'notauthorized':
                url.pathname = '/not-authorized';
                break;

            case 'login':
            default:
                url.pathname = '/MicrosoftIdentity/Account/SignIn';
                url.search = `redirect=${encodeURIComponent( window.location.href )}`;
        }

        window.location.replace( url.href );
    }

};

export default svc;
