import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {getLocations, useScrollToTop} from '@/util/Hooks';
import {Modal} from '@ultradent/components';
import {PageNotFound} from '@/components/common/PageNotFound';

export const SearchRouter = ( {routes, ResultDetailRoute, children} ) => {
    const navigate = useNavigate();
    const location = useLocation();
    useScrollToTop( ( l ) => {
        // Condition: Returning to search page (possibly from modal)
        // resource - https://reactrouter.com/en/v6.3.0/upgrading/v5#use-usenavigate-instead-of-usehistory
        if ( l.pathname === '/search' ) {
            return false;
        }

        // Condition: loading search results from pagination
        const searchParams = new URLSearchParams( l.search );
        const start = searchParams.get( 'start' );
        if ( !start ) {
            return true;
        }

        return Number( start ) <= 0;
    } );

    const {parentLocation, modalLocation} = getLocations( location );

    return (
        <>
            {children}

            <Routes location={parentLocation}>
                <Route path="*" element={<PageNotFound/>}/>

                {routes.map( ( route, index ) => {
                    const MainComponent = route.main;

                    return <Route
                        key={index}
                        path={route.path}
                        element={<MainComponent/>}
                    />
                } )}

            </Routes>

            {/* Note - creates an independent Router to track/render routes inside a modal separate form main Router */}
            {ResultDetailRoute && modalLocation &&
                <Modal isOpen={true}
                       size="3xl"
                       onClose={() => {
                           const goBack = modalLocation.state?.index || 1;
                           navigate( -(goBack) );
                       }}>
                    <Routes>
                        {ResultDetailRoute}
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </Modal>
            }
        </>
    );
}
