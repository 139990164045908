import React from 'react';
import ReactDOM from 'react-dom';
import '@/css/index.scss';
import './client-init';
import {AppEntry} from '@/containers/ApplicationEntry';
import ResourceCenterApp from '@/apps/ResourceCenter';
import StudiesApp from '@/apps/Studies';
import ProductAnswersApp from '@/apps/ProductAnswers';
import {Accounts} from '@/containers/Accounts';
import {UserManagement} from '@/containers/UserManagement';
import {BookmarkApp} from '@/containers/Bookmarks';
import {AssetSharingApp} from '@/containers/AssetSharing';
import {EquipmentRepairApp} from '@/containers/EquipmentRepair';
import PhotoViewer from '@/components/common/PhotoViewer';
import reportWebVitals from './reportWebVitals';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {EventManagementApp, EventRegistrationApp} from './containers/EventManagement';

const createEntryNode = () => {
    const entryNode = document.createElement( 'div' );
    document.body.appendChild( entryNode );
    return entryNode;
}

const photoViewer = document.querySelectorAll( '[data-photo-viewer]' );
if ( notEmptyOrNil( photoViewer ) ) {
    [...photoViewer].forEach( root => {
        const imgNodeList = root.querySelectorAll( '[data-photo-src]' );
        const imgSrcList = [...imgNodeList]?.map( i => ({
            src: i.dataset.photoSrc,
            altText: i.querySelector( 'img' )?.alt
        }) );
        ReactDOM.render(
            <PhotoViewer imgSrcList={imgSrcList}/>,
            root
        )
    } );
}

const resourceCenterAppRoot = document.getElementById( 'resource-center-app' );
if ( resourceCenterAppRoot ) {
    ReactDOM.render(
        <AppEntry>
            <ResourceCenterApp/>
        </AppEntry>,
        resourceCenterAppRoot
    );
}

const studiesAppRoot = document.getElementById( 'studies-app' );
if ( studiesAppRoot ) {
    ReactDOM.render(
        <AppEntry>
            <StudiesApp/>
        </AppEntry>,
        studiesAppRoot
    );
}

const productAnswersAppRoot = document.getElementById( 'product-answers-app' );
if ( productAnswersAppRoot ) {
    ReactDOM.render(
        <AppEntry>
            <ProductAnswersApp/>
        </AppEntry>,
        productAnswersAppRoot
    );
}

const bookmarksAppRoot = document.getElementById( 'bookmarks-entry' );
if ( bookmarksAppRoot ) {
    ReactDOM.render(
        <AppEntry>
            <BookmarkApp/>
        </AppEntry>,
        bookmarksAppRoot
    );
}

const assetShareRoot = document.getElementById( 'asset-share-entry' );
if ( assetShareRoot ) {
    ReactDOM.render(
        <AppEntry>
            <AssetSharingApp/>
        </AppEntry>,
        assetShareRoot
    );
}

// team-management
const inviteTeamActions = document.querySelector( '[data-action="invite-team-member"]' );
if ( inviteTeamActions != null ) {
    ReactDOM.render(
        <AppEntry>
            <UserManagement crmAccountId={inviteTeamActions.dataset.crmAccountId}/>
        </AppEntry>,
        createEntryNode()
    );
}

const irmAccountManagementRoot = document.getElementById( 'irm-account-management' );
if ( irmAccountManagementRoot ) {
    ReactDOM.render(
        <AppEntry>
            <Accounts/>
        </AppEntry>,
        irmAccountManagementRoot
    );
}

const equipmentRepairRoot = document.getElementById( 'equipment-repair-entry' );
if ( equipmentRepairRoot ) {
    ReactDOM.render(
        <AppEntry>
            <EquipmentRepairApp/>
        </AppEntry>,
        equipmentRepairRoot
    );
}

const eventManagementRoot = document.getElementById( 'event-management-app' );
if ( eventManagementRoot ) {
    const {eventId, eventAdmin} = eventManagementRoot.dataset;
    ReactDOM.render(
        <AppEntry>
            {eventAdmin === 'True'
                ? <EventManagementApp eventId={eventId}/>
                : <EventRegistrationApp eventId={eventId}/>
            }
        </AppEntry>,
        eventManagementRoot
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
