import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {Radio, Show, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import cx from 'classnames';
import React from 'react';
import {toFileSize} from '@ultradent/utilities/Strings';

export const AssetDownloadOptions = ( {sizeOptions, sizeDetails = []} ) => {
    if ( isEmptyOrNil( sizeOptions ) ) {
        return null;
    }

    const labelMap = {
        Original: t( '', _.downloadAssets.label.original ),
        Large: t( '', _.downloadAssets.label.webOptimized )
    };

    return (
        <div className="mb-4 md:table">
            <Show when={notEmptyOrNil( sizeDetails )}>
                <div className="md:table-row py-2">
                    <h3 className="table-cell py-2 pr-8 text-title">
                        {t( 'assetDetail.label.size', _.assetDetail.label.size )}
                    </h3>
                    <span className="hidden md:table-cell py-2 pr-4 text-sm text-grey-darker">
                        {t( 'assetDetail.label.resolution', _.assetDetail.label.resolution )}
                    </span>
                    <span className="hidden md:table-cell py-2 pr-4 text-sm text-grey-darker">
                        {t( 'assetDetail.label.dimensions', _.assetDetail.label.dimensions )}
                    </span>
                    <span className="hidden md:table-cell py-2 pr-4 text-sm text-grey-darker">
                        {t( 'assetDetail.label.fileSize', _.assetDetail.label.fileSize )}
                    </span>
                </div>
            </Show>
            <Show when={isEmptyOrNil( sizeDetails )}>
                <div className="flex py-2 w-full">
                    <h3 className="text-title mr-1">
                        {t( 'downloadAssets.label.imageSize', _.downloadAssets.label.imageSize )}
                    </h3>
                    <span className="text-base text-grey-dark">
                        {t( 'downloadAssets.label.imageOptimizedFormats',
                            _.downloadAssets.label.imageOptimizedFormats )}
                    </span>
                </div>
            </Show>
            {sizeOptions.map( optionName => {
                const sizeDetail = sizeDetails.find( s => s.alias === optionName );
                return (
                    <div key={optionName} className="md:table-row">
                        <span className="table-cell pr-8">
                            <Radio
                                id={optionName}
                                name="assetSize"
                                value={optionName.toLowerCase()}
                                label={labelMap[optionName] || optionName}
                                inputClassName={cx( {'hidden': sizeOptions.length <= 1} )}/>
                        </span>
                        <Show when={notEmptyOrNil( sizeDetail )}>
                            <span className="hidden md:table-cell pr-4">
                                <Show when={sizeDetail?.resolution > 0}>
                                    {sizeDetail?.resolution}dpi
                                </Show>
                            </span>
                            <span className="table-cell pr-4">
                                <Show when={sizeDetail?.width && sizeDetail?.height}>
                                    {sizeDetail?.width} x {sizeDetail?.height}
                                </Show>
                            </span>
                            <span className="hidden md:table-cell pr-4">
                                <Show when={sizeDetail?.fileSize > 0}>
                                    {toFileSize( sizeDetail?.fileSize )}
                                </Show>
                            </span>
                        </Show>
                    </div>
                )
            } )}
        </div>
    );
}
