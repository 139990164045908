import {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

export function useIsMountedRef () {
    const isMountedRef = useRef( null );
    useEffect( () => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    } );
    return isMountedRef;
}

export const useScrollToTop = ( {condition, scrollContext = document.documentElement} = {} ) => {
    const location = useLocation();

    useLayoutEffect( () => {
        let doScroll = true;

        if ( condition ) {
            doScroll = condition( location );
        }

        doScroll && scrollContext.scrollTo( 0, 0 );
    }, [location.pathname] );

    return {scrollContext}
}

export function useDidUpdateEffect ( fn, inputs ) {
    const isMountedRef = useRef( null );

    useEffect( () => {
        if ( isMountedRef.current ) {
            fn();
            return;
        }

        isMountedRef.current = true;
    }, inputs );
}

export const getLocations = ( location ) => {
    const backgroundLocation = location.state?.backgroundLocation;
    return {
        parentLocation: backgroundLocation === undefined ? location : backgroundLocation,
        modalLocation: backgroundLocation === undefined ? undefined : location
    };
};

export const useMediaQuery = ( query ) => {
    const mediaMatch = window.matchMedia( query );
    const [matches, setMatches] = useState( mediaMatch.matches );

    useEffect( () => {
        const handler = e => setMatches( e.matches );
        mediaMatch.addEventListener( 'change', handler );
        return () => mediaMatch.removeEventListener( 'change', handler );
    } );

    return matches;
}

