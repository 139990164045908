import {FeatureTile} from '@/components/common/MediaTile/FeatureTile';

export const FeaturedCollectionsSection = ( {title, list} ) =>
    <div className="mb-16 md:mb-32">
        <div className="text-center mb-6 md:mb-10">
            <h3 className="headline-base text-near-black">{title}</h3>
        </div>
        <div
            className={`grid md:grid-cols-${list.length} gap-4 justify-items-center`}>
            {list.map( itemProps =>
                <FeatureTile key={itemProps.id} {...itemProps} className="max-w-md"/>
            )}
        </div>
    </div>
