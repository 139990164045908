import {
    FormatDoc,
    FormatDocument,
    FormatGeneric,
    FormatIndd,
    FormatJpg,
    FormatPdf,
    FormatPng,
    FormatPpt,
    FormatPsd,
    FormatTif,
    FormatVideo,
    FormatZip
} from '@/components/common/Icons';
import {fileTypeMap} from '@/constants/appConstants';
import {Tooltip} from '@ultradent/components';

const iconMap = {
    avi: FormatVideo,
    doc: FormatDoc,
    docx: FormatDoc,
    indd: FormatIndd,
    generic: FormatGeneric,
    html5: FormatVideo,
    jpeg: FormatJpg,
    jpg: FormatJpg,
    pdf: FormatPdf,
    png: FormatPng,
    ppt: FormatPpt,
    pptx: FormatPpt,
    psd: FormatPsd,
    mkv: FormatVideo,
    mov: FormatVideo,
    mp4: FormatVideo,
    mpeg: FormatVideo,
    swf: FormatVideo,
    tif: FormatTif,
    txt: FormatDocument,
    webm: FormatVideo,
    wmv: FormatVideo,
    video: FormatVideo,
    zip: FormatZip
};

export const FileFormatIcon = ( {format, className = ''} ) => {
    const fm = format?.toLowerCase();
    const Icon = iconMap[fm] || iconMap['generic'];
    return (
        <span className={className} aria-label={fm}>
            <Tooltip content={fileTypeMap[fm]}>
                <Icon width="24" height="24"/>
            </Tooltip>
        </span>
    );
}
