import React from 'react';
import {Button, Checkbox, Search, Show, TokenFilter, Tooltip, TooltipPlugins} from '@ultradent/components';
import cx from 'classnames';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {EventResponseStatus} from '@/constants/appConstants';
import {MenuArrowDown} from '@/components/common/Icons';

const SortOptions = [
    {id: 'dateSubmitted:desc', label: 'Date Submitted'},
    {id: 'status:asc', label: 'Status'},
    {id: 'companyName:asc', label: 'Company Name'}
];
const ResponseListFilterBar = ( { sortValue, appliedFilters, onSortChange, onRemoveFilter, onClearAllFilters} ) => {
    return (
        <div>
            <div className="w-full md:flex lg:flex-1 mb-4">
                <Search.Input className="flex-1 mr-4" hint="test"/>
                <Tooltip plugins={[TooltipPlugins.hideOnEsc]} trigger="click" arrow={false}
                         placement="bottom-start" interactive={true} theme="light"
                         content={
                             <div className="w-56 text-base p-2">
                                  {EventResponseStatus.map( ( id ) =>
                                      <div className="flex pt-1" key={id}>
                                          <Checkbox inputClassName="flex"
                                                    name="statusFilters"
                                                    id={id}
                                                    value={id}
                                                    label={id}/>
                                      </div>
                                  )}
                              </div>
                         }>
                    <span tabIndex="0">
                        <Button
                            data-testid="status-filter-button"
                            tertiary small className="mt-4 md:mt-0"
                            appendLabel={<MenuArrowDown width="20" className="ml-1"/>}>
                            Status
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip plugins={[TooltipPlugins.hideOnEsc, TooltipPlugins.hideOnInteraction]}
                         trigger="click" arrow={false} placement="bottom-start" interactive={true}
                         theme="light"
                         content={
                             <nav className="flex flex-col w-40 text-base">
                                  {SortOptions.map( ( {id, label} ) =>
                                      <button
                                          key={id}
                                          name={id}
                                          type="button"
                                          onClick={() => onSortChange( id )}
                                          className={cx( 'menu-context--item',
                                              {'text-blue font-bold bg-moon-grey': id === sortValue} )}>
                                          {label}
                                      </button>
                                  )}
                              </nav>
                         }>
                    <span tabIndex="0">
                        <Button
                            data-testid="sort-button"
                            tertiary small className="mt-4 md:mt-0 ml-4"
                            appendLabel={<MenuArrowDown width="20" className="ml-1"/>}>
                            Sort
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <p className="hidden md:block -mt-2 text-grey-darker">Search by company, name, email, phone number, Ultradent representative, or country</p>
            <Show when={notEmptyOrNil( appliedFilters )}>
                <div data-testid="applied-filters-bar" className="my-6 flex flex-wrap items-center">
                    <h4 className="text-title mr-8">Applied filters:</h4>
                    {appliedFilters.map( ( filter, index ) =>
                        <TokenFilter key={index}
                                     color="moon-grey"
                                     textColor="grey-darker"
                                     textSize="base"
                                     label={filter}
                                     onClick={() => onRemoveFilter( filter )}
                        />
                    )}
                    <Button ghosted small secondary className="ml-2" onClick={onClearAllFilters}>Clear All</Button>
                </div>
            </Show>
        </div>
    );
}

export default ResponseListFilterBar;
